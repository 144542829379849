import React, {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Grid, ListItemText, MenuItem, Typography } from '@material-ui/core';
import { InfoTwoTone as InfoIcon } from '@material-ui/icons';
import { flatten, isEmpty, isEqual, isNil, isNull, maxBy, negate, sortBy } from 'lodash';
import moment from 'moment';
import uuid from 'react-uuid';
import {
  AllocationName,
  AllocationScenarioValuationModel,
  GPC,
  ValuationApproachGPC,
  ValuationApproachGPT,
  ValuationApproachWeightModel,
} from 'api';
import { valuationsAction } from 'common/actions';
import { SYSTEM_COLLAPSE, SYSTEM_EXPAND } from 'common/actions/row-groups/types';
import { ALLOCATIONS_FINAL, ALLOCATIONS_PUBLISHED } from 'common/constants/allocations';
import { CAP_TABLE_CURRENCY_PAGE, FINANCIALS_CURRENCY_PAGE } from 'common/constants/currencyPageTypes';
import { ALLOCATIONS_REFERENCE_TYPE } from 'common/constants/documents';
import { INITIAL, VALIDATED, VALIDATING } from 'common/constants/general';
import { ALLOCATIONS_PAGE_KEY } from 'common/constants/notes';
import { COMMON_STOCK, CONVERTIBLE_NOTES, PREFERRED_STOCK } from 'common/constants/securityTypes';
import { ANALYST_VALUE, FIRM_ADMIN_VALUE, FUND_ADMIN_VALUE } from 'common/constants/user';
import { VALUATIONS_SLUG } from 'common/constants/valuations';
import { useFormat } from 'common/hooks';
import { rowsGroupsReducer } from 'common/reducers/rowsGroups';
import { useStore } from 'common/store';
import { NewAllocationScenario } from 'common/types/allocation';
import { DialogContextValues } from 'common/types/dialogContext';
import { UseFormatValues } from 'common/types/format';
import { Cell, ConfigurationSpreadsheets, ResetParams } from 'common/types/scalarSpreadsheet';
import { LayoutContextValues, UseStoreValues } from 'common/types/store';
import { UnsavedChangesValues } from 'common/types/unsavedChanges';
import {
  ApproachType,
  NewValuationApproach,
  ValuationApproachAdditionalProperties,
  ValuationByAllocation,
  ValuationsApproach,
} from 'common/types/valuation';
import { Calibration as CalibrationValuationModel } from 'common/types/valuation/valuation';
import {
  Alert,
  AllocationFinalMessage,
  CenterAlignedParagraph,
  EmptyTableMessage,
  LoadingSection,
  MessageBox,
  Tab,
  TabsNavigation,
  Widgets,
} from 'components';
import { ConfirmationDialog } from 'components/Dialogs';
import { REGULAR_UNIT } from 'components/FeaturedSpreadsheet/constants';
import useWorkbook from 'components/ScalarSpreadsheet/utilities/useWorkbook';
import WorkbookContext from 'components/ScalarSpreadsheet/utilities/WorkbookContext';
import { ConfirmUnsavedChanges } from 'components/Spreadsheet/components';
import { DialogContext, LayoutContext } from 'context';
import UnsavedChanges from 'context/UnsavedChanges';
import NewVersionForm from 'pages/Allocation/allocations/components/NewVersionForm';
import { PERCENTILE_ROWS } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { synchronizeAndUpdateApproaches } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/utilities';
import {
  CompGroupForRowConfig,
  GPCRowConfig,
  GPCRowTransformer,
  ReverseParsedValuationApproachGPC,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/types';
import { useCombinedGPCComparisonPerfMetricAttrs } from 'pages/Valuations/approaches/guidelinePublicCompanies/utils/useCombinedGPCComparisonPerfMetricAttrs';
import {
  GPTRowTransformer,
  ReverseParsedValuationApproachGPT,
} from 'pages/Valuations/approaches/GuidelineTransactions/types';
import { useRefreshHelpers } from 'pages/Valuations/components/RefreshGPCOption/hooks';
import RefreshGPCOption from 'pages/Valuations/components/RefreshGPCOption/RefreshGPCOption';
import RefreshGPCPrompt from 'pages/Valuations/components/RefreshGPCPrompt/RefreshGPCPrompt';
import {
  EQUITY_ALLOCATION_KEY,
  EQUITY_ALLOCATION_LABEL,
  SHEET_ALIASES_CONSTANTS as EQUITY_ALLOCATION_SHEET_ALIASES_CONSTANTS,
} from 'pages/ValuationsAllocation/common/constants/equityAllocation';
import {
  CALIBRATION_MODEL_NAME,
  EQUITY_ATTRIBUTES_NEEDED,
  READY_FOR_AUDIT,
  REMOVE_READY_FOR_AUDIT,
  SET_AS_READY_FOR_AUDIT,
  VALUATIONS_APPROACH_GPC_PROPERTY,
  VALUATIONS_APPROACH_TYPES_WITH_VALUATION_DATE_ENABLED,
  VALUATIONS_BACKSOLVE_APPROACH,
  VALUATIONS_CANNOT_DELETE_GPC,
  VALUATIONS_CAP_TABLE_CURRENCY_APPROACHES,
  VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH,
  VALUATIONS_EXTERNAL_VALUATION_APPROACH,
  VALUATIONS_FINAL_VALUATION_LOCKED_MESSAGE,
  VALUATIONS_FUTURE_EXIT_APPROACH,
  VALUATIONS_MISSING_FINANCIAL_STATEMENT_TAGLINE,
  VALUATIONS_MISSING_FINANCIAL_STATEMENT_TITLE,
  VALUATIONS_PUBLIC_COMPANIES_APPROACH,
  VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH,
  VALUATIONS_SPREADSHEET_APPROACH_TABLE_NAME_REGEX,
  VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY,
  VALUATIONS_SPREADSHEET_TABLE_TERMS,
  VALUATIONS_VALUATION_REFERENCES_MAP,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import ValuationOwnershipConfirmation from 'pages/ValuationsAllocation/components/ValuationOwnershipConfirmation/ValuationOwnershipConfirmation';
import {
  areApproachesSynchronized,
  getApproachTableName,
  getFirmTotal,
  getGenericApproachTypeObject,
  getGPCOptions,
  getWeightedEquityValue,
  updateDCFPeriodsByVersion,
  updatePercentileAndGetConfiguration,
} from 'pages/ValuationsAllocation/util';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useDocuments } from 'services/hooks';
import { useMakeAllocationsFinal, usePublishAllocationVersion } from 'services/hooks/allocations';
import { useNotes } from 'services/hooks/notes';
import { GetPublicCompsByDateHook, useCreateNewVersion, useSetReadyForAudit } from 'services/hooks/valuations';
import { SetReadyForAuditParams } from 'services/hooks/valuations/useSetReadyForAudit';
import { useAllocationValuesStore, useScalarSpreadsheetStore, useValuationsStore } from 'store';
import {
  dbShortDate,
  getAllocationStatus,
  getArrayValue,
  getBooleanValue,
  getNumberValue,
  getObjectValue,
  getSlugValue,
  getStringValue,
  gridShortDate,
  handleCatchError,
  isFalseStrict,
  returnCurrencyFormattedValueOrNull,
  scrollToTop,
} from 'utilities';
import { EquityAllocation, WeightedShareValues } from './allocation';
import { EquityAllocationCellCustomData, EquityAllocationColumn } from './allocation/EquityAllocation/config';
import { createColumns as createEquityAllocationColumns } from './allocation/EquityAllocation/config/createColumns';
import { customRowConfig as createEquityAllocationRows } from './allocation/EquityAllocation/config/customRowConfig';
import { ValuationSummary } from './approaches';
import { ValuationSummaryCollapsibleColumns } from './approaches/ValuationSummary';
import { ValuationSummaryCellCustomData, ValuationSummaryColumn } from './approaches/ValuationSummary/config';
import { createColumns as createValuationSummaryColumns } from './approaches/ValuationSummary/config/createColumns';
import {
  ALLOCATION_SCENARIO_METHOD_CSE,
  ALLOCATION_SCENARIO_METHOD_OPM,
  ALLOCATION_SCENARIO_METHOD_OPM_KEY,
  ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES,
  ALLOCATION_SCENARIO_METHOD_WATERFALL,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE,
  ALLOCATION_SCENARIO_TYPE_BACKSOLVE_KEY,
  ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT,
  ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_KEY,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES,
  ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_KEY,
} from './common/constants/allocation';
import { EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE } from './common/constants/equityAllocation/sheetAliases';
import { EQUITY_ALLOCATION_SPREADSHEET_TABLE_TERMS } from './common/constants/equityAllocation/sheetConfigs';
import { FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER } from './common/constants/equityAllocation/sheetTitles';
import {
  VALUATION_SUMMARY_KEY,
  VALUATION_SUMMARY_LABEL,
  SHEET_ALIASES_CONSTANTS as VALUATION_SUMMARY_SHEET_ALIASES_CONSTANTS,
} from './common/constants/valuationSummary';
import { VALUATION_SUMMARY_SPREADSHEET_TABLE_TERMS } from './common/constants/valuationSummary/sheetConfigs';
import {
  ALLOCATION_WEIGHTED_SHARE_VALUES_KEY,
  ALLOCATION_WEIGHTED_SHARE_VALUES_LABEL,
} from './common/constants/weightedShareValues';
import {
  AddScenarioApproachDialog,
  ApproachActionOption,
  ApproachDialog,
  ApproachTypes,
  DeleteApproachDialog,
  DeleteCalibrationDialog,
  InvalidTablesMessage,
  RedirectToFinancialButton,
} from './components';
import { CreateNewVersionButton } from './components/CreateNewVersionButton';
import { SetReadyForAuditConfirmation } from './components/SetReadyForAuditConfirmation';
import {
  Configuration,
  ConfiguredSpreadsheets,
  EnterpriseValues,
  useApproachQueryParam,
  useCreateCalibration,
  useCreateValuation,
  useLogInvalidCells,
} from './hooks';
import useStyles from './styles';
import {
  CalculateEquityValueParams,
  ChangedCell,
  DefaultPanelComponentProps,
  DiscountedCashFlowProperties,
  File,
  GetIsGPCInApproachParams,
  GetSpreadsheetsFromWorkbookParams,
  HandleEquityValueParams,
  InvalidTable,
  InvalidTables,
  IsApproachTypeOfParams,
  Note,
  OnSpreadsheetChange,
  OnWorkbookChange,
  RedirectToNewApproachParams,
  SecurityIdMapType,
  SpreadsheetsNames,
  UpdateMainSpreadsheetsParams,
  ValuationApproachesProps,
  ValuationApproachWithReferences,
  ValuationsAreCellsValidValues,
  ValuationsFormState,
  ValuationsUseWorkbook,
  ValuationWithReferences,
} from './types';
import Calibration from '../Valuations/approaches/guidelineCalibration/Calibration';
import { GPTRowConfig } from '../Valuations/approaches/GuidelineTransactions/types';
import RefreshCalibrationOption from '../Valuations/components/RefreshCalibrationOption/RefreshCalibrationOption';

const defaultValues = {
  name: moment().format('MM/DD/YYYY'),
};

const defaultVersionFormState = {
  values: {
    ...defaultValues,
  },
  isValid: false,
  dbErrors: {},
  errors: {},
};
const {
  VALUATION_SUMMARY_SPREADSHEET_EQUITY_VALUE_COLUMN_KEY,
  VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
} = VALUATION_SUMMARY_SHEET_ALIASES_CONSTANTS;

const {
  EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD,
  EQUITY_ALLOCATION_SPREADSHEET_MATURITY,
  EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS,
  EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE,
  EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
  EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS,
  EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY,
} = EQUITY_ALLOCATION_SHEET_ALIASES_CONSTANTS;

const ALLOCATION_SCENARIO_DEFAULT_VALUES = {
  approach_uuid: null,
  deleted_at: null,
  dilution_percentage: '0',
  discount_rate: null,
  equity_value: '0',
  exit_date: null,
  exit_equity_value: '0',
  id: 0,
  is_deleted: false,
  maturity: '0',
  name: 'Empty Scenario',
  order: 0,
  scenario_method: ALLOCATION_SCENARIO_METHOD_OPM,
  scenario_type: ALLOCATION_SCENARIO_TYPE_CURRENT_VALUE,
  scenario_values: {
    aggregate_values: [],
    future_values: [],
    present_values: [],
  },
  total_aggregate_value: null,
  volatility: '0',
  weighting_probability: '0',
} as AllocationScenarioValuationModel;

// Titles
const CHANGE_TO_SCALAR_VALUATION_LABEL = 'Change to Scalar Valuation';
const CHANGE_VALUATION_OWNERSHIP_LABEL = 'Change Valuation Ownership';
const DELETE_VALUATION_BUTTON_LABEL = 'Delete';
const EDIT_APPROACH_NAME_LABEL = 'Edit Approach';
const EDIT_CALIBRATION_NAME_LABEL = 'Edit Calibration';
const GIVE_OWNERSHIP_TO_FIRM_LABEL = 'Give Ownership to Firm';
const MAKE_FINAL_ALLOCATION_BUTTON_LABEL = 'Make Final';
const MAKE_FINAL_ALLOCATION_CONFIRMATION_MESSAGE = 'Are you sure you want to Make this Allocation Final?';
const PUBLISH_ALLOCATION_BUTTON_LABEL = 'Publish';
const PUBLISH_ALLOCATION_CONFIRMATION_MESSAGE = 'Are you sure you want to Publish this Allocation?';
const SAVE_AS_NEW_VERSION_LABEL = 'Save as New Version';
const SAVE_VALUATION_BUTTON_LABEL = 'Save';
const SCALAR_OWNED_DRAFT_MESSAGE = 'This Valuation will be viewable once Scalar publishes this version';

// Approach Menu Options
const CHANGE_FINANCIAL_STATEMENT_LABEL = 'Change Financial Statement';
const DELETE_APPROACH_LABEL = 'Delete Approach';
const DELETE_CALIBRATION_LABEL = 'Delete Calibration';
const ENTERPRISE_VALUE_LABEL = 'Enterprise Value';
const EQUITY_VALUE_LABEL = 'Equity Value';
const FINANCIAL_STATEMENT_LABEL = 'Financial Statement';
const VALUATION_DATE_LABEL = 'Valuation Date';

// Delays
const LOADING_TIMEOUT_DELAY = 500;
const REDIRECT_TIMEOUT_DELAY = 100;

// Spreadsheets Columns Groups
const COLUMNS_GROUPS_COLLAPSED_BY_DEFAULT = false; // Expanded
const COLUMNS_GROUPS_INITIAL_STATE = Object.freeze({
  [VALUATION_SUMMARY_SPREADSHEET_EQUITY_VALUE_COLUMN_KEY]: COLUMNS_GROUPS_COLLAPSED_BY_DEFAULT,
});

// Equity Allocation Rows Groups
const EQUITY_ALLOCATION_ROWS_GROUPS_INITIAL_STATE = Object.freeze({
  [EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD]: SYSTEM_EXPAND,
  [EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS]: SYSTEM_COLLAPSE,
  [EQUITY_ALLOCATION_SPREADSHEET_PRESENT_VALUE_PER_SHARE]: SYSTEM_EXPAND,
  [EQUITY_ALLOCATION_SPREADSHEET_VALUE_ALLOCATED_TO_SECURITY_CLASS]: SYSTEM_COLLAPSE,
});

const COMPONENT_NOT_IMPLEMENTED = (approachType?: ApproachType) =>
  `Component for valuation approach ${getStringValue(approachType)} has not been implemented`;

const isApproachTypeOf = (params: IsApproachTypeOfParams) => {
  const { approach, type } = params;

  return approach?.approach_type === type;
};

const calculateEquityValue = (params: CalculateEquityValueParams) => {
  const { companyExchangeRate, enterpriseValue, isCapTableCurrencyApproach, totalCash, totalDebt } = params;

  if (isCapTableCurrencyApproach) {
    return enterpriseValue + companyExchangeRate * (-1 * totalDebt + totalCash);
  }

  return enterpriseValue - totalDebt + totalCash;
};

const getSpreadsheetsFromWorkbook = (params: GetSpreadsheetsFromWorkbookParams) => {
  const { spreadsheetsNames, workbook } = params;

  const approachSpreadsheets = Object.entries(getObjectValue(workbook)).reduce((accumulator, current) => {
    const [key, spreadsheet] = current;

    if (!(key in spreadsheetsNames)) {
      return accumulator;
    }

    return {
      ...accumulator,
      [getStringValue(spreadsheetsNames[key])]: spreadsheet,
    };
  }, {} as ConfiguredSpreadsheets);

  return approachSpreadsheets;
};

const filterApproaches = (config: Configuration) => {
  const { isEquityAllocation, isWeightedShareValues, name, spreadsheets } = getObjectValue(config);

  return (
    name !== VALUATION_SUMMARY_KEY // Exclude Valuation Summary
    && !isEmpty(spreadsheets) // Exclude empty Configurations
    && isNil(isEquityAllocation) // Exclude Equity Allocation
    && isNil(isWeightedShareValues) // Exclude Weighted Share Values
  );
};

const sortApproaches = (a: Configuration, b: Configuration) =>
  getNumberValue(a?.approach?.id ?? Infinity) - getNumberValue(b?.approach?.id ?? Infinity);

const DefaultPanelComponent: FC<DefaultPanelComponentProps> = props => {
  const { approach } = props;

  return (
    <Grid>
      <Typography>{COMPONENT_NOT_IMPLEMENTED(approach?.approach_type)}</Typography>
    </Grid>
  );
};

const ValuationApproaches: FC<ValuationApproachesProps> = props => {
  const {
    financialStatementsList,
    allocationVersion,
    capTableVersions,
    cashTaxRate,
    companyMeasurementDate,
    compGroups,
    displayDecisionAlerts,
    financialFiscalYearPeriods,
    financialPeriods,
    financialPeriodsPerMD,
    financials,
    financialVersions,
    getDisconnectDialog,
    getValuationInfo,
    getVersions,
    isLoadingPublicCompsByDate,
    isUpdatingValuation,
    measurementDate,
    otherFinancialStatements,
    primaryCapTable,
    primaryFinancialStatement,
    publicCompsByDate,
    saveValuation,
    setCashTaxRate,
    setValuation,
    updatePublicComps,
    valuation,
  } = props;

  const { id: valuationId, valuations_approaches: valuationApproaches } = getObjectValue(valuation);
  const {
    allocation,
    is_firm_owned: isValuationFirmOwned,
    is_ready_for_audit: isValuationReadyForAudit,
  } = getObjectValue(valuation);
  const { allocation_scenarios: allocationScenarios = [], id: allocationId } = getObjectValue(allocation);
  const { calibrations: calibrationData } = getObjectValue(valuation);

  const getInitialAllocationStatus = (allocationVersion: AllocationName) => {
    const { is_final = false, is_published = false } = getObjectValue(allocationVersion);
    return { is_final, is_published };
  };
  const [isCurrentAllocationFinal, setIsCurrentAllocationFinal] = useState(
    getInitialAllocationStatus(allocationVersion).is_final
  );
  const [isCurrentAllocationPublished, setIsCurrentAllocationPublished] = useState(
    getInitialAllocationStatus(allocationVersion).is_published
  );

  useEffect(() => {
    setIsCurrentAllocationFinal(getInitialAllocationStatus(allocationVersion).is_final);
    setIsCurrentAllocationPublished(getInitialAllocationStatus(allocationVersion).is_published);
  }, [allocationVersion]);

  const [storeValue, dispatch] = useStore() as unknown as UseStoreValues;
  const { companyInfo, user, firmId } = storeValue;
  const { is_staff: isUserStaff } = getObjectValue(user);

  const { showDialog } = useContext(DialogContext) as unknown as DialogContextValues;
  const { cleanAction: cleanUnsavedChanges, setAction: setUnsavedChanges } = useContext(
    UnsavedChanges
  ) as unknown as UnsavedChangesValues;

  const { companyExchangeRate, financialExchangeRate, updatePageActions, viewOnlyUser } = useContext(
    LayoutContext
  ) as unknown as LayoutContextValues;

  const [approaches, setApproaches] = useState<ValuationsApproach[]>([]);
  const [approachToUpdate, setApproachToUpdate] = useState<ValuationsApproach | null>(null);
  const [arePendingChanges, setArePendingChanges] = useState(false);
  const [areSynchronized, setAreSynchronized] = useState(false);
  const [changedCells, setChangedCells] = useState<ChangedCell[]>([]);
  const [collapsibleColumns, setCollapsibleColumns]
    = useState<ValuationSummaryCollapsibleColumns>(COLUMNS_GROUPS_INITIAL_STATE);
  const [currentPublicCompsByDate, setCurrentPublicCompsByDate] = useState<GetPublicCompsByDateHook['data'] | null>(
    null
  );
  const [currentWeightedEquityValue, setCurrentWeightedEquityValue] = useState<number | null>(null);
  const [deletedApproachesIds, setDeletedApproachesIds] = useState<number[]>([]);
  const [deletedScenariosIds, setDeletedScenariosIds] = useState<number[]>([]);
  const [doRefresh, setDoRefresh] = useState(true);
  const [equityAllocationRowsGroups, setEquityAllocationRowsGroups] = useReducer(
    rowsGroupsReducer,
    EQUITY_ALLOCATION_ROWS_GROUPS_INITIAL_STATE
  );
  const [filesInApproach, setFilesInApproach] = useState<File[]>([]);
  const [firmTotal, setFirmTotal] = useState<number | null>(null);
  const [invalidTables, setInvalidTables] = useState<InvalidTable[]>([]);
  const [isChangingFinancialStatement, setIsChangingFinancialStatement] = useState(false);
  const [isCurrentValuationDisabled, setIsCurrentValuationDisabled] = useState(
    isCurrentAllocationFinal || isUpdatingValuation || viewOnlyUser
  );
  const [isEditingApproachName, setIsEditingApproachName] = useState(false);
  const [isEditingCalibrationName, setIsEditingCalibrationName] = useState(false);
  const [isLoadingValuationSummary, setIsLoadingValuationSummary] = useState(true);
  const [isUpdatingSpreadsheets, setIsUpdatingSpreadsheets] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLSpanElement | null>(null);
  const [notesInApproach, setNotesInApproach] = useState<Note[]>([]);
  const [openAddApproachDialog, setOpenAddApproachDialog] = useState(false);
  const [openedApproaches, setOpenedApproaches] = useState<Map<string, boolean>>(new Map());
  const [openUpdateApproachDialog, setOpenUpdateApproachDialog] = useState(false);
  const [pageStatus, setPageStatus] = useState(INITIAL);
  const [valuationApproachesTabs, setValuationApproachesTabs] = useState<Tab[]>([]);
  const [openNewVersionDialog, setOpenNewVersionDialog] = useState(false);
  const [versionFormState, setVersionFormState] = useState(defaultVersionFormState);
  const setCurrentValuation = useValuationsStore(state => state.setCurrentValuation);

  const currentValuationApproaches = useRef(getArrayValue(valuation.valuations_approaches));

  const classes = useStyles();

  // Discounted Cash Flow
  const discountedCashFlowProperties = useMemo(
    () =>
      ({
        measurementDate,
        cashTaxRate,
        companyMeasurementDate,
      } as DiscountedCashFlowProperties),
    [cashTaxRate, companyMeasurementDate, measurementDate]
  );

  const handleOpenUpdateApproachDialog = () => setOpenUpdateApproachDialog(true);
  const handleMenuClose = () => setMenuAnchorEl(null);

  // Create Valuation
  const {
    backsolveAttributes,
    configurations,
    enterpriseValues,
    guidelinePublicCompaniesAttributes,
    calibrationAttributes,
    isLoadingAllocationScenariosValues,
    isLoadingBacksolvesEquityValues,
    isLoadingRiskFreeRates,
    isUniformCurrency,
    publicCompaniesAttributes,
    resetConfigurations,
    riskFreeRates,
    securities,
    setEnterpriseValues,
    spreadsheets,
    updateAllocationScenariosValues,
    updateOPMInputs,
    updateSecurities,
    weightedShareValuesNames,
    weightingProbabilities,
    allocationMethodsOptions,
    updateApproachesScenarioMethods,
  } = useCreateValuation({
    financialStatementsList,
    allocationVersion,
    approaches,
    capTable: primaryCapTable,
    capTableVersions,
    compGroups,
    deletedApproachesIds,
    discountedCashFlowProperties,
    financialPeriods,
    financials,
    isValuationDisabled: isCurrentValuationDisabled,
    otherFinancialStatements,
    setApproaches,
    valuation,
    calibration: calibrationData,
  });

  const {
    handleDeleteCalibration,
    showUpdateCalibrationNameDialog,
    updateValuationCalibrations,
    setCalibrationToUpdate,
    calibrationToUpdate,
    setCalibrationTabs,
    calibrationTabs,
  } = useCreateCalibration({
    valuation,
    REDIRECT_TIMEOUT_DELAY,
    setArePendingChanges,
    setValuation,
    setCurrentValuation,
    setPageStatus,
    handleOpenUpdateApproachDialog,
    setApproachToUpdate,
    handleMenuClose,
    setIsChangingFinancialStatement,
    setIsEditingCalibrationName,
    setIsEditingApproachName,
  });

  const combinedGPCComparisonPerfMetricAttrs = useCombinedGPCComparisonPerfMetricAttrs(
    guidelinePublicCompaniesAttributes
  );

  // Currency Formats
  const [capTableRegularFormat] = useFormat({
    page: CAP_TABLE_CURRENCY_PAGE,
    sourceCurrency: companyInfo?.captable_currency,
    units: REGULAR_UNIT,
  }) as UseFormatValues;

  const [financialRegularFormat] = useFormat({
    page: FINANCIALS_CURRENCY_PAGE,
    sourceCurrency: companyInfo?.financials_currency,
    units: REGULAR_UNIT,
  }) as UseFormatValues;

  // Refresh GPC Approaches
  const { getRefreshedApproaches } = useRefreshHelpers({
    measurementDate,
    backsolveAttributes,
    gpcAttributes: guidelinePublicCompaniesAttributes,
  });

  // Documents
  const { addReferenceForExistingDocuments } = useDocuments();

  // Notes
  const { notes, notesHasChanged, onAddNote, onDeleteNote, onUpdateNotes, saveNotes, setNotes } = useNotes();

  // ScalarSpreadsheet Store
  const setSynchronizedScroll = useScalarSpreadsheetStore(state => state.setSynchronizedScroll);
  const setSynchronizedSpreadsheets = useScalarSpreadsheetStore(state => state.setSynchronizedSpreadsheets);

  // Valuations Store

  // Allocation Values Store
  const setCurrentAllocationStatus = useAllocationValuesStore(state => state.setCurrentAllocationStatus);
  const setCurrentEquityValue = useAllocationValuesStore(state => state.setCurrentEquityValue);
  const setCurrentFirmTotal = useAllocationValuesStore(state => state.setCurrentFirmTotal);

  // Publish Allocation Version
  const { mutateAsync: publishAllocationVersion } = usePublishAllocationVersion();

  // Make Allocation Version
  const { mutateAsync: makeFinalAllocationVersion } = useMakeAllocationsFinal();

  // Create New Version
  const { mutateAsync: createNewVersion } = useCreateNewVersion();

  const { mutateAsync: setReadyForAudit } = useSetReadyForAudit();

  // Approach Query Param
  const { setApproachQueryParam } = useApproachQueryParam();

  // Log Invalid Cells
  const { displayInvalidCells, logInvalidCell } = useLogInvalidCells();

  // Spreadsheets
  const { areCellsValid, cells, data, onChange, workbook, workbookContextValue } = (
    useWorkbook as unknown as ValuationsUseWorkbook
  )(spreadsheets);

  useEffect(() => {
    if (cells) {
      updateAllocationScenariosValues({ allocationScenarios, approaches }, cells, onChange);
    }
    // eslint-disable-next-line
  }, [cells]);

  // Page Status
  const isValidatingValuation = useMemo(() => pageStatus === VALIDATING, [pageStatus]);

  // Should Synchronize Approaches
  const shouldSynchronizeApproaches = useMemo(
    () =>
      !areApproachesSynchronized({
        valuationApproaches,
        approaches,
      }),
    [approaches, valuationApproaches]
  );

  // Valuation Ownership
  const isFirmOwned = useMemo(() => isValuationFirmOwned, [isValuationFirmOwned]);
  const isReadyForAudit = useMemo(() => isValuationReadyForAudit ?? false, [isValuationReadyForAudit]);

  const isScalarOwnedDraftAndNotStaff = useMemo(
    () => isFalseStrict(isFirmOwned) && !isUserStaff && !(isCurrentAllocationFinal || isCurrentAllocationPublished),
    [isCurrentAllocationFinal, isCurrentAllocationPublished, isFirmOwned, isUserStaff]
  );

  // Valuation without Approaches
  const isValuationEmpty = useMemo(() => isEmpty(valuationApproaches), [valuationApproaches]);

  // Allocation Scenarios sorted by order
  const sortedAllocationScenarios = useMemo(() => sortBy(allocationScenarios, 'order'), [allocationScenarios]);

  // Allocation without Scenarios
  const isAllocationScenariosEmpty = useMemo(() => isEmpty(sortedAllocationScenarios), [sortedAllocationScenarios]);

  const { fund_ownership: capTableFundOwnership, securities: capTableSecurities = [] }
    = getObjectValue(primaryCapTable);
  const { fund_ownership_detail: capTableFundOwnershipDetail = [] } = getObjectValue(capTableFundOwnership);

  // Fund Ownership Custom Securities
  const customSecurities = useMemo(
    () =>
      getArrayValue(capTableFundOwnershipDetail?.filter(fundOwnershipDetail => isNil(fundOwnershipDetail?.security))),
    [capTableFundOwnershipDetail]
  );

  const cashDistributions = useMemo(
    () =>
      capTableFundOwnership?.fund_ownership_detail?.reduce(
        (acc, { cash_distribution }) => acc + Number(cash_distribution),
        0
      ) ?? 0,
    [capTableFundOwnership]
  );

  const noteSecurityIdMap = useMemo(
    () =>
      capTableSecurities
        .filter(s => s.security_type === CONVERTIBLE_NOTES)
        .reduce((acc, s) => {
          if (s.id) {
            acc[s.id] = s;
          }
          return acc;
        }, {} as SecurityIdMapType),
    [capTableSecurities]
  );

  const unrealizedDebt = useMemo(
    () =>
      capTableFundOwnership?.fund_ownership_detail
        ?.filter(({ security }) => security && noteSecurityIdMap[security])
        ?.reduce((acc, { invested_capital }) => Number(invested_capital) + acc, 0) ?? 0,
    [capTableFundOwnership, noteSecurityIdMap]
  );

  // Fund Ownership has Custom Securities
  const hasCustomSecurities = useMemo(() => !isEmpty(customSecurities), [customSecurities]);

  // Display Allocation without Scenarios
  const shouldDisplayWithoutScenarios = useMemo(
    () => isAllocationScenariosEmpty && hasCustomSecurities,
    [isAllocationScenariosEmpty, hasCustomSecurities]
  );

  // Fund Ownership is Empty
  const isFundOwnershipEmpty = useMemo(() => isEmpty(capTableFundOwnershipDetail), [capTableFundOwnershipDetail]);

  // Cap Table with Common and Preferred Stock
  const isCapTableValid = useMemo(() => {
    const breakpointsSecurities = capTableSecurities?.filter(security =>
      [COMMON_STOCK, PREFERRED_STOCK].includes(getNumberValue(security?.security_type))
    );

    return !isEmpty(breakpointsSecurities);
  }, [capTableSecurities]);

  // Cap Table has Valid Securities or Fund Ownership has Custom Securities
  const hasSecurities = useMemo(() => isCapTableValid || hasCustomSecurities, [hasCustomSecurities, isCapTableValid]);

  // Cap Table Securities are Invalid
  const areCapTableSecuritiesInvalid = useMemo(
    () => !isCapTableValid || (!isEmpty(capTableSecurities) && !hasSecurities && !shouldDisplayWithoutScenarios),
    [capTableSecurities, hasSecurities, isCapTableValid, shouldDisplayWithoutScenarios]
  );

  // Allocation is Empty
  const isAllocationEmpty = useMemo(
    () => isAllocationScenariosEmpty && !shouldDisplayWithoutScenarios && !isFundOwnershipEmpty,
    [isAllocationScenariosEmpty, isFundOwnershipEmpty, shouldDisplayWithoutScenarios]
  );

  // Allocation is Valid
  const isAllocationValid = useMemo(
    () => !isAllocationEmpty && !isFundOwnershipEmpty && !areCapTableSecuritiesInvalid,
    [areCapTableSecuritiesInvalid, isAllocationEmpty, isFundOwnershipEmpty]
  );

  // Should Display Weighted Share Values
  const shouldDisplayWeightedShareValues = useMemo(
    () => shouldDisplayWithoutScenarios || hasSecurities,
    [shouldDisplayWithoutScenarios, hasSecurities]
  );

  // Allocation Values - Allocation Status
  const currentAllocationStatus = useMemo(
    () => getAllocationStatus({ isFinal: isCurrentAllocationFinal, isPublished: isCurrentAllocationPublished }),
    [isCurrentAllocationFinal, isCurrentAllocationPublished]
  );

  // Approaches used for Allocation Scenarios
  const approachesUsedForAllocationScenarios = useMemo(
    () =>
      allocationScenarios
        ?.filter(scenario => Boolean(scenario?.backsolve_valuation) || Boolean(scenario?.future_exit))
        ?.map(scenario => {
          const { backsolve_valuation: backsolveValuationId, future_exit: futureExitId } = getObjectValue(scenario);

          const { valuations_approach_backsolve: backsolveValuation } = getObjectValue(
            approaches?.find(approach => approach?.valuations_approach_backsolve?.id === backsolveValuationId)
          );

          const { valuations_approach_future_exit: futureExitValuation } = getObjectValue(
            approaches?.find(approach => approach?.valuations_approach_future_exit?.id === futureExitId)
          );

          return getNumberValue(backsolveValuation?.valuation_approach ?? futureExitValuation?.valuation_approach);
        })
        ?.filter(Boolean),
    [allocationScenarios, approaches]
  );

  // Approaches quantity
  const approachesQuantity = useMemo(() => getNumberValue(approaches?.length), [approaches]);

  // Financial Versions quantity
  const financialVersionQuantity = useMemo(() => getNumberValue(financialVersions?.length), [financialVersions]);

  // Primary Financial Statement Id
  const primaryFinancialStatementId = useMemo(
    () => getNumberValue(primaryFinancialStatement?.id),
    [primaryFinancialStatement]
  );

  // Selected Financial Version
  const selectedFinancialsVersion = useMemo(() => {
    if (approachToUpdate && !isEmpty(approaches)) {
      const approachMatched = approaches?.find(approach => approach?.panelId === approachToUpdate?.panelId);

      return getNumberValue(approachMatched?.financial_statement);
    }

    return 0;
  }, [approachToUpdate, approaches]);

  // Get Allocation Values - Equity Value
  const getCurrentWeightedEquityValue = useCallback(
    (valuationSummarySpreadsheet: ConfigurationSpreadsheets) => {
      const { cells: valuationSummaryCells } = getObjectValue(valuationSummarySpreadsheet);

      // If Valuation is empty, set Weighted Equity Value to 0
      if (isValuationEmpty) {
        setCurrentWeightedEquityValue(0);
        return;
      }

      // If Summary is empty or is synchronizing, set Weighted Equity Value to null
      if (isEmpty(valuationSummarySpreadsheet) || isLoadingValuationSummary || !areSynchronized) {
        setCurrentWeightedEquityValue(null);
        return;
      }

      // If Summary Cells are empty, set Weighted Equity Value to 0
      if (isEmpty(valuationSummaryCells)) {
        setCurrentWeightedEquityValue(0);
        return;
      }

      // Evaluate Weighted Equity Value
      const totalWeightedEquityValue = getWeightedEquityValue(getObjectValue(valuationSummaryCells));

      // Set Weighted Equity Value
      setCurrentWeightedEquityValue(totalWeightedEquityValue);
    },
    [areSynchronized, isLoadingValuationSummary, isValuationEmpty]
  );

  // Get Allocation Values - Firm Total
  const getCurrentFirmTotal = useCallback(
    (weightedShareValuesSpreadsheets: ConfigurationSpreadsheets[]) => {
      const weightedShareValuesCells = flatten(
        weightedShareValuesSpreadsheets
          ?.map(spreadsheet => spreadsheet?.cells) // Get Cells with References
          ?.map(spreadsheetCells => Object.values(getObjectValue(spreadsheetCells))) // Get Cells without References
          ?.filter(spreadsheetCell => !isEmpty(spreadsheetCell)) // Remove empty Cells
      );

      // If Weighted Share Values are empty, set Firm Total to 0
      if (!shouldDisplayWeightedShareValues) {
        setFirmTotal(0);
        return;
      }

      // If Weighted Share Values is empty, set Firm Total to null
      if (isEmpty(weightedShareValuesSpreadsheets)) {
        setFirmTotal(null);
        return;
      }

      // If Weighted Share Values Cells are empty, set Firm Total to 0
      if (isEmpty(weightedShareValuesCells)) {
        setFirmTotal(0);
        return;
      }

      // Evaluate Firm Total
      const currentFirmTotal
        = getFirmTotal(getObjectValue(weightedShareValuesCells)) + cashDistributions + unrealizedDebt;

      // Set Firm Total
      setFirmTotal(currentFirmTotal);
    },
    [shouldDisplayWeightedShareValues, cashDistributions, unrealizedDebt]
  );

  const equityCellValues = useRef<Array<string>>([]);
  const handleEquityValueChange = useCallback(async () => {
    const { equityAllocation: equityAllocationSpreadsheet } = getObjectValue(workbook);

    if (!isEmpty(equityAllocationSpreadsheet)) {
      const currentEquityCellValues = Object.values(equityAllocationSpreadsheet.cells)
        .filter(c => c.alias === EQUITY_ALLOCATION_SPREADSHEET_PRESENT_EQUITY_VALUE && c.hidden === false)
        .map(c => c.value) as Array<string>;
      if (!currentEquityCellValues.every(v => equityCellValues.current.includes(v))) {
        equityCellValues.current = currentEquityCellValues;
        await updateAllocationScenariosValues(
          {
            allocationScenarios,
            approaches,
          },
          cells,
          onChange
        );
      }
    }
  }, [allocationScenarios, approaches, updateAllocationScenariosValues, workbook, cells, onChange]);

  // Update Allocation Values
  const updateAllocationValues = useCallback(() => {
    const { [VALUATION_SUMMARY_KEY]: valuationSummarySpreadsheet } = getObjectValue(workbook);

    const weightedShareValuesSpreadsheets = getArrayValue(
      Object.values(getObjectValue(workbook))?.filter(
        spreadsheet => spreadsheet?.page === ALLOCATION_WEIGHTED_SHARE_VALUES_KEY
      )
    );

    if (!isEmpty(valuationSummarySpreadsheet)) {
      getCurrentWeightedEquityValue(valuationSummarySpreadsheet);
    }

    if (!isEmpty(weightedShareValuesSpreadsheets)) {
      getCurrentFirmTotal(weightedShareValuesSpreadsheets);
    }
  }, [getCurrentFirmTotal, getCurrentWeightedEquityValue, workbook]);

  // Update Valuation Approaches
  const updateValuationApproaches = useCallback(
    (updatedApproaches: ValuationsApproach[]) => {
      const updatedValuation = { ...valuation, valuations_approaches: updatedApproaches } as ValuationByAllocation;

      // Set Valuation Info in Store
      dispatch?.(valuationsAction.setValuationInfo(updatedValuation));
      setCurrentValuation(updatedValuation);

      setValuation(updatedValuation);
    },
    [dispatch, setCurrentValuation, setValuation, valuation]
  );

  // Update Allocation Scenarios
  const updateAllocationScenarios = useCallback(
    (updatedScenarios: AllocationScenarioValuationModel[]) => {
      const updatedValuation = {
        ...valuation,
        allocation: { ...allocation, allocation_scenarios: updatedScenarios },
      } as ValuationByAllocation;

      // Set Valuation Info in Store
      dispatch?.(valuationsAction.setValuationInfo(updatedValuation));
      setCurrentValuation(updatedValuation);

      setValuation(updatedValuation);
    },
    [allocation, dispatch, setCurrentValuation, setValuation, valuation]
  );

  // Add Approach Dialog
  const handleOpenAddApproachDialog = () => setOpenAddApproachDialog(true);
  const handleCloseAddApproachDialog = () => setOpenAddApproachDialog(false);

  // Expand OPM Inputs on Equity Allocation
  const expandOPMInputs = () =>
    setEquityAllocationRowsGroups({
      row: EQUITY_ALLOCATION_SPREADSHEET_OPM_INPUTS,
      type: SYSTEM_EXPAND,
    });

  // Add Approach
  const handleAddApproach = useCallback(
    (approachType: ApproachTypes) => {
      const approachIdentifier = uuid();

      if (approachType !== CALIBRATION_MODEL_NAME) {
        const createdApproach = {
          approach_type: approachType,
          financial_statement: primaryFinancialStatementId,
          name: approachType,
          panel_id: approachIdentifier,
          panelId: approachIdentifier,
          valuation: getNumberValue(valuationId),
          valuations_approaches_weights: [],
        } as NewValuationApproach;

        if (!isAllocationScenariosEmpty) {
          // add a weight for each scenario
          allocationScenarios.forEach(allocationScenario => {
            allocationScenario.allocations_scenarios_weights?.push({
              allocation_scenario_id: allocationScenario?.id ?? 0,
              approach_uuid: approachIdentifier,
              equity_value: '0',
              weight: '0',
              valuation_approach_id: 0,
            });
          });

          createdApproach.valuations_approaches_weights = getArrayValue(
            allocationScenarios?.map(allocationScenario => {
              const { id: allocationScenarioId } = getObjectValue(allocationScenario);

              return {
                allocation_scenario_id: allocationScenarioId,
                equity_value: '0',
                weight: '0',
              } as ValuationApproachWeightModel;
            })
          );
        }

        if (allocationId) {
          createdApproach.allocation = getNumberValue(allocationId);
        }

        const additionalProperties = getGenericApproachTypeObject({
          approach: createdApproach,
          capTableList: capTableVersions,
          financialStatementPeriods: financialFiscalYearPeriods,
          approaches,
        }) as ValuationApproachAdditionalProperties;

        const newApproach = {
          ...createdApproach,
          ...additionalProperties,
        } as ValuationsApproach;

        updateValuationApproaches([...approaches, newApproach]);
      } else {
        const defaultFinancial = financialStatementsList?.find(item => !item.is_a_version);
        const newCalibration = {
          performances: [],
          valuation: valuation.id,
          name: CALIBRATION_MODEL_NAME,
          financials_version: defaultFinancial?.id ?? 0,
          panelId: approachIdentifier,
          data_updated: true,
        } as unknown as CalibrationValuationModel;

        updateValuationCalibrations([...valuation.calibrations, newCalibration]);
      }

      setPageStatus(VALIDATED);
      setArePendingChanges(true);
      handleCloseAddApproachDialog();

      return approachIdentifier;
    },
    [
      allocationId,
      allocationScenarios,
      approaches,
      capTableVersions,
      financialFiscalYearPeriods,
      financialStatementsList,
      isAllocationScenariosEmpty,
      primaryFinancialStatementId,
      updateValuationApproaches,
      updateValuationCalibrations,
      valuation.calibrations,
      valuation.id,
      valuationId,
    ]
  );

  // Add Scenario
  const handleAddScenario = useCallback(() => {
    const scenarioIdentifier = uuid();

    const createdScenariosWeights = approaches?.map(approach => {
      const { equity_value: approachEquityValue, id: approachId, panel_id: approachPanelId } = getObjectValue(approach);

      return {
        allocation_scenario_id: 0,
        approach_uuid: getStringValue(approachPanelId),
        equity_value: getStringValue(approachEquityValue ?? '0'),
        valuation_approach_id: getNumberValue(approachId),
        weight: '0',
      } as ValuationApproachWeightModel;
    });

    const createdScenario = {
      allocation: getNumberValue(allocationId),
      allocations_scenarios_weights: createdScenariosWeights,
      asRef: scenarioIdentifier,
      cap_table_id: getNumberValue(primaryCapTable?.id),
      cap_table: primaryCapTable,
      order: getNumberValue(maxBy(sortedAllocationScenarios, 'order')?.order) + 1,
      scenario_ref: scenarioIdentifier,
    } as NewAllocationScenario;

    const newScenario = {
      ...ALLOCATION_SCENARIO_DEFAULT_VALUES,
      ...createdScenario,
    } as NewAllocationScenario;

    updateAllocationScenarios([...getArrayValue(allocationScenarios), newScenario]);

    setPageStatus(VALIDATED);
    setArePendingChanges(true);
    expandOPMInputs();
    handleCloseAddApproachDialog();

    setTimeout(() => {
      setApproachQueryParam(VALUATION_SUMMARY_KEY);
      setSynchronizedScroll({
        synchronizedBy: null,
        synchronizedScrollLeft: Number.MAX_SAFE_INTEGER,
      });
    }, REDIRECT_TIMEOUT_DELAY);
  }, [
    allocationId,
    allocationScenarios,
    approaches,
    primaryCapTable,
    setApproachQueryParam,
    setSynchronizedScroll,
    sortedAllocationScenarios,
    updateAllocationScenarios,
  ]);

  // Delete Scenario
  const handleDeleteScenario = useCallback(
    (columnIndex: number) => {
      const deletedScenarioIndex = columnIndex - (FIRST_ALLOCATION_SCENARIOS_COLUMN_NUMBER - 1);

      const scenarioToBeDeleted = sortedAllocationScenarios?.[deletedScenarioIndex];

      const { id: deletedScenarioId } = getObjectValue(scenarioToBeDeleted);

      setDeletedScenariosIds(previousState => [
        ...previousState,
        ...(!isNil(deletedScenarioId) ? [deletedScenarioId] : []),
      ]);

      const updatedScenarios = allocationScenarios?.filter(scenario => scenario?.id !== deletedScenarioId);

      updateAllocationScenarios(updatedScenarios);

      setPageStatus(VALIDATED);
      setArePendingChanges(true);
    },
    [allocationScenarios, sortedAllocationScenarios, updateAllocationScenarios]
  );

  // Update Weighted Share Values
  const handleWeightedShareValues = useCallback(() => {
    const weightedShareValuesSpreadsheets = spreadsheets?.filter(
      spreadsheet => spreadsheet?.page === ALLOCATION_WEIGHTED_SHARE_VALUES_KEY
    );

    setTimeout(() => {
      weightedShareValuesSpreadsheets.forEach(spreadsheet => {
        spreadsheet.reverseParse();
      });
    }, LOADING_TIMEOUT_DELAY);
  }, [spreadsheets]);

  // Update Valuation Summary and Equity Allocation Spreadsheets
  const updateMainSpreadsheets = useCallback(
    (params?: UpdateMainSpreadsheetsParams) => {
      const { updatedSecurities } = getObjectValue(params);

      const summarySpreadsheet = spreadsheets?.find(spreadsheet => spreadsheet?.name === VALUATION_SUMMARY_KEY);
      const allocationSpreadsheet = spreadsheets?.find(spreadsheet => spreadsheet?.name === EQUITY_ALLOCATION_KEY);

      if (summarySpreadsheet && allocationSpreadsheet) {
        // Valuation Summary
        const updatedSummaryColumns = createValuationSummaryColumns({
          allocationScenarios: sortedAllocationScenarios,
          configurations,
          deletedApproachesIds,
          isUniformCurrency,
        });

        summarySpreadsheet.reset({
          columns: updatedSummaryColumns as ResetParams<ValuationSummaryColumn>['columns'],
        });

        // Equity Allocation
        const updatedAllocationColumns = createEquityAllocationColumns({
          allocationScenarios: sortedAllocationScenarios,
          approaches,
          isUniformCurrency,
          riskFreeRates,
          securities: updatedSecurities ?? securities,
        });

        const updatedAllocationRows = createEquityAllocationRows({
          isDisabled: isCurrentValuationDisabled,
          securities: updatedSecurities ?? securities,
        });

        allocationSpreadsheet.reset({
          columns: updatedAllocationColumns as ResetParams<EquityAllocationColumn>['columns'],
          rowConfig: updatedAllocationRows as ResetParams<EquityAllocationColumn>['rowConfig'],
        });

        // Update Weighted Share Values
        handleWeightedShareValues();

        // Reset Configurations
        resetConfigurations();
      }
    },
    [
      approaches,
      configurations,
      deletedApproachesIds,
      handleWeightedShareValues,
      isCurrentValuationDisabled,
      isUniformCurrency,
      resetConfigurations,
      riskFreeRates,
      securities,
      sortedAllocationScenarios,
      spreadsheets,
    ]
  );

  // Recalculate Allocation Scenarios Values
  const handleScenarioWeight = useCallback(
    async (cell: Cell<ValuationSummaryCellCustomData>) => {
      const { isValid, y: columnNumber, data: cellData } = getObjectValue(cell);
      const { isApproach = false, scenarioId, scenarioRef } = getObjectValue(cellData);

      const isScenarioWeight = isApproach && (scenarioId || scenarioRef);

      if (isScenarioWeight && !isNil(columnNumber) && isValid) {
        // Update Allocation Scenarios Values
        await updateAllocationScenariosValues(
          {
            allocationScenarios,
            approaches,
          },
          cells,
          onChange
        );
      }
    },
    [allocationScenarios, approaches, updateAllocationScenariosValues, cells, onChange]
  );

  // Synchorize Allocation Methods on Valuation Summary and Equity Allocation
  const handleAllocationMethod = useCallback(
    async (cell: Cell<EquityAllocationCellCustomData>) => {
      const { alias, data: cellData, isValid, value, y: columnNumber } = getObjectValue(cell);
      const { isApproachWithOPM, scenarioId, scenarioRef } = getObjectValue(cellData as EquityAllocationCellCustomData);

      if ([EQUITY_ALLOCATION_SPREADSHEET_ALLOCATION_METHOD].includes(alias) && !isNil(columnNumber) && isValid) {
        // Scenario
        const scenario = getObjectValue(
          (allocationScenarios as NewAllocationScenario[])?.find(allocationScenario => {
            const { id: currentScenarioId, scenario_ref: currentScenarioRef } = getObjectValue(allocationScenario);

            const matchScenarioIds = currentScenarioId && scenarioId && currentScenarioId === scenarioId;
            const matchScenarioRefs = currentScenarioRef && scenarioRef && currentScenarioRef === scenarioRef;

            return matchScenarioIds || matchScenarioRefs;
          })
        );

        // Handle Add Approach
        switch (value) {
          case ALLOCATION_SCENARIO_METHOD_OPM_KEY:
            expandOPMInputs();
            break;

          case ALLOCATION_SCENARIO_TYPE_BACKSOLVE_KEY:
            scenario.approach_uuid = handleAddApproach(VALUATIONS_BACKSOLVE_APPROACH);
            scenario.backsolve_valuation = null;
            scenario.scenario_method = ALLOCATION_SCENARIO_METHOD_WATERFALL;
            scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_BACKSOLVE;
            return;

          case ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT_KEY:
            scenario.approach_uuid = handleAddApproach(VALUATIONS_FUTURE_EXIT_APPROACH);
            scenario.future_exit = null;
            scenario.scenario_method = ALLOCATION_SCENARIO_METHOD_CSE;
            scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_FUTURE_EXIT;
            return;

          case ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES_KEY:
            scenario.approach_uuid = handleAddApproach(VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH);
            scenario.scenario_method = ALLOCATION_SCENARIO_METHOD_SPECIFIED_SHARE_VALUES;
            scenario.scenario_type = ALLOCATION_SCENARIO_TYPE_SPECIFIED_SHARE_VALUES;
            scenario.specified_share_values = null;
            return;

          default:
            break;
        }
        const approach = approaches.find(a => a.panelId === scenario.approach_uuid);
        if (
          approach?.approach_type === VALUATIONS_BACKSOLVE_APPROACH
          || approach?.approach_type === VALUATIONS_SPECIFIED_SHARE_VALUES_APPROACH
        ) {
          return;
        }
        // Update Securities
        const updatedSecurities = updateSecurities(allocationScenarios);

        // Update Valuation Summary and Equity Allocation Spreadsheets
        updateMainSpreadsheets({ updatedSecurities });

        // Update Risk Free Rates based on OPM Inputs
        await updateOPMInputs({
          allocationScenarios,
          approaches,
        });

        // Update Allocation Scenarios Values
        await updateAllocationScenariosValues(
          {
            allocationScenarios,
            approaches,
          },
          cells,
          onChange
        );

        if (isApproachWithOPM) {
          expandOPMInputs();
        }
      }
    },
    [
      allocationScenarios,
      approaches,
      handleAddApproach,
      updateAllocationScenariosValues,
      updateMainSpreadsheets,
      updateOPMInputs,
      updateSecurities,
      cells,
      onChange,
    ]
  );

  // Update Securities
  const handleCapTableSelection = useCallback(
    async (cell: Cell<EquityAllocationCellCustomData>) => {
      const { isValid, y: columnNumber, customKey } = getObjectValue(cell);
      if (['capTableSelection'].includes(customKey ?? '') && !isNil(columnNumber) && isValid) {
        // Update Allocation Scenarios Values
        const updatedSecurities = updateSecurities(allocationScenarios);

        // Update Valuation Summary and Equity Allocation Spreadsheets
        updateMainSpreadsheets({ updatedSecurities });

        // Update Allocation Scenarios Values
        await updateAllocationScenariosValues(
          {
            allocationScenarios,
            approaches,
          },
          cells,
          onChange
        );
      }
    },
    [
      allocationScenarios,
      approaches,
      updateAllocationScenariosValues,
      updateMainSpreadsheets,
      updateSecurities,
      cells,
      onChange,
    ]
  );

  // Recalculate Risk Free Rates based on Maturity
  const handleMaturity = useCallback(
    async (cell: Cell<EquityAllocationCellCustomData>) => {
      const { alias, isValid, y: columnNumber } = getObjectValue(cell);

      if ([EQUITY_ALLOCATION_SPREADSHEET_MATURITY].includes(alias) && !isNil(columnNumber) && isValid) {
        // Update Risk Free Rates based on OPM Inputs
        await updateOPMInputs({
          allocationScenarios,
          approaches,
        });

        // Update Allocation Scenarios Values
        await updateAllocationScenariosValues(
          {
            allocationScenarios,
            approaches,
          },
          cells,
          onChange
        );
      }
    },
    [allocationScenarios, approaches, updateAllocationScenariosValues, updateOPMInputs, cells, onChange]
  );

  // Recalculate Allocation Scenarios Values
  const handleVolatility = useCallback(
    async (cell: Cell<EquityAllocationCellCustomData>) => {
      const { alias, isValid, y: columnNumber } = getObjectValue(cell);

      if ([EQUITY_ALLOCATION_SPREADSHEET_VOLATILITY].includes(alias) && !isNil(columnNumber) && isValid) {
        // Update Allocation Scenarios Values
        await updateAllocationScenariosValues(
          {
            allocationScenarios,
            approaches,
          },
          cells,
          onChange
        );
      }
    },
    [allocationScenarios, approaches, updateAllocationScenariosValues, cells, onChange]
  );

  // Synchorize Scenario Weighting Probability on Valuation Summary and Equity Allocation
  const handleScenarioWeightingProbability = useCallback(
    async (cell: Cell<ValuationSummaryCellCustomData | EquityAllocationCellCustomData>) => {
      const { alias, isValid, y: columnNumber } = getObjectValue(cell);

      if (
        [
          VALUATION_SUMMARY_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
          EQUITY_ALLOCATION_SPREADSHEET_SCENARIO_WEIGHTING_PROBABILITY,
        ].includes(alias)
        && !isNil(columnNumber)
        && isValid
      ) {
        // Update Allocation Scenarios Values
        await updateAllocationScenariosValues(
          {
            allocationScenarios,
            approaches,
          },
          cells,
          onChange
        );
      }
    },
    [allocationScenarios, approaches, updateAllocationScenariosValues, cells, onChange]
  );

  // Update Equity Value on Approaches
  const handleEquityValue = useCallback(
    (params: HandleEquityValueParams) => {
      const { cell, expr, customFn } = params;

      const reversed = onChange(cell, expr, customFn);

      reversed
        .filter(reversedApproach => !isEmpty(reversedApproach))
        .forEach(reversedApproach => {
          const updatedApproach = { ...reversedApproach };
          const approachIndex = approaches?.findIndex(
            currentApproach => currentApproach?.panelId === updatedApproach?.panelId
          );

          if (approachIndex !== -1) {
            const updatedApproaches = [...approaches];
            updatedApproach.equity_value = approaches[approachIndex].equity_value;
            updatedApproaches?.splice(approachIndex, 1, updatedApproach);
          }
        });

      // Update Allocation Values
      updateAllocationValues();
    },

    [approaches, onChange, updateAllocationValues]
  );

  // Workbook
  const onWorkbookChange: OnWorkbookChange = useCallback(
    async (cell, expr, customFn) => {
      // Handle Approaches Equity Value
      handleEquityValue({
        cell,
        customFn,
        expr,
      });

      // Start Updating Spreadsheets
      setIsUpdatingSpreadsheets(true);

      // Handle Scenario Weight
      await handleScenarioWeight(cell as Cell<ValuationSummaryCellCustomData>);

      // Handle Allocation Method
      await handleAllocationMethod(cell as Cell<EquityAllocationCellCustomData>);

      // Handle Cap Table Selection
      await handleCapTableSelection(cell as Cell<EquityAllocationCellCustomData>);

      // Handle Maturity
      await handleMaturity(cell as Cell<EquityAllocationCellCustomData>);

      await handleVolatility(cell as Cell<EquityAllocationCellCustomData>);

      // Handle Scenario Weighting Probability and Weighted Share Values
      await handleScenarioWeightingProbability(
        cell as Cell<ValuationSummaryCellCustomData | EquityAllocationCellCustomData>
      );

      // Handle changes to any equity value
      await handleEquityValueChange();

      setIsUpdatingSpreadsheets(false);
    },
    [
      handleAllocationMethod,
      handleCapTableSelection,
      handleEquityValue,
      handleMaturity,
      handleScenarioWeight,
      handleScenarioWeightingProbability,
      handleVolatility,
      handleEquityValueChange,
    ]
  );

  // Update Changed Cells
  const updateChangedCells = (currentChangedCells: ChangedCell[]) =>
    setChangedCells(currentChangedCells?.filter(negate(isNil)));

  // Handle On Spreadsheet Change
  const handleOnSpreadsheetChange: OnSpreadsheetChange = useCallback(
    async (cell, expr) => {
      onWorkbookChange(cell, expr, updateChangedCells);
    },
    [onWorkbookChange]
  );

  // Valuation Summary tab
  const valuationSummaryTab = useMemo(
    () =>
      ({
        id: VALUATION_SUMMARY_KEY,
        label: VALUATION_SUMMARY_LABEL,
        content: (
          <>
            <ValuationSummary
              collapsibleColumns={collapsibleColumns}
              isLoading={isUpdatingSpreadsheets}
              isLoadingValuationSummary={isLoadingValuationSummary}
              isSaving={isUpdatingValuation}
              isValuationEmpty={isValuationEmpty}
              onSpreadsheetChange={handleOnSpreadsheetChange}
              setCollapsibleColumns={setCollapsibleColumns}
              setIsLoadingValuationSummary={setIsLoadingValuationSummary}
              workbook={workbook}
            />

            <EquityAllocation
              areCapTableSecuritiesInvalid={areCapTableSecuritiesInvalid}
              collapsibleColumns={collapsibleColumns}
              equityAllocationRowsGroups={equityAllocationRowsGroups}
              handleDeleteScenario={handleDeleteScenario}
              isAllocationEmpty={isAllocationEmpty}
              isFundOwnershipEmpty={isFundOwnershipEmpty}
              isLoading={
                isLoadingAllocationScenariosValues
                || isLoadingBacksolvesEquityValues
                || isLoadingRiskFreeRates
                || isUpdatingSpreadsheets
              }
              isLoadingValuationSummary={isLoadingValuationSummary}
              isSaving={isUpdatingValuation}
              measurementDate={measurementDate}
              onSpreadsheetChange={handleOnSpreadsheetChange}
              setCollapsibleColumns={setCollapsibleColumns}
              setEquityAllocationRowsGroups={setEquityAllocationRowsGroups}
              workbook={workbook}
            />

            <WeightedShareValues
              isLoading={isUpdatingSpreadsheets}
              isLoadingValuationSummary={isLoadingValuationSummary}
              isSaving={isUpdatingValuation}
              onSpreadsheetChange={handleOnSpreadsheetChange}
              shouldDisplayWeightedShareValues={shouldDisplayWeightedShareValues}
              weightedShareValuesNames={weightedShareValuesNames}
              workbook={workbook}
            />

            {/* Allocation Notes  */}
            <Widgets
              notesProps={{
                isDisabled: isCurrentValuationDisabled,
                notes,
                onAddNote,
                onDeleteNote,
                onUpdateNotes,
                pageType: ALLOCATIONS_REFERENCE_TYPE,
                pageTypeId: allocationId,
                pageTypeKey: ALLOCATIONS_PAGE_KEY,
                setNotes,
              }}
            />
          </>
        ),
      } as Tab),
    [
      allocationId,
      areCapTableSecuritiesInvalid,
      collapsibleColumns,
      equityAllocationRowsGroups,
      handleDeleteScenario,
      handleOnSpreadsheetChange,
      isAllocationEmpty,
      isCurrentValuationDisabled,
      isFundOwnershipEmpty,
      isLoadingAllocationScenariosValues,
      isLoadingBacksolvesEquityValues,
      isLoadingRiskFreeRates,
      isLoadingValuationSummary,
      isUpdatingSpreadsheets,
      isUpdatingValuation,
      isValuationEmpty,
      measurementDate,
      notes,
      onAddNote,
      onDeleteNote,
      onUpdateNotes,
      setNotes,
      shouldDisplayWeightedShareValues,
      weightedShareValuesNames,
      workbook,
    ]
  );

  // Valuations Allocation Spreadsheets names
  const valuationsAllocationTablesNames = useMemo(() => {
    const valuationSummaryTable = {
      [VALUATION_SUMMARY_KEY]: {
        approachType: '',
        id: VALUATION_SUMMARY_KEY,
        name: VALUATION_SUMMARY_LABEL,
      } as InvalidTable,
    } as InvalidTables;

    const equityAllocationTable = {
      [EQUITY_ALLOCATION_KEY]: {
        approachType: '',
        id: VALUATION_SUMMARY_KEY,
        name: EQUITY_ALLOCATION_LABEL,
      } as InvalidTable,
    } as InvalidTables;

    const weightedShareValuesTables = getObjectValue(
      weightedShareValuesNames.reduce((accumulator, current) => {
        const weightedShareValueName = getStringValue(current);

        return {
          ...accumulator,
          [weightedShareValueName]: {
            approachType: '',
            id: VALUATION_SUMMARY_KEY,
            name: ALLOCATION_WEIGHTED_SHARE_VALUES_LABEL,
          } as InvalidTable,
        };
      }, {} as InvalidTables)
    );

    const approachesTables = getObjectValue(
      approaches?.reduce((accumulator, current) => {
        const { approach_type: approachType, name } = current;
        const approachTableName = getApproachTableName({ approach: current });

        return {
          ...accumulator,
          [approachTableName]: {
            approachType: getStringValue(approachType),
            id: approachTableName,
            name: getStringValue(name),
          } as InvalidTable,
        };
      }, {} as InvalidTables)
    );

    const tablesNames = {
      ...valuationSummaryTable,
      ...equityAllocationTable,
      ...weightedShareValuesTables,
      ...approachesTables,
    } as InvalidTables;

    return tablesNames;
  }, [approaches, weightedShareValuesNames]);

  const handleMenuOpen = (event: MouseEvent<HTMLSpanElement>) => setMenuAnchorEl(event.currentTarget);

  // Synchronize Refresh Approaches
  const synchronizeRefreshedApproaches = useCallback(
    (refreshedApproaches: ValuationsApproach[]) => {
      valuation.valuations_approaches = [...refreshedApproaches];
      setApproaches([...refreshedApproaches]);
    },
    [valuation]
  );

  // Refresh Approaches
  const refreshApproachesPublicComps = useCallback(
    (publicComps: GetPublicCompsByDateHook['data']) => {
      const approachOutdatedIndex = approaches.findIndex(approach => approach.panelId === approachToUpdate?.panelId);
      const approachOutdated = approaches[approachOutdatedIndex];

      const refreshedApproaches = getRefreshedApproaches(
        publicComps,
        [approachOutdated],
        approaches,
        true // isValuationDateChanged
      ) as ValuationsApproach[];

      handleMenuClose();

      setCurrentPublicCompsByDate(publicComps);
      synchronizeRefreshedApproaches(refreshedApproaches);
    },
    [approachToUpdate, approaches, getRefreshedApproaches, synchronizeRefreshedApproaches]
  );

  // New Version Dialog
  const handleOpenNewVersionDialog = () => setOpenNewVersionDialog(true);

  const handleCloseNewVersionDialog = useCallback(() => {
    setOpenNewVersionDialog(false);
    setVersionFormState(defaultVersionFormState);
  }, []);

  // Update Approach Dialog
  const handleCloseUpdateApproachDialog = () => setOpenUpdateApproachDialog(false);

  // Updated Valuation Approach GPC
  const getUpdatedValuationApproachGPC = useCallback(
    (approachOutdated: ValuationsApproach) =>
      ({
        ...approachOutdated.valuations_approach_gpc,
        gpc_comparison: [
          ...getArrayValue(
            approachOutdated?.valuations_approach_gpc?.gpc_comparison?.map(comp => ({
              ...comp,
            }))
          ),
        ],
      } as ValuationApproachGPC),
    []
  );

  // Update Guideline Public Companies Approach
  const updateGPCApproach = useCallback(
    params => {
      const {
        approachOutdated,
        updatedApproach: approach,
        valuationDate,
        isEvaluatingEquityValue,
        useMultiplePremiumDiscount,
      } = params;

      let updatedApproach = { ...approach };

      // Valuation Date Changed
      if (
        updatedApproach.approach_type === VALUATIONS_PUBLIC_COMPANIES_APPROACH
        && valuationDate !== approachOutdated.valuation_date
      ) {
        const updatedValuationApproachGPC = getUpdatedValuationApproachGPC(approachOutdated);

        updatedApproach = {
          ...updatedApproach,
          valuations_approach_gpc: updatedValuationApproachGPC,
        };
      }
      if (updatedApproach.approach_type === VALUATIONS_PUBLIC_COMPANIES_APPROACH) {
        updatedApproach.valuations_approach_gpc.is_evaluating_equity_value = isEvaluatingEquityValue;
        updatedApproach.valuations_approach_gpc.use_multiple_premium_discount = useMultiplePremiumDiscount;
      }

      return updatedApproach;
    },
    [getUpdatedValuationApproachGPC]
  );

  // Update DCF Approach
  const updateDCFApproach = useCallback(
    params => {
      const { approachOutdated, updatedApproach: approach, updatedFinancialStatmentId } = params;

      let updatedApproach = { ...approach };

      const currentApproach = currentValuationApproaches.current?.find(
        valuationApproach =>
          (!isNil(approach?.id) && valuationApproach?.id === updatedApproach?.id)
          || (!isNil(updatedApproach?.panelId) && valuationApproach?.panelId === updatedApproach?.panelId)
          || (!isNil(updatedApproach?.panel_id) && valuationApproach?.panel_id === updatedApproach?.panel_id)
      );

      if (
        updatedApproach.approach_type === VALUATIONS_DISCOUNT_CASH_FLOW_APPROACH
        && !isNull(updatedFinancialStatmentId)
        && approachOutdated?.financial_statement !== updatedFinancialStatmentId
      ) {
        updatedApproach = updateDCFPeriodsByVersion({
          currentApproach,
          otherFinancialStatements,
          primaryFinancialStatement,
          updatedApproach,
        });
      }

      return updatedApproach;
    },
    [otherFinancialStatements, primaryFinancialStatement, currentValuationApproaches]
  );

  // Update Approach from Dialog
  const handleUpdateApproach = useCallback(
    async (formState: ValuationsFormState) => {
      const { values } = getObjectValue(formState);

      if (!isEmpty(approachToUpdate) && !isEmpty(values)) {
        const {
          name: updatedName,
          financial_statement_id: selectedFinancialStatementId,
          valuation_date: selectedValuationDate,
          enterprise_equity_value: enterpriseEquityValue,
          is_calibration: isCalibration,
          use_multiple_premium_discount: useMultiplePremiumDiscount,
        } = getObjectValue(values);

        const approachToUpdateIndex = approaches?.findIndex(
          approach => approach?.panelId === approachToUpdate?.panelId
        );
        const approachOutdated = approaches[approachToUpdateIndex];

        if (approachOutdated.valuations_approach_gpc) {
          updatePublicComps({
            approach: approachOutdated,
            currentApproaches: approaches,
            valuationDate: getStringValue(selectedValuationDate),
          });
        }

        const valuationDate = selectedValuationDate ? dbShortDate(selectedValuationDate) : null;

        const firstFinancialStatement = financialVersions?.[0];

        const updatedFinancialStatmentId = getNumberValue(selectedFinancialStatementId ?? firstFinancialStatement?.id);

        let updatedApproach = {
          ...approachOutdated,
          name: getStringValue(updatedName ?? approachOutdated.name),
          valuation_date: getStringValue(valuationDate),
        } as ValuationApproachWithReferences;

        // Update Financial Statement Version
        if (!isNil(updatedFinancialStatmentId)) {
          updatedApproach.financial_statement = updatedFinancialStatmentId;
        }
        const isEvaluatingEquityValue = enterpriseEquityValue === 'Equity Value';
        // Guideline Public Companies
        updatedApproach = updateGPCApproach({
          approachOutdated,
          updatedApproach,
          valuationDate,
          isEvaluatingEquityValue,
          useMultiplePremiumDiscount,
        });

        // Discounted Cash Flow
        updatedApproach = updateDCFApproach({ approachOutdated, updatedApproach, updatedFinancialStatmentId });

        updatedApproach.is_calibration = getBooleanValue(isCalibration);

        const updatedApproaches = approaches?.filter(
          valuationApproach => valuationApproach?.panelId !== approachToUpdate?.panelId
        );

        updatedApproaches?.splice(approachToUpdateIndex, 0, updatedApproach);

        updateValuationApproaches(updatedApproaches);
        setPageStatus(VALIDATED);
        setArePendingChanges(true);
      }

      if (!isEmpty(calibrationToUpdate)) {
        const { name: updatedName } = getObjectValue(values);

        const calibrationToUpdateIndex = valuation.calibrations?.findIndex(
          calibration => calibration.panelId === calibrationToUpdate?.panelId
        );

        const updatedCalibrations = [...valuation.calibrations];

        updatedCalibrations.splice(calibrationToUpdateIndex, 1, {
          ...updatedCalibrations[calibrationToUpdateIndex],
          name: updatedName ?? '',
        });

        updateValuationCalibrations(updatedCalibrations);

        setPageStatus(VALIDATED);
        setArePendingChanges(true);
      }
    },
    [
      approachToUpdate,
      approaches,
      calibrationToUpdate,
      financialVersions,
      updateDCFApproach,
      updateGPCApproach,
      updatePublicComps,
      updateValuationApproaches,
      updateValuationCalibrations,
      valuation,
    ]
  );

  // Update Approach Dialog
  const handleUpdateApproachDialog = useCallback(
    (approachPanelId: string) => {
      if (approachPanelId) {
        handleMenuClose();

        const approachToBeUpdated = approaches?.find(
          valuationApproach => valuationApproach?.panelId === approachPanelId
        );

        if (approachToBeUpdated) {
          setCalibrationToUpdate(null);
          handleOpenUpdateApproachDialog();
          setApproachToUpdate(approachToBeUpdated);
        }
      }
    },
    [approaches, setCalibrationToUpdate]
  );

  // Update Approach Name Dialog
  const showUpdateApproachNameDialog = useCallback(
    (approachPanelId: string) => {
      if (approachPanelId) {
        setIsEditingCalibrationName(false);
        setIsEditingApproachName(true);
        setIsChangingFinancialStatement(false);
        handleUpdateApproachDialog(approachPanelId);
      }
    },
    [handleUpdateApproachDialog]
  );

  // Delete Approach
  const handleDeleteApproach = useCallback(
    (approachPanelId: string) => {
      if (approachPanelId) {
        const approachToBeDeleted = approaches?.find(
          valuationApproach => valuationApproach?.panelId === approachPanelId
        );
        const { id: deletedApproachId } = getObjectValue(approachToBeDeleted);

        setDeletedApproachesIds(previousState => [
          ...previousState,
          ...(!isNil(deletedApproachId) ? [deletedApproachId] : []),
        ]);

        const updatedApproaches = approaches?.filter(
          valuationApproach => valuationApproach?.panelId !== approachPanelId
        );

        updateValuationApproaches(updatedApproaches);
        setPageStatus(VALIDATED);
        setArePendingChanges(true);

        setTimeout(() => {
          setApproachQueryParam(VALUATION_SUMMARY_KEY);
        }, REDIRECT_TIMEOUT_DELAY);
      }
    },
    [approaches, setApproachQueryParam, updateValuationApproaches]
  );

  // Delete Approach Dialog
  const showDeleteApproachDialog = useCallback(
    (approachPanelId: string) => {
      if (approachPanelId) {
        handleMenuClose();
        const message
          = 'This approach cannot be deleted because it is being used in at least one guideline calibration. Remove it or select a different approach';
        const contentCalibrationMessage = () => (
          <DeleteApproachDialog customMessage={message} isReferencedApproach={false} />
        );

        let relatedCalibration = false;
        if (valuation.calibrations.length > 0) {
          const { calibrations } = valuation;
          calibrations.forEach(calibration => {
            if (calibration.public_comps_approach_calibration?.toString() === approachPanelId) {
              relatedCalibration = true;
            }
          });
        }

        if (relatedCalibration) {
          showDialog({
            wrapperProps: {
              title: 'Action not allowed',
            },
            content: contentCalibrationMessage,
            wrapper: ConfirmationDialog,
            actions: [
              {
                label: 'Cancel',
                variant: 'contained',
                type: 'primary',
              },
            ],
          });
        } else {
          const approachToBeDeleted = approaches?.find(
            valuationApproach => valuationApproach?.panelId === approachPanelId
          );

          const isReferencedApproach = approachesUsedForAllocationScenarios?.includes(
            getNumberValue(approachToBeDeleted?.id)
          );

          const content = () => <DeleteApproachDialog isReferencedApproach={isReferencedApproach} />;

          showDialog({
            actions: [
              {
                callback: () => handleDeleteApproach(approachPanelId),
                label: DELETE_VALUATION_BUTTON_LABEL,
                type: 'danger',
                variant: 'contained',
              },
            ],
            content,
            wrapper: ConfirmationDialog,
          });
        }
      }
    },
    [approaches, approachesUsedForAllocationScenarios, handleDeleteApproach, showDialog, valuation]
  );

  // Delete Approach Dialog
  const showDeleteCalibrationDialog = useCallback(
    (panelIdParam: string) => {
      if (panelIdParam) {
        handleMenuClose();
        const content = () => <DeleteCalibrationDialog />;

        showDialog({
          actions: [
            {
              callback: () => handleDeleteCalibration(panelIdParam),
              label: DELETE_VALUATION_BUTTON_LABEL,
              type: 'danger',
              variant: 'contained',
            },
          ],
          content,
          wrapper: ConfirmationDialog,
        });
      }
    },
    [handleDeleteCalibration, showDialog]
  );

  const isUpdatedApproachTypeOf = useCallback(
    (approachType: ApproachTypes) => {
      if (approachToUpdate) {
        const approachToBeUpdated = approaches?.find(approach => approach?.panelId === approachToUpdate?.panelId);
        return approachToBeUpdated?.approach_type === approachType;
      }

      return false;
    },
    [approachToUpdate, approaches]
  );

  // Validate if the GPC comparisons companies need to be refreshed because the new gpc approach equity option needs more data from capiq.
  // This is only for the cases where an user saved  a gpc approach before the new equity option was deployed.
  const isEquityOptionAvailable = (comps: GPC[]) => {
    if (!comps?.length) {
      return true;
    }

    const hasNullFieldInEquityValues = comps.some(comparison =>
      EQUITY_ATTRIBUTES_NEEDED.some(comparisonAttribute => comparison[comparisonAttribute] === null)
    );

    return !hasNullFieldInEquityValues;
  };

  const shouldDisplayEquityOption = (approach: ValuationsApproach | null) => {
    if (approach?.approach_type === VALUATIONS_PUBLIC_COMPANIES_APPROACH) {
      return isEquityOptionAvailable(approach.valuations_approach_gpc?.gpc_comparison ?? []);
    }
    return false;
  };

  // Update Financial Statement Dialog
  const showUpdateFinancialStatementDialog = useCallback(
    (approachPanelId: string) => {
      if (approachPanelId) {
        setIsChangingFinancialStatement(true);
        setIsEditingApproachName(false);
        handleUpdateApproachDialog(approachPanelId);
      }
    },
    [handleUpdateApproachDialog]
  );

  // Save Documents
  const saveApproachDocuments = useCallback(
    async (approach: ValuationsApproach) => {
      if (!isEmpty(filesInApproach)) {
        // Save Files for each Approach
        const filesToBeSaved = filesInApproach?.filter(file => file?.panelId === approach?.panelId);

        // Add Reference to Files to be saved
        const filesReferences = filesToBeSaved?.map(async file =>
          addReferenceForExistingDocuments(
            getNumberValue(measurementDate?.id),
            getArrayValue(file?.filesToSave),
            getNumberValue(approach?.id),
            VALUATIONS_VALUATION_REFERENCES_MAP.get(getStringValue(approach?.approach_type))
          )
        );

        await Promise.all(filesReferences);

        setFilesInApproach([]);
      }
    },
    [addReferenceForExistingDocuments, filesInApproach, measurementDate]
  );

  // Save Approaches Notes
  const saveApproachNotes = useCallback(
    (approach: ValuationsApproach) => {
      if (!isEmpty(notesInApproach)) {
        // Save Notes for each Approach
        const notesToBeSaved = notesInApproach?.filter(note => note?.panelId === approach?.panelId);

        notesToBeSaved?.forEach(note => {
          if (note?.notes && !isEmpty(note?.notes) && note?.notesHasChanged) {
            saveNotes(note?.notes);
          }
        });
      }
    },
    [notesInApproach, saveNotes]
  );

  // Save Allocation Notes
  const saveAllocationNotes = useCallback(() => {
    if (!isEmpty(notes) && notesHasChanged) {
      saveNotes();
    }
  }, [notes, notesHasChanged, saveNotes]);

  // Save/Update Documents and Notes
  const saveValuationAllocationDocumentsAndNotes = useCallback(() => {
    // Save Documents and Notes for each Approach
    if (!isEmpty(approaches) && (!isEmpty(filesInApproach) || !isEmpty(notesInApproach))) {
      approaches?.forEach(approach => {
        if (!isNil(approach?.id)) {
          // Documents
          saveApproachDocuments(approach);

          // Notes
          saveApproachNotes(approach);
        }
      });
    }

    // Save Allocation Notes
    if (!isNil(allocationId)) {
      saveAllocationNotes();
    }
  }, [
    allocationId,
    approaches,
    filesInApproach,
    notesInApproach,
    saveAllocationNotes,
    saveApproachDocuments,
    saveApproachNotes,
  ]);

  // Handle Invalid Approaches
  const handleInvalidTables = useCallback(
    (sheetsWithInvalidCells: ValuationsAreCellsValidValues['sheetsWithInvalidCells']) => {
      const allInvalidTables = [] as string[];

      // Get all Invalid Approaches
      sheetsWithInvalidCells.forEach(currentSheet =>
        currentSheet?.forEach(invalidCell => {
          const { sheet: invalidSheet } = getObjectValue(invalidCell);
          const { name: invalidSheetName = '' } = getObjectValue(invalidSheet);

          const extractedTableName = VALUATIONS_SPREADSHEET_APPROACH_TABLE_NAME_REGEX.exec(invalidSheetName);

          const approachTableName = extractedTableName?.[1] ?? invalidSheetName;

          allInvalidTables.push(approachTableName);

          // Display Invalid Cells
          invalidSheet?.updateAllCellsDisplayConfig();

          // Log Invalid Spreadsheet Cell
          logInvalidCell({
            approachTableName,
            invalidCell,
          });
        })
      );

      const invalidTablesNames = [...new Set(allInvalidTables)].map(tableName => {
        // Is an Approach
        if (tableName in valuationsAllocationTablesNames) {
          const { approachType, id, name } = valuationsAllocationTablesNames[tableName];

          return {
            approachType,
            id,
            name,
          } as InvalidTable;
        }

        // Valuation Summary
        return {
          approachType: '',
          id: VALUATION_SUMMARY_KEY,
          name: tableName,
        } as InvalidTable;
      });

      // Set Unique Invalid Tables names
      setInvalidTables(invalidTablesNames);

      // Display Invalid Cells on Console
      displayInvalidCells();
    },
    [displayInvalidCells, logInvalidCell, valuationsAllocationTablesNames]
  );

  // Validate All Spreadsheets
  const allSpreadsheetAreValid = useCallback(() => {
    const { areAllCellsValid, sheetsWithInvalidCells } = areCellsValid(true, true) as ValuationsAreCellsValidValues;

    // If all Cells are valid
    if (areAllCellsValid) {
      setInvalidTables([]);
      return true;
    }

    // If invalid Cells are found
    if (sheetsWithInvalidCells) {
      handleInvalidTables(sheetsWithInvalidCells);
    }

    return false;
  }, [areCellsValid, handleInvalidTables]);

  // Validate and Save Valuation
  const validateAndSaveValuationAllocation = useCallback(() => {
    // If all Cells are valid
    if (allSpreadsheetAreValid()) {
      const updatedValuationApproaches = approaches?.map(
        approach =>
          ({
            ...approach,
            financial_statement: getNumberValue(approach?.financial_statement ?? primaryFinancialStatementId),
            reference_for_sibling: getStringValue(approach?.panelId),
          } as ValuationApproachWithReferences)
      );

      const valuationToBeSaved = {
        ...valuation,
        deleted_approaches: deletedApproachesIds,
        valuations_approaches: updatedValuationApproaches,
        weighting_probabilities: weightingProbabilities,
        allocation: {
          ...allocation,
          deleted_allocation_scenario_ids: deletedScenariosIds,
        },
      } as ValuationWithReferences;

      saveValuation({
        cleanUnsavedChanges,
        currentValuationApproaches,
        setArePendingChanges,
        updatedValuation: valuationToBeSaved,
      });

      saveValuationAllocationDocumentsAndNotes();
    }

    setPageStatus(VALIDATED);
  }, [
    allSpreadsheetAreValid,
    allocation,
    approaches,
    cleanUnsavedChanges,
    deletedApproachesIds,
    deletedScenariosIds,
    primaryFinancialStatementId,
    saveValuation,
    saveValuationAllocationDocumentsAndNotes,
    valuation,
    weightingProbabilities,
  ]);

  // Publish Allocation
  const handlePublishAllocation = useCallback(async () => {
    const publishedAllocation = await publishAllocationVersion({ allocationId });
    const { published } = getObjectValue(publishedAllocation);

    if (published) {
      getValuationInfo().catch(handleCatchError);
      setCurrentAllocationStatus(ALLOCATIONS_PUBLISHED);
      setIsCurrentAllocationPublished(true);
    }
  }, [allocationId, getValuationInfo, publishAllocationVersion, setCurrentAllocationStatus]);

  // Publish Allocation Dialog
  const showPublishAllocationDialog = useCallback(() => {
    showDialog({
      actions: [
        {
          callback: handlePublishAllocation,
          label: PUBLISH_ALLOCATION_BUTTON_LABEL,
          type: 'success',
          variant: 'contained',
        },
      ],
      content: CenterAlignedParagraph,
      contentProps: { message: PUBLISH_ALLOCATION_CONFIRMATION_MESSAGE },
      wrapper: ConfirmationDialog,
    });
  }, [handlePublishAllocation, showDialog]);

  // Make Final Allocation
  const handleMakeFinalAllocation = useCallback(async () => {
    const finalAllocation = await makeFinalAllocationVersion({ allocationsIds: [getNumberValue(allocationId)] });
    const { updated } = getObjectValue(finalAllocation);

    if (updated) {
      getValuationInfo().catch(handleCatchError);
      setCurrentAllocationStatus(ALLOCATIONS_FINAL);
      setIsCurrentAllocationFinal(true);
    }
  }, [allocationId, getValuationInfo, makeFinalAllocationVersion, setCurrentAllocationStatus]);

  // Make Final Allocation Dialog
  const showMakeFinalAllocationDialog = useCallback(() => {
    showDialog({
      actions: [
        {
          callback: handleMakeFinalAllocation,
          label: MAKE_FINAL_ALLOCATION_BUTTON_LABEL,
          type: 'success',
          variant: 'contained',
        },
      ],
      content: CenterAlignedParagraph,
      contentProps: { message: MAKE_FINAL_ALLOCATION_CONFIRMATION_MESSAGE },
      wrapper: ConfirmationDialog,
    });
  }, [handleMakeFinalAllocation, showDialog]);

  // Create New Valuations Allocation Version
  const handleCreateNewVersion = useCallback(async () => {
    // If all Cells are valid
    if (allSpreadsheetAreValid()) {
      const updatedValuationApproaches = approaches?.map(
        approach =>
          ({
            ...approach,
            financial_statement: getNumberValue(approach?.financial_statement ?? primaryFinancialStatementId),
            reference_for_sibling: getStringValue(approach?.panelId),
          } as ValuationApproachWithReferences)
      );

      const { values } = versionFormState;

      const valuationToBeDuplicated = {
        ...valuation,
        deleted_approaches: deletedApproachesIds,
        name: getStringValue(values?.name),
        valuations_approaches: updatedValuationApproaches,
        weighting_probabilities: weightingProbabilities,
        allocation: {
          ...allocation,
          is_final: false,
          name: getStringValue(values?.name),
        },
      } as ValuationWithReferences;

      const { valuation: newValuationAllocationVersion } = await createNewVersion({
        valuation: valuationToBeDuplicated,
      });

      if (!isEmpty(newValuationAllocationVersion?.allocation)) {
        const { allocation: newAllocation, id: newValuationId } = getObjectValue(newValuationAllocationVersion);

        const {
          id: newAllocationId,
          is_final: isFinal,
          is_published: isPublished,
          name,
          slug,
          version,
        } = getObjectValue(newAllocation);

        const newAllocationVersion = {
          id: newAllocationId,
          is_final: isFinal,
          is_published: isPublished,
          name,
          slug,
          valuation: newValuationId,
          version,
        } as AllocationName;

        getVersions?.(newAllocationVersion);
      }

      cleanUnsavedChanges();
      setArePendingChanges(false);
    }
  }, [
    allSpreadsheetAreValid,
    allocation,
    approaches,
    cleanUnsavedChanges,
    createNewVersion,
    deletedApproachesIds,
    getVersions,
    primaryFinancialStatementId,
    valuation,
    versionFormState,
    weightingProbabilities,
  ]);

  // Change Valuation Ownership
  const changeValuationOwnership = useCallback(() => {
    const updatedValuation = { ...valuation, is_firm_owned: !isFirmOwned };
    setValuation(updatedValuation);

    // Save Valuation to avoid modifications from not Scalar users
    if (updatedValuation) {
      setPageStatus(VALIDATING);
    }
  }, [isFirmOwned, valuation, setValuation]);

  // Toggle ready for audit
  const toggleReadyForAudit = useCallback(async () => {
    const requestData = {
      valuationId: valuation.id,
      readyForAuditData: { is_ready_for_audit: !isReadyForAudit },
    } as unknown as SetReadyForAuditParams;
    const response = await setReadyForAudit(requestData);
    if (response) {
      const updatedValuation = { ...valuation, is_ready_for_audit: response.is_ready_for_audit };
      setValuation(updatedValuation);
    }
  }, [valuation, isReadyForAudit, setReadyForAudit, setValuation]);

  // Create Valuations Allocation Blank Version
  const createValuationsAllocationBlankVersion = useCallback(async () => {
    if (valuation && allocation) {
      // If all Cells are valid
      if (allSpreadsheetAreValid()) {
        const { id: currentMeasurementDateId, measurement_date: currentMeasurementDate }
          = getObjectValue(companyMeasurementDate);
        const { date } = getObjectValue(currentMeasurementDate);

        // Version number and Ownership will be set in by the Backend API
        const blankValuationAllocation = {
          allocation_id: allocation.id,
          deleted_approaches: [],
          id: valuation.id,
          name: `Firm version - ${date}`,
          valuations_approaches: [],
          weighting_probabilities: [],
          allocation: {
            allocation_scenarios: [],
            company_measurement_date: currentMeasurementDateId,
            date,
            fund_conclusion: [],
            id: allocation.id,
            is_final: false,
            is_published: false,
            name: `Firm version - ${date}`,
          },
        } as unknown as ValuationWithReferences;

        const { valuation: newValuationAllocationVersion } = await createNewVersion({
          valuation: blankValuationAllocation,
        });

        if (!isEmpty(newValuationAllocationVersion?.allocation)) {
          const { allocation: newAllocation, id: newValuationId } = getObjectValue(newValuationAllocationVersion);

          const {
            id: newAllocationId,
            is_final: isFinal,
            is_published: isPublished,
            name,
            slug,
            version,
          } = getObjectValue(newAllocation);

          const newAllocationVersion = {
            id: newAllocationId,
            is_final: isFinal,
            is_published: isPublished,
            name,
            slug,
            valuation: newValuationId,
            version,
          } as AllocationName;

          getVersions?.(newAllocationVersion);
        }

        cleanUnsavedChanges();
        setArePendingChanges(false);
      }
    }
  }, [
    allSpreadsheetAreValid,
    allocation,
    cleanUnsavedChanges,
    companyMeasurementDate,
    createNewVersion,
    getVersions,
    valuation,
  ]);

  // Update Percentile Rows
  const updatePercentileRows = useCallback(
    (currentChangedCells: ChangedCell[]) => {
      const cell = currentChangedCells?.[0];

      const isPercentileCell = PERCENTILE_ROWS.includes(cell?.alias) && PERCENTILE_ROWS.includes(cell?.key);

      if (isPercentileCell) {
        const { sheet } = getObjectValue(cell);
        const { tableData } = getObjectValue(sheet);
        let { approach } = getObjectValue(tableData);

        // Update GPC or GPT approach
        if (approach && !isEmpty(approach)) {
          const numericValue = Number(cell?.value);

          const { rowConfigGenerator, rowTransformer, specificApproachProperty, updatedApproach }
            = updatePercentileAndGetConfiguration({
              alias: getStringValue(cell?.alias),
              approach,
              updatedValue: getNumberValue(numericValue),
            });

          // Updating the Approach with the Percentile value
          approach = { ...approach, ...updatedApproach };

          const approachProperty = approach[getStringValue(specificApproachProperty) as keyof ValuationsApproach];

          const { gpcOptions } = getGPCOptions(approaches);

          const updatedRowConfig
            = specificApproachProperty === VALUATIONS_APPROACH_GPC_PROPERTY
              ? (rowConfigGenerator as GPCRowConfig)({
                allCompGroups: compGroups as CompGroupForRowConfig[],
                approach: approachProperty as ReverseParsedValuationApproachGPC,
                companyName: getStringValue(tableData?.companyName),
                isDisabled: isCurrentValuationDisabled,
              })
              : (rowConfigGenerator as GPTRowConfig)({
                allCompGroups: compGroups as CompGroupForRowConfig[],
                approach: approachProperty as ReverseParsedValuationApproachGPT,
                companyName: getStringValue(tableData?.companyName),
                gpcOptions,
                isDisabled: isCurrentValuationDisabled,
              });
          const columnsForReset
            = specificApproachProperty === VALUATIONS_APPROACH_GPC_PROPERTY
              ? (rowTransformer as GPCRowTransformer)({
                approach: approachProperty as ValuationApproachGPC,
                financials,
                financialsPeriods: financialPeriods,
                financialStatementId: getNumberValue(approach.financial_statement),
                measurementDate: companyMeasurementDate.measurement_date,
              })
              : (rowTransformer as GPTRowTransformer)({
                approach: approachProperty as ValuationApproachGPT,
                financials,
              });

          cell?.sheet?.reset({
            columns: columnsForReset as ResetParams['columns'],
            rowConfig: updatedRowConfig,
            tableData: { ...tableData, ...getObjectValue(approach) },
          });

          resetConfigurations();
        }
      }
    },
    [
      approaches,
      compGroups,
      financialPeriods,
      financials,
      isCurrentValuationDisabled,
      resetConfigurations,
      companyMeasurementDate.measurement_date,
    ]
  );

  // Update Enterprise Values
  const updateEnterpriseValues = useCallback(
    (updatedCells: ChangedCell[]) => {
      const cellsToBeUpdated = updatedCells
        .filter(cell => cell?.customKey === VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY)
        .filter(cell => cell?.value !== enterpriseValues?.[getStringValue(cell?.sheet?.name)]);

      if (!isEmpty(cellsToBeUpdated)) {
        const updatedEnterpriseValue = { ...enterpriseValues } as EnterpriseValues;

        cellsToBeUpdated?.forEach(cell => {
          updatedEnterpriseValue[getStringValue(cell?.sheet?.name)] = getStringValue(cell?.value?.toString() ?? '0');
        });

        setEnterpriseValues(updatedEnterpriseValue);
      }
    },
    [enterpriseValues, setEnterpriseValues]
  );

  // Unsaved Changes Dialog
  const showUnsavedChangesDialog = useCallback(
    () =>
      setUnsavedChanges?.({
        content: ConfirmUnsavedChanges,
        wrapper: ConfirmationDialog,
      }),
    [setUnsavedChanges]
  );

  // Get Spreadsheets Names
  const getSpreadsheetsNames = useCallback(
    (configuredSpreadsheets?: ConfiguredSpreadsheets) =>
      Object.entries(getObjectValue(configuredSpreadsheets)).reduce((accumulator, current) => {
        const [key, spreadsheet] = current;
        const { name } = getObjectValue(spreadsheet);

        return {
          ...accumulator,
          [getStringValue(name)]: getStringValue(key),
        };
      }, {} as SpreadsheetsNames),
    []
  );

  // Get Financial Statement Version Name
  const getFinancialStatementName = useCallback(
    (financialStatementId: number) =>
      getStringValue(financialVersions?.find(financialVersion => financialVersion?.id === financialStatementId)?.name),
    [financialVersions]
  );

  const getApproachesByType = useCallback(
    ({ approachType }) => approaches?.filter(approachItem => approachItem?.approach_type === approachType),
    [approaches]
  );

  const getIsGPCInApproach = useCallback((params: GetIsGPCInApproachParams) => {
    const { approachGPCId, approachType, approachesToCheck } = params;

    const approachKey
      = approachType === 'backsolve' ? 'valuations_approach_backsolve' : 'valuations_approach_future_exit';

    return approachesToCheck?.some(
      approach => approach?.[approachKey]?.valuation_approach_gpc?.toString() === approachGPCId
    );
  }, []);

  // Opened Approaches
  const addOpenedApproach = useCallback((approachTableName: string) => {
    setOpenedApproaches(prevState => new Map(prevState.set(approachTableName, true)));
  }, []);

  // Redirect to the New Approach
  const redirectToNewApproach = useCallback(
    (params: RedirectToNewApproachParams) => {
      const { approach, approachTableName, configIndex } = params;

      setTimeout(() => {
        if (
          isNil(approach?.id)
          && approachesQuantity === configIndex + 1
          && !openedApproaches?.get(getStringValue(approachTableName))
        ) {
          setApproachQueryParam(approachTableName);
          addOpenedApproach(approachTableName);
          scrollToTop();
        }
      }, REDIRECT_TIMEOUT_DELAY);
    },
    [addOpenedApproach, approachesQuantity, openedApproaches, setApproachQueryParam]
  );

  const userCanToggleAuditState = useMemo(() => {
    const currentFirmRole = user?.firms_permissions.find(perm => perm.id === firmId)
      ?.user_firm_role as unknown as number;
    const validRoleAccess = [FIRM_ADMIN_VALUE, FUND_ADMIN_VALUE, ANALYST_VALUE].includes(currentFirmRole);
    return user?.is_superuser || user?.is_staff || validRoleAccess;
  }, [user, firmId]);

  // Valuation Context
  const ValuationContextValues = useMemo(
    () => ({
      approaches,
      backsolveAttributes,
      allocationMethodsOptions,
      updateApproachesScenarioMethods,
      companyMeasurementDate,
      compGroups,
      disableFinancialsSelection: isUpdatedApproachTypeOf(VALUATIONS_EXTERNAL_VALUATION_APPROACH),
      filesInApproach,
      financials,
      financialsPeriods: financialPeriods,
      financialStatementPeriods: financialFiscalYearPeriods,
      financialStatementPeriodsPerMD: financialPeriodsPerMD,
      financialsVersions: financialVersions,
      gpcAttributes: guidelinePublicCompaniesAttributes,
      calibrationAttributes,
      isChangingFinancialStatement,
      isEditingApproachName,
      isEditingCalibrationName,
      measurementDate,
      notesInApproach,
      onChange: onWorkbookChange,
      publicCompsAttrs: publicCompaniesAttributes,
      resetConfiguration: resetConfigurations,
      selectedFinancialsVersion,
      setAreThereChanges: setArePendingChanges,
      setCashTaxRate,
      setFilesInApproach,
      setNotesInApproach,
      valuation,
      workbook,
    }),
    [
      approaches,
      backsolveAttributes,
      compGroups,
      allocationMethodsOptions,
      updateApproachesScenarioMethods,
      companyMeasurementDate,
      filesInApproach,
      financialFiscalYearPeriods,
      financialPeriods,
      financialPeriodsPerMD,
      financialVersions,
      financials,
      guidelinePublicCompaniesAttributes,
      calibrationAttributes,
      isChangingFinancialStatement,
      isEditingApproachName,
      isEditingCalibrationName,
      measurementDate,
      notesInApproach,
      onWorkbookChange,
      publicCompaniesAttributes,
      resetConfigurations,
      selectedFinancialsVersion,
      setCashTaxRate,
      isUpdatedApproachTypeOf,
      valuation,
      workbook,
    ]
  );

  // Synchronize and Update Approaches
  useEffect(() => {
    // This prevents an infinite update loop
    if (shouldSynchronizeApproaches) {
      synchronizeAndUpdateApproaches(
        valuation,
        financialPeriods,
        measurementDate,
        getObjectValue(companyInfo),
        setAreSynchronized,
        setApproaches,
        combinedGPCComparisonPerfMetricAttrs
      );
    }
  }, [
    companyInfo,
    financialPeriods,
    guidelinePublicCompaniesAttributes,
    measurementDate,
    shouldSynchronizeApproaches,
    valuation,
    combinedGPCComparisonPerfMetricAttrs,
  ]);

  // Refresh Approaches Public Comps
  useEffect(() => {
    if (
      !isEmpty(publicCompsByDate)
      && !isLoadingPublicCompsByDate
      && !isEqual(publicCompsByDate, currentPublicCompsByDate)
      && !shouldSynchronizeApproaches
    ) {
      refreshApproachesPublicComps(publicCompsByDate);
    }
  }, [
    currentPublicCompsByDate,
    isLoadingPublicCompsByDate,
    publicCompsByDate,
    refreshApproachesPublicComps,
    shouldSynchronizeApproaches,
  ]);

  // Synchonize Enterprise Values
  useEffect(() => {
    if (data) {
      const currentEnterpriseValues = { ...enterpriseValues };

      Object.entries(data).forEach(([sheetName, sheet]) => {
        const { cells: processedCells } = sheet;

        if (sheetName === VALUATION_SUMMARY_KEY) {
          if (processedCells) {
            sheet?.reverseParse?.();
          }
        } else if (processedCells?.enterprise_value?.value) {
          currentEnterpriseValues[sheetName] = getStringValue(processedCells?.enterprise_value?.value.toString());
        }
      });

      const areEnterpriseValuesSynchronized = Object.entries(currentEnterpriseValues).every(
        ([sheetName, sheetEnterpriseValue]) => sheetEnterpriseValue === enterpriseValues[sheetName]
      );

      // Update Enterprise Values if they are different
      if (!areEnterpriseValuesSynchronized) {
        setEnterpriseValues(currentEnterpriseValues);
      }
    }
  }, [data, enterpriseValues, setEnterpriseValues]);

  // Set Allocation Values
  useEffect(() => {
    updateAllocationValues();
  }, [updateAllocationValues]);

  // Updating Allocation Values
  useEffect(() => {
    // Set current Allocation Status
    if (!isNil(currentAllocationStatus)) {
      setCurrentAllocationStatus?.(currentAllocationStatus);
    }

    // Set current Equity Value
    const equityToSet = isScalarOwnedDraftAndNotStaff ? 0 : currentWeightedEquityValue;
    setCurrentEquityValue?.(equityToSet);

    const weightedShareValuesSpreadsheets = getArrayValue(
      Object.values(getObjectValue(workbook))?.filter(
        spreadsheet => spreadsheet?.page === ALLOCATION_WEIGHTED_SHARE_VALUES_KEY
      )
    );
    // Set current Firm Total
    const firmTotalToSet = isScalarOwnedDraftAndNotStaff || isEmpty(weightedShareValuesSpreadsheets) ? 0 : firmTotal;
    setCurrentFirmTotal?.(firmTotalToSet);

    return () => {
      // Reset current Allocation Status
      setCurrentAllocationStatus?.(null);
      // Reset current Equity Value
      setCurrentEquityValue?.(null);
      // Reset current Firm Total
      setCurrentFirmTotal?.(null);
    };
  }, [
    currentAllocationStatus,
    firmTotal,
    currentWeightedEquityValue,
    setCurrentAllocationStatus,
    setCurrentEquityValue,
    setCurrentFirmTotal,
    isScalarOwnedDraftAndNotStaff,
    workbook,
  ]);

  // Updating Synchronized Spreadsheets
  useEffect(() => {
    setSynchronizedSpreadsheets([
      VALUATION_SUMMARY_SPREADSHEET_TABLE_TERMS.tableSlug,
      EQUITY_ALLOCATION_SPREADSHEET_TABLE_TERMS.tableSlug,
    ]);

    return () => {
      // Reset Synchronized Spreadsheets
      setSynchronizedSpreadsheets?.([]);

      // Reset Synchronized By
      setSynchronizedScroll?.({
        synchronizedBy: null,
        synchronizedScrollLeft: 0,
      });
    };
  }, [setSynchronizedScroll, setSynchronizedSpreadsheets]);

  // Save/Update Valuation Allocation
  useEffect(() => {
    if (isValidatingValuation) {
      validateAndSaveValuationAllocation();
    }
  }, [isValidatingValuation, validateAndSaveValuationAllocation]);

  // Display Unsaved Changes Dialog
  useEffect(() => {
    if (arePendingChanges) {
      showUnsavedChangesDialog();
    }
  }, [arePendingChanges, showUnsavedChangesDialog]);

  const changeOwnershipActions = useMemo(
    () => [
      {
        label: 'Change ownership',
        type: 'primary' as const,
        callback: changeValuationOwnership,
      },
    ],
    [changeValuationOwnership]
  );

  const setReadyForAuditActions = useMemo(
    () => [
      {
        label: isReadyForAudit ? REMOVE_READY_FOR_AUDIT : SET_AS_READY_FOR_AUDIT,
        type: 'primary' as const,
        callback: toggleReadyForAudit,
      },
    ],
    [toggleReadyForAudit, isReadyForAudit]
  );

  // Set Page Actions
  useEffect(() => {
    if (!isEmpty(approaches) || !isEmpty(deletedApproachesIds) || !isAllocationEmpty) {
      const areUnsavedScenarios = allocationScenarios?.some(scenario => scenario?.id === 0);
      const readyForAuditOptions = isReadyForAudit ? REMOVE_READY_FOR_AUDIT : READY_FOR_AUDIT;
      const secondaryActions = isAllocationValid
        ? [
          !isCurrentAllocationPublished
              && !isCurrentAllocationFinal && {
            callback: showPublishAllocationDialog,
            disabled: areUnsavedScenarios,
            isActive: !isAllocationEmpty || shouldDisplayWeightedShareValues,
            label: PUBLISH_ALLOCATION_BUTTON_LABEL,
          },
          !isCurrentAllocationFinal
              && isCurrentAllocationPublished && {
            callback: showMakeFinalAllocationDialog,
            disabled: areUnsavedScenarios,
            isActive: !isAllocationEmpty || shouldDisplayWeightedShareValues,
            label: MAKE_FINAL_ALLOCATION_BUTTON_LABEL,
          },
          {
            callback: handleOpenNewVersionDialog,
            isActive: true,
            label: SAVE_AS_NEW_VERSION_LABEL,
          },
          {
            label: CHANGE_TO_SCALAR_VALUATION_LABEL,
            callback: () =>
              showDialog({
                wrapper: ConfirmationDialog,
                wrapperProps: {
                  title: CHANGE_VALUATION_OWNERSHIP_LABEL,
                },
                content: ValuationOwnershipConfirmation,
                actions: changeOwnershipActions,
              }),
            isActive: isFirmOwned && isUserStaff, // if is_firm_owned true
          },
          {
            label: GIVE_OWNERSHIP_TO_FIRM_LABEL,
            callback: () =>
              showDialog({
                wrapper: ConfirmationDialog,
                wrapperProps: {
                  title: CHANGE_VALUATION_OWNERSHIP_LABEL,
                },
                content: ValuationOwnershipConfirmation,
                actions: changeOwnershipActions,
              }),
            isActive: !isFirmOwned && isUserStaff, // if is_firm_owned false
          },
          isAllocationValid
              && userCanToggleAuditState && {
            label: readyForAuditOptions,
            callback: () =>
              showDialog({
                wrapper: ConfirmationDialog,
                wrapperProps: {
                  title: readyForAuditOptions,
                },
                content: () => <SetReadyForAuditConfirmation currentReadinessStatus={isReadyForAudit} />,
                actions: setReadyForAuditActions,
              }),
            isActive: true,
          },
        ]
        : [];

      updatePageActions?.({
        mainAction: {
          callback: () => setPageStatus(VALIDATING),
          // On mainAction isActive === isDisabled
          isActive: isCurrentValuationDisabled || isLoadingAllocationScenariosValues || isUpdatingSpreadsheets,
          isLock: isCurrentAllocationFinal,
          label: SAVE_VALUATION_BUTTON_LABEL,
        },
        secondaryActions,
      });
    }

    return () => {
      // Reset Page Actions
      updatePageActions?.(null);
    };
  }, [
    allocationScenarios,
    approaches,
    deletedApproachesIds,
    isAllocationEmpty,
    isAllocationValid,
    isCurrentAllocationFinal,
    isCurrentAllocationPublished,
    isCurrentValuationDisabled,
    isLoadingAllocationScenariosValues,
    isUpdatingSpreadsheets,
    updatePageActions,
    shouldDisplayWeightedShareValues,
    showMakeFinalAllocationDialog,
    showPublishAllocationDialog,
    isFirmOwned,
    changeValuationOwnership,
    isUserStaff,
    userCanToggleAuditState,
    showDialog,
    changeOwnershipActions,
    isReadyForAudit,
    setReadyForAuditActions,
  ]);

  // Update Enterprise Values and Percentile Rows
  useEffect(() => {
    const currentChangedCells = [...changedCells];

    if (!isEmpty(currentChangedCells)) {
      updateEnterpriseValues(currentChangedCells);
      updatePercentileRows(currentChangedCells);
      setChangedCells([]);
    }
  }, [changedCells, updateEnterpriseValues, updatePercentileRows]);

  // Handle Valuation disabled status
  useEffect(() => {
    setIsCurrentValuationDisabled(
      isCurrentAllocationFinal || isUpdatingValuation || (!isFirmOwned && !isUserStaff) || viewOnlyUser
    );
  }, [isCurrentAllocationFinal, isUpdatingValuation, isFirmOwned, isUserStaff, viewOnlyUser]);

  // Create Valuation Approaches Tabs
  useEffect(() => {
    let createValuationTabsTimer: NodeJS.Timeout;

    if (configurations && cells && workbook) {
      createValuationTabsTimer = setTimeout(() => {
        const calibrationTab = configurations
          ?.filter(config => config?.name === CALIBRATION_MODEL_NAME && config?.calibration?.panelId !== '0')
          ?.map(config => {
            const { spreadsheets: configuredSpreadsheets, calibration } = config;

            const { inputs, outputs, performance } = getObjectValue(configuredSpreadsheets);
            const { panelId, name } = getObjectValue(calibration);
            const calibrationName = panelId.slice(0, 5);

            const menuItems: Tab['menuItems'] = [
              <ApproachActionOption<string, never>
                actionText={EDIT_CALIBRATION_NAME_LABEL}
                argumentsList={[getStringValue(panelId)]}
                key={`${getSlugValue(EDIT_CALIBRATION_NAME_LABEL)}-${getStringValue(panelId)}`}
                onClick={showUpdateCalibrationNameDialog}
              />,
              <ApproachActionOption<string, never>
                actionText={DELETE_CALIBRATION_LABEL}
                argumentsList={[getStringValue(panelId)]}
                key={`${getSlugValue(DELETE_CALIBRATION_LABEL)}-${getStringValue(panelId)}`}
                onClick={showDeleteCalibrationDialog}
              />,
              <RefreshCalibrationOption
                calibration={calibration}
                approaches={approaches}
                closeApproachMenu={handleMenuClose}
                key={`refresh-gpc-${getStringValue(panelId)}`}
                setApproaches={setApproaches}
              />,
            ];

            const calibrationComponent = {
              id: calibrationName,
              label: name,
              content: (
                <Calibration
                  spreadsheets={{
                    calibration_inputs: inputs,
                    calibration_outputs: outputs,
                    calibration_performance: performance,
                  }}
                  workbook={workbook}
                  onChange={handleOnSpreadsheetChange}
                  approaches={approaches}
                  financials={financials}
                  company={companyInfo}
                  financialStatementsList={financialStatementsList}
                  calibration={calibration}
                />
              ),
              menuItems,
            } as Tab;

            return calibrationComponent;
          });

        setCalibrationTabs(calibrationTab);

        const valuationTabs = configurations
          .filter(filterApproaches)
          .sort(sortApproaches)
          .map((config, configIndex) => {
            const {
              spreadsheets: configuredSpreadsheets,
              approach,
              component,
              enterpriseValueReference: sheetName,
            } = config;

            const {
              approach_type: approachType,
              financial_statement: approachFinancialStatementId,
              name: approachName,
              panelId: approachPanelId,
              valuation_date: approachValuationDate,
            } = getObjectValue(approach);

            const approachTableName = getStringValue(getApproachTableName({ approach }));

            const spreadsheetsNames = getSpreadsheetsNames(configuredSpreadsheets);

            const approachSpreadsheets = getSpreadsheetsFromWorkbook({ spreadsheetsNames, workbook });

            const backsolveApproaches = getApproachesByType({
              approachType: VALUATIONS_BACKSOLVE_APPROACH,
            });

            const futureExitApproaches = getApproachesByType({
              approachType: VALUATIONS_FUTURE_EXIT_APPROACH,
            });

            // External Valuation
            const isExternalValuation = isApproachTypeOf({ approach, type: VALUATIONS_EXTERNAL_VALUATION_APPROACH });

            // Guideline Public Companies
            const isGuidelinePublicCompaniesApproach = isApproachTypeOf({
              approach,
              type: VALUATIONS_PUBLIC_COMPANIES_APPROACH,
            });

            const approachGPCId = getStringValue(approach?.valuations_approach_gpc?.id?.toString());

            const isGPCInMarketAdjustment = getIsGPCInApproach({
              approachGPCId,
              approachesToCheck: backsolveApproaches,
              approachType: 'backsolve',
            });

            const isGPCInFutureExit = getIsGPCInApproach({
              approachGPCId,
              approachesToCheck: futureExitApproaches,
              approachType: 'futureExit',
            });

            // Approaches Values - Equity and Enterprise Value
            const currentEnterpriseValue = Number(getNumberValue(enterpriseValues?.[getStringValue(sheetName)]));
            const totalDebt = Number(getNumberValue(financials?.total_debt));
            const totalCash = Number(getNumberValue(financials?.total_cash_equivalents));

            const isCapTableCurrencyApproach = VALUATIONS_CAP_TABLE_CURRENCY_APPROACHES.includes(
              getStringValue(approach?.approach_type)
            );

            const currentEquityValue = calculateEquityValue({
              companyExchangeRate: getNumberValue(companyExchangeRate),
              enterpriseValue: currentEnterpriseValue,
              isCapTableCurrencyApproach,
              totalCash,
              totalDebt,
            });

            const approachValuesFormat = isCapTableCurrencyApproach ? capTableRegularFormat : financialRegularFormat;

            const approachValuesExchangeRate = getStringValue(
              isCapTableCurrencyApproach ? companyExchangeRate : financialExchangeRate
            );

            // Approaches Values - Valuation Date
            const shouldDisplayValuationDate
              = VALUATIONS_APPROACH_TYPES_WITH_VALUATION_DATE_ENABLED.includes(getStringValue(approachType))
              && !isNil(approachValuationDate)
              && approachValuationDate !== measurementDate?.date?.toString();

            const Component = component ?? DefaultPanelComponent;

            // Approach Menu Items
            const menuItems: Tab['menuItems'] = [
              <ApproachActionOption<string, never>
                actionText={EDIT_APPROACH_NAME_LABEL}
                argumentsList={[getStringValue(approachPanelId)]}
                key={`${getSlugValue(EDIT_APPROACH_NAME_LABEL)}-${getStringValue(approachPanelId)}`}
                onClick={showUpdateApproachNameDialog}
              />,
              <ApproachActionOption<string, never>
                actionText={DELETE_APPROACH_LABEL}
                argumentsList={[getStringValue(approachPanelId)]}
                disabled={isGPCInMarketAdjustment || isGPCInFutureExit}
                key={`${getSlugValue(DELETE_APPROACH_LABEL)}-${getStringValue(approachPanelId)}`}
                tooltipMessage={VALUATIONS_CANNOT_DELETE_GPC}
                onClick={showDeleteApproachDialog}
                shouldDisplayTooltip={isGPCInMarketAdjustment || isGPCInFutureExit}>
                {isGPCInMarketAdjustment || isGPCInFutureExit ? <InfoIcon className={classes.gpcInUse} /> : <></>}
              </ApproachActionOption>,
              // Add Change Financial Statement option
              !isExternalValuation && financialVersionQuantity > 1 ? (
                <ApproachActionOption<string, never>
                  actionText={CHANGE_FINANCIAL_STATEMENT_LABEL}
                  argumentsList={[getStringValue(approachPanelId)]}
                  key={`${getSlugValue(CHANGE_FINANCIAL_STATEMENT_LABEL)}-${getStringValue(approachPanelId)}`}
                  onClick={showUpdateFinancialStatementDialog}
                />
              ) : null,
              // Add Refresh GPC Data option
              isGuidelinePublicCompaniesApproach ? (
                <RefreshGPCOption
                  approach={approach}
                  approaches={approaches}
                  closeApproachMenu={handleMenuClose}
                  key={`refresh-gpc-${getStringValue(approachPanelId)}`}
                  setApproaches={setApproaches}
                />
              ) : null,

              // Approach Values
              <MenuItem
                disabled
                className={classes.approachValuesContainer}
                key={`approach-values-${getStringValue(approachPanelId)}`}>
                {/* Valuation Date */}
                {shouldDisplayValuationDate && (
                  <ListItemText
                    className={classes.approachValues}
                    key={`valuation-date-${getStringValue(approachPanelId)}`}
                    primary={VALUATION_DATE_LABEL}
                    secondary={gridShortDate(approachValuationDate)}
                  />
                )}

                {/* Financial Statement */}
                <ListItemText
                  className={classes.approachValues}
                  key={`financial-statement-${getStringValue(approachPanelId)}`}
                  primary={FINANCIAL_STATEMENT_LABEL}
                  secondary={getFinancialStatementName(getNumberValue(approachFinancialStatementId))}
                />

                {/* Enterprise Value */}
                <ListItemText
                  className={classes.approachValues}
                  key={`enterprise-value-${getStringValue(approachPanelId)}`}
                  primary={ENTERPRISE_VALUE_LABEL}
                  secondary={returnCurrencyFormattedValueOrNull(
                    currentEnterpriseValue,
                    approachValuesFormat,
                    approachValuesExchangeRate,
                    isCapTableCurrencyApproach
                  )}
                />

                {/* Equity Value */}
                <ListItemText
                  className={classes.approachValues}
                  key={`equity-value-${getStringValue(approachPanelId)}`}
                  primary={EQUITY_VALUE_LABEL}
                  secondary={returnCurrencyFormattedValueOrNull(
                    currentEquityValue,
                    approachValuesFormat,
                    approachValuesExchangeRate,
                    isCapTableCurrencyApproach
                  )}
                />
              </MenuItem>,
            ];

            // Approach Tab
            const approachTab = {
              id: approachTableName,
              label: approachName,
              content: (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: Component is not typed
                <Component
                  approach={approach}
                  approaches={approaches}
                  capTableList={capTableVersions}
                  enterpriseValueMap={enterpriseValues}
                  financials={financials}
                  isDisabled={isCurrentValuationDisabled}
                  isSaving={isUpdatingValuation}
                  measurementDate={measurementDate}
                  name={sheetName}
                  onChange={handleOnSpreadsheetChange}
                  setEnterpriseValueMap={setEnterpriseValues}
                  spreadsheets={!isEmpty(approachSpreadsheets) ? approachSpreadsheets : configuredSpreadsheets}
                  workbook={workbook}
                />
              ),
              menuItems,
            } as Tab;

            // Redirect to the new Approach Tab
            redirectToNewApproach({ approach, approachTableName, configIndex });

            return approachTab;
          });

        setValuationApproachesTabs(valuationTabs);
      }, LOADING_TIMEOUT_DELAY);
    }

    return () => clearTimeout(createValuationTabsTimer);
  }, [
    approaches,
    capTableRegularFormat,
    capTableVersions,
    cells,
    classes,
    companyExchangeRate,
    companyInfo,
    configurations,
    enterpriseValues,
    financialExchangeRate,
    financialRegularFormat,
    financialStatementsList,
    financialVersionQuantity,
    financials,
    getApproachesByType,
    getFinancialStatementName,
    getIsGPCInApproach,
    getSpreadsheetsNames,
    handleOnSpreadsheetChange,
    isCurrentValuationDisabled,
    isUpdatingValuation,
    measurementDate,
    redirectToNewApproach,
    setCalibrationTabs,
    setEnterpriseValues,
    showDeleteApproachDialog,
    showDeleteCalibrationDialog,
    showUpdateApproachNameDialog,
    showUpdateCalibrationNameDialog,
    showUpdateFinancialStatementDialog,
    spreadsheets,
    valuation.calibrations,
    workbook,
  ]);

  if (!areSynchronized && !isValuationEmpty) {
    return <LoadingSection />;
  }

  if (isScalarOwnedDraftAndNotStaff) {
    return (
      <MessageBox
        action={<CreateNewVersionButton onClickHandler={createValuationsAllocationBlankVersion} />}
        fullWidth={false}
        title={SCALAR_OWNED_DRAFT_MESSAGE}
        titleStyleOverride={{ whiteSpace: 'pre-line' }}
      />
    );
  }

  // Display empty Valuations message if there are no Financial Periods
  if (!financialFiscalYearPeriods) {
    return (
      <EmptyTableMessage
        component={<RedirectToFinancialButton measurementDateSlug={getStringValue(measurementDate?.slug)} />}
        tableTerms={VALUATIONS_SPREADSHEET_TABLE_TERMS}
        tagline={VALUATIONS_MISSING_FINANCIAL_STATEMENT_TAGLINE}
        title={VALUATIONS_MISSING_FINANCIAL_STATEMENT_TITLE}
      />
    );
  }
  const filteredTabs = valuationApproachesTabs.filter(tab => tab.label !== undefined);

  return (
    <Grid className={`${VALUATIONS_SLUG}-container`}>
      <ValuationContext.Provider value={ValuationContextValues}>
        <WorkbookContext.Provider value={workbookContextValue}>
          {/* Render Valuation Approaches Tabs */}
          <TabsNavigation
            handleMenuClose={handleMenuClose}
            handleMenuOpen={handleMenuOpen}
            invalidTables={invalidTables}
            isDisabled={isCurrentValuationDisabled}
            menuAnchorEl={menuAnchorEl}
            mountOnActive
            tabs={[valuationSummaryTab, ...calibrationTabs, ...filteredTabs]}>
            <>
              {/* Display Decision Message */}
              {displayDecisionAlerts?.()}

              {/* Invalid Approaches Message */}
              {Boolean(invalidTables?.length) && <InvalidTablesMessage invalidTables={invalidTables} />}

              {/* Unlock Final Allocation Message */}
              {isCurrentAllocationFinal && isCurrentValuationDisabled && (
                <AllocationFinalMessage
                  setIsDisabled={setIsCurrentValuationDisabled}
                  singleAlert
                  tableTerms={{ tableName: VALUATIONS_SPREADSHEET_TABLE_TERMS.tableName }}
                />
              )}

              {/* Final Allocation Message */}
              {isCurrentAllocationFinal && !isCurrentValuationDisabled && (
                <Alert isAlertVisible>{VALUATIONS_FINAL_VALUATION_LOCKED_MESSAGE}</Alert>
              )}

              {/* Refresh GPC Data */}
              <RefreshGPCPrompt
                approaches={approaches}
                doRefresh={doRefresh}
                setApproaches={setApproaches}
                setDoRefresh={setDoRefresh}
              />
            </>
          </TabsNavigation>

          {/* New Valuations Allocation Version */}
          <NewVersionForm
            defaultValues={defaultValues}
            onClose={handleCloseNewVersionDialog}
            onFormChange={setVersionFormState}
            onSave={handleCreateNewVersion}
            open={openNewVersionDialog}
          />
        </WorkbookContext.Provider>

        {/* Add Scenario/Approach Dialog */}
        <AddScenarioApproachDialog
          disableAddApproachButton={isCurrentValuationDisabled || isLoadingValuationSummary}
          disableAddScenarioButton={areCapTableSecuritiesInvalid || isFundOwnershipEmpty}
          handleAddApproach={handleAddApproach}
          handleAddScenario={handleAddScenario}
          handleCloseAddApproachDialog={handleCloseAddApproachDialog}
          handleOpenAddApproachDialog={handleOpenAddApproachDialog}
          openAddApproachDialog={openAddApproachDialog}
          approaches={approaches}
        />

        {/* Update Approach Dialog */}
        <ApproachDialog
          approach={approachToUpdate}
          calibration={calibrationToUpdate}
          editMode={false}
          handleOnClose={handleCloseUpdateApproachDialog}
          handleOnSave={handleUpdateApproach}
          measurementDate={getStringValue(measurementDate?.date?.toString())}
          open={openUpdateApproachDialog}
          shouldDisplayGPCOptions={isUpdatedApproachTypeOf(VALUATIONS_PUBLIC_COMPANIES_APPROACH)}
          isEquityOptionAvailable={shouldDisplayEquityOption(approachToUpdate)}
          title={EDIT_APPROACH_NAME_LABEL}
        />
      </ValuationContext.Provider>
      {getDisconnectDialog?.()}
    </Grid>
  );
};

export default ValuationApproaches;

import { FinancialStatementPeriodFullWrite } from 'api';
import { LAST_TWELVE_MONTHS, LTM, NEXT_TWELVE_MONTHS, NTM } from 'common/constants/financials';
import { PeriodOption } from 'pages/Valuations/approaches/guidelinePublicCompanies/types';
import { getNumberValue, getStringValue, gridLocalShortDate } from 'utilities';

export const getPeriodsOptions = ({
  financialsPeriods,
  valuationDate,
  priceToTangibleOptions = false,
}: {
  financialsPeriods: Array<Partial<FinancialStatementPeriodFullWrite>>;
  valuationDate?: string;
  priceToTangibleOptions?: boolean;
}) => {
  const periodOptions: Array<PeriodOption> = [];

  const ltmOption: PeriodOption = {
    value: LAST_TWELVE_MONTHS,
    label: LAST_TWELVE_MONTHS,
  };
  const ntmOption: PeriodOption = {
    value: NEXT_TWELVE_MONTHS,
    label: NEXT_TWELVE_MONTHS,
  };
  // Traverse periods and if they're years, push to tmpOptions.
  // If they're LTM or NTM modify existing options and push at the end of function
  if (Array.isArray(financialsPeriods)) {
    financialsPeriods.forEach(period => {
      if (![LTM, NTM].includes(getNumberValue(period.period_type))) {
        periodOptions.push({
          value: getNumberValue(period.id),
          label: getStringValue(period.statement_date?.slice(0, 4)),
        });
      } else if (period.period_type === LTM) {
        ltmOption.value = getNumberValue(period.id);
        ltmOption.label = LAST_TWELVE_MONTHS;
      } else if (period.period_type === NTM) {
        ntmOption.value = getNumberValue(period.id);
        ntmOption.label = NEXT_TWELVE_MONTHS;
      }
    });
  }
  // ran across a scenario where years where not sorted, so just to reassure
  periodOptions.sort((a, b) => getNumberValue(a.label) - getNumberValue(b.label));

  if (priceToTangibleOptions) {
    periodOptions.push({
      value: ltmOption.value,
      label: `As of ${gridLocalShortDate(valuationDate)}`,
    });
  } else {
    periodOptions.push(ltmOption);
    periodOptions.push(ntmOption);
  }

  return periodOptions;
};

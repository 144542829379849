import React, { ReactNode } from 'react';
import { Button, Tooltip } from '@material-ui/core';

type CustomPrimaryButtonProps = {
  children: ReactNode;
  onClick: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
  tooltip?: string;
  className: string;
};

const CustomPrimaryButton = (props: CustomPrimaryButtonProps) => {
  const { children, onClick, variant = 'contained', disabled = false, tooltip = '', className } = props;
  const button = (
    <Button
      onClick={onClick}
      variant={variant}
      color="primary"
      disabled={disabled}
      style={{
        fontWeight: 600,
        fontSize: '1rem',
        padding: '1rem 2rem',
      }}
      className={className}>
      {children}
    </Button>
  );

  return tooltip !== '' ? (
    <Tooltip title={tooltip} placement="top">
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
};

export default CustomPrimaryButton;

/* eslint-disable no-param-reassign */
import type {
  FinancialStatementPeriodFullWrite,
  GPC,
  IncomeStatementAllow,
  MeasurementDateName,
  ValuationApproachGPC,
} from 'api';
import { ADJUSTED_EBITDA_ALIAS, EBITDA_ALIAS, FISCAL_YEAR } from 'common/constants/financials';
import {
  COMPANY,
  ENTERPRISE_VALUE_ID,
  EV_EXCL_OP_LEASES_ALIAS,
  FIRST_FORWARD_ID,
  FIRST_NON_FORWARD_ID,
  FORWARD_NET_INCOME,
  LTM_REVENUE,
  MARKET_CAP_ID,
  NET_INCOME_EXCLUDE_EXTRA_ITEMS,
  SECOND_FORWARD_ID,
  SECOND_NON_FORWARD_ID,
  STOCK_PRICE_ID,
  TANGIBLE_BOOK_VALUE,
  TANGIBLE_BOOK_VALUE_ESTIMATE,
  TICKER_SYMBOL_ID,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import addApproachDataInColumn from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/addApproachDataInColumn';
import type { GPCBaseColumn, GPCRowTransformer } from 'pages/Valuations/approaches/guidelinePublicCompanies/types';
import { FinancialsTransposed } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getYearFromDate } from 'utilities';
import { financialsKeyMap, titleToFspPropMap } from './auxMaps';
import { getTitleWithIcon } from './utilities';

const readCompanyFinancialsForEquityValueMode = ({
  financials,
  approach,
  financialsPeriods,
  columnId,
  measurementDate,
}: {
  financials: FinancialsTransposed;
  approach: ValuationApproachGPC;
  financialsPeriods: Partial<FinancialStatementPeriodFullWrite>[];
  columnId: string;
  measurementDate: { date: string } | MeasurementDateName | undefined;
}) => {
  const {
    first_period_selection,
    second_period_selection,
    first_period_forward_selection,
    second_period_forward_selection,
  } = approach;
  const currentFinancialsAvailablePeriodsIds = financialsPeriods.map(({ id }) => id);
  const shouldDefault = (periodSelection: null | number | undefined) =>
    periodSelection === null || !currentFinancialsAvailablePeriodsIds.includes(periodSelection);

  if (columnId === FIRST_NON_FORWARD_ID) {
    if (shouldDefault(first_period_selection)) {
      return financials.tangible_book_value;
    }
    const relatedPeriod = financialsPeriods.find(({ id }) => id === Number(approach.first_period_selection));
    return getNumberValue(relatedPeriod?.balance_sheet?.tangible_book_value);
  }
  if (columnId === SECOND_NON_FORWARD_ID) {
    if (shouldDefault(second_period_selection)) {
      return financials.ltm_net_income;
    }
    const relatedPeriod = financialsPeriods.find(({ id }) => id === Number(approach.second_period_selection));
    return getNumberValue(relatedPeriod?.income_statement?.net_income);
  }
  if (columnId === FIRST_FORWARD_ID) {
    if (shouldDefault(first_period_forward_selection)) {
      // get the tangible book value from the year after the valuation date year
      const nextYear = (getYearFromDate(measurementDate?.date) as number) + 1;
      const relatedPeriod = financialsPeriods
        .filter(({ period_type }) => period_type === FISCAL_YEAR)
        .find(({ statement_date }) => (statement_date as string).includes(nextYear.toString()));
      return getNumberValue(relatedPeriod?.balance_sheet?.tangible_book_value);
    }
    const relatedPeriod = financialsPeriods.find(({ id }) => id === Number(approach.first_period_forward_selection));
    return getNumberValue(relatedPeriod?.balance_sheet?.tangible_book_value);
  }

  // if columnId === SECOND_FORWARD_ID
  if (shouldDefault(second_period_forward_selection)) {
    return financials.ntm_net_income;
  }
  const relatedPeriod = financialsPeriods.find(({ id }) => id === Number(approach.second_period_forward_selection));
  return getNumberValue(relatedPeriod?.income_statement?.net_income);
};

type PeriodType = 'ltm' | 'ntm';

const periodTypeMap: Record<PeriodType, string> = {
  ltm: 'ltm_financial_statement_period_id',
  ntm: 'ntm_financial_statement_period_id',
};

const getIncomeStatementField = ({
  field,
  periodType,
  gpcApproach,
}: {
  field: string;
  periodType: PeriodType;
  gpcApproach: ValuationApproachGPC;
}): string => {
  if (!field.includes(EBITDA_ALIAS)) {
    return field;
  }
  const periods: Record<PeriodType, string> = {
    ltm: gpcApproach.use_adjusted_LTM_ebitda ? ADJUSTED_EBITDA_ALIAS : EBITDA_ALIAS,
    ntm: gpcApproach.use_adjusted_NTM_ebitda ? ADJUSTED_EBITDA_ALIAS : EBITDA_ALIAS,
  };
  return periods[periodType];
};

const getComparisonFields = (gpc_comparison: GPC, comparisonFields: Array<{ [key: string]: keyof GPC }>) =>
  comparisonFields.reduce((obj, comparisonField) => {
    obj[Object.keys(comparisonField)[0]] = gpc_comparison[Object.values(comparisonField)[0]];
    return obj;
  }, {});

const rowTransformer: GPCRowTransformer = params => {
  const { approach, financials, financialsPeriods, financialStatementId, measurementDate, sortedColumn } = params;
  const filteredFinancialPeriods = financialsPeriods.filter(
    ({ financial_statement }) => financial_statement === financialStatementId
  );
  const comparisons = approach?.gpc_comparison || [];
  const isEvaluatingEquityValue = approach?.is_evaluating_equity_value ?? false;
  const TABLE_COLUMNS: GPCBaseColumn[] = [
    {
      title: getTitleWithIcon('Ticker Symbol', TICKER_SYMBOL_ID, sortedColumn),
      id: 'ticker-symbol',
      parentColumn: FIRST_NON_FORWARD_ID,
      fields: [{ ticker_symbol: TICKER_SYMBOL_ID }],
      isSortableColumn: true,
      isStringColumn: true,
    },
    {
      title: getTitleWithIcon('Stock Price', STOCK_PRICE_ID, sortedColumn),
      id: STOCK_PRICE_ID,
      parentColumn: FIRST_NON_FORWARD_ID,
      fields: [{ stock_price: STOCK_PRICE_ID }],
      customFormat: {
        currency: {
          units: '',
        },
      },
      isSortableColumn: true,
    },
    {
      title: getTitleWithIcon('Market Cap', MARKET_CAP_ID, sortedColumn),
      id: MARKET_CAP_ID,
      parentColumn: FIRST_NON_FORWARD_ID,
      fields: [{ market_cap: MARKET_CAP_ID }],
      isSortableColumn: true,
    },
    {
      title: getTitleWithIcon('Enterprise Value', ENTERPRISE_VALUE_ID, sortedColumn),
      id: ENTERPRISE_VALUE_ID,
      parentColumn: FIRST_NON_FORWARD_ID,
      multiple_basis: approach.multiple_basis,
      fields: [{ enterprise_value: ENTERPRISE_VALUE_ID }, { ev_excl_operating_leases: EV_EXCL_OP_LEASES_ALIAS }],
      isSortableColumn: true,
    },
    {
      title: isEvaluatingEquityValue ? 'Price-to-Tangible Book' : 'Revenue',
      id: FIRST_NON_FORWARD_ID,
      periodType: 'ltm',
      isParent: true,
      fields: [
        { number: isEvaluatingEquityValue ? TANGIBLE_BOOK_VALUE : LTM_REVENUE },
        { enabled: 'first_metric_enabled' },
        { calendarYears: 'calendar_years_financials' },
      ],
    },
    {
      title: isEvaluatingEquityValue ? 'Price-to-Earnings' : 'EBITDA',
      id: SECOND_NON_FORWARD_ID,
      periodType: 'ltm',
      fields: [
        { number: isEvaluatingEquityValue ? NET_INCOME_EXCLUDE_EXTRA_ITEMS : 'ltm_ebitda' },
        { enabled: 'second_metric_enabled' },
        { calendarYears: 'calendar_years_financials' },
      ],
    },
    {
      title: isEvaluatingEquityValue ? 'Price-to-Tangible Book' : 'Revenue',
      id: FIRST_FORWARD_ID,
      periodType: 'ntm',
      fields: [
        { number: isEvaluatingEquityValue ? TANGIBLE_BOOK_VALUE_ESTIMATE : 'ntm_revenue' },
        { enabled: 'first_forward_metric_enabled' },
        { calendarYears: 'calendar_years_financials' },
      ],
    },
    {
      title: isEvaluatingEquityValue ? 'Price-to-Earnings' : 'EBITDA',
      id: SECOND_FORWARD_ID,
      periodType: 'ntm',
      fields: [
        { number: isEvaluatingEquityValue ? FORWARD_NET_INCOME : 'ntm_ebitda' },
        { enabled: 'second_forward_metric_enabled' },
        { calendarYears: 'calendar_years_financials' },
      ],
    },
  ];

  return TABLE_COLUMNS.map((tableColumn, index) => {
    const tmpTableColumn: any = {};
    comparisons.forEach(comparison => {
      tmpTableColumn[comparison.cap_iq_id as string] = getComparisonFields(comparison, tableColumn.fields);
      if (index > 3 && financials) {
        const financialsKey = financialsKeyMap[tableColumn.id][isEvaluatingEquityValue.toString()];
        tmpTableColumn[COMPANY] = financials[financialsKey];
        if (approach.ntm_financial_statement_period_id && !isEvaluatingEquityValue) {
          const { periodType, title } = tableColumn;
          const periodTypeAsPeriod = periodType as PeriodType;
          const titleAsString = title as string;
          const relatedPeriod = filteredFinancialPeriods.filter(
            ({ id }) => id === Number(approach[periodTypeMap[periodTypeAsPeriod] as keyof ValuationApproachGPC])
          )?.[0];
          if (relatedPeriod?.income_statement) {
            const selectedField = getIncomeStatementField({
              field: titleToFspPropMap[titleAsString],
              periodType: periodTypeAsPeriod,
              gpcApproach: approach,
            });
            tmpTableColumn[COMPANY] = relatedPeriod.income_statement[selectedField as keyof IncomeStatementAllow];
          }
        }
        if (isEvaluatingEquityValue) {
          tmpTableColumn[COMPANY] = readCompanyFinancialsForEquityValueMode({
            financials,
            approach,
            financialsPeriods,
            columnId: tableColumn.id,
            measurementDate,
          });
        }
      }
    });
    tableColumn = addApproachDataInColumn(tableColumn, approach);
    return { ...tableColumn, ...tmpTableColumn };
  });
};

export default rowTransformer;

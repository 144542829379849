import React, { ChangeEvent, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { sortBy, uniqBy } from 'lodash';
import { worldCurrencies } from 'utilities';

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

export type CurrencyListOption = {
  value: string;
  label: string;
};

type CurrencyListProps = {
  label: string;
  formCurrency?: string;
  handleCurrencyChange?: (event: ChangeEvent<unknown>, option: CurrencyListOption) => void;
};

const CurrencyList = (props: CurrencyListProps) => {
  const { label, formCurrency, handleCurrencyChange } = props;
  const classes = useStyles();
  const currencies = Object.keys(worldCurrencies).map(currency => ({ value: currency, label: currency }));

  const uniqueCurrencies = uniqBy(currencies, 'label');

  const selectedCurrency = useMemo(
    () => uniqueCurrencies.find(c => c.value === formCurrency),
    [uniqueCurrencies, formCurrency]
  );

  return (
    <Autocomplete
      id={label.toLowerCase().replaceAll(' ', '-')}
      options={sortBy(uniqueCurrencies, 'label')}
      style={{ width: '100%' }}
      autoHighlight
      getOptionLabel={option => option.label}
      onChange={handleCurrencyChange}
      value={selectedCurrency}
      renderInput={params => (
        <TextField {...params} fullWidth label={label} name={label.toLowerCase().replaceAll(' ', '-')} />
      )}
      className={classes.marginBottom}
      disableClearable={true}
    />
  );
};

export default CurrencyList;

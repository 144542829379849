import { useMemo } from 'react';
import {
  AllocationAttributes,
  BacksolveAttributes,
  CalibrationAttributes,
  DiscountedCashFlowAttributes,
  ExternalValuationAttributes,
  FutureExitAttributes,
  GuidelinePublicCompaniesAttributes,
  GuidelinePublicTransactionsAttributes,
  PublicCompaniesAttributes,
  SpecifiedShareValuesAttributes,
  ValuationApproachWeightAttributes,
} from 'pages/ValuationsAllocation/types';
import { useReadFieldAttributes } from 'services/hooks';
import {
  ALLOCATION_MODEL_NAME,
  ALLOCATION_SCENARIO_MODEL_NAME,
  ALLOCATIONS_PAGE,
  BACKSOLVE_APPROACH_MODEL_NAME,
  CALIBRATION_MODEL_NAME,
  CUSTOM_DATE_PERFORMANCE_METRIC_MODEL_NAME,
  DCF_APPROACH_MODEL_NAME,
  DCF_PERIOD_MODEL_NAME,
  EXTERNAL_VALUE_APPROACH_MODEL_NAME,
  FUTURE_EXIT_MODEL_NAME,
  GPC_APPROACH_MODEL_NAME,
  GPC_BACKSOLVE_RELATION_MODEL_NAME,
  GPC_COMPARISON_MODEL_NAME,
  GPT_APPROACH_MODEL_NAME,
  GPT_TRANSACTION_MODEL_NAME,
  METHODOLOGY_PRESENT_SHARE_VALUE_MODEL_NAME,
  PUBLIC_COMPANY_ADJUSTMENT_STATUS_MODEL_NAME,
  SSV_APPROACH_MODEL_NAME,
  VALUATION_APPROACH_WEIGHT_MODEL_NAME,
  VALUATIONS_PAGE,
} from 'services/hooks/useReadFieldAttributes/constants';

const useReadValuationAllocationAttributes = () => {
  const valuationFieldAttributes = useReadFieldAttributes(VALUATIONS_PAGE);
  const allocationFieldAttributes = useReadFieldAttributes(ALLOCATIONS_PAGE);

  const backsolveAttributes = useMemo(
    () =>
      ({
        backsolveApproachAttrs: valuationFieldAttributes.get(BACKSOLVE_APPROACH_MODEL_NAME),
        shareValueAttrs: valuationFieldAttributes.get(METHODOLOGY_PRESENT_SHARE_VALUE_MODEL_NAME),
        publicCompAdjustmentAttrs: valuationFieldAttributes.get(PUBLIC_COMPANY_ADJUSTMENT_STATUS_MODEL_NAME),
        gpcBacksolveRelationAttrs: valuationFieldAttributes.get(GPC_BACKSOLVE_RELATION_MODEL_NAME),
      } as BacksolveAttributes),
    [valuationFieldAttributes]
  );

  const discountedCashFlowAttributes = useMemo(
    () =>
      ({
        dcfApproachAttrs: valuationFieldAttributes.get(DCF_APPROACH_MODEL_NAME),
        dcfPeriodAttrs: valuationFieldAttributes.get(DCF_PERIOD_MODEL_NAME),
      } as DiscountedCashFlowAttributes),
    [valuationFieldAttributes]
  );

  const externalValuationAttributes = useMemo(
    () => valuationFieldAttributes.get(EXTERNAL_VALUE_APPROACH_MODEL_NAME) as ExternalValuationAttributes,
    [valuationFieldAttributes]
  );

  const futureExitAttributes = useMemo(
    () =>
      ({
        futureExitApproachAttrs: valuationFieldAttributes.get(FUTURE_EXIT_MODEL_NAME),
      } as FutureExitAttributes),
    [valuationFieldAttributes]
  );

  const guidelinePublicCompaniesAttributes = useMemo(
    () =>
      ({
        gpcApproachAttrs: valuationFieldAttributes.get(GPC_APPROACH_MODEL_NAME),
        comparisonAttrs: valuationFieldAttributes.get(GPC_COMPARISON_MODEL_NAME),
      } as GuidelinePublicCompaniesAttributes),
    [valuationFieldAttributes]
  );

  const calibrationAttributes = useMemo(
    () =>
      ({
        calibrationAttrs: valuationFieldAttributes.get(CALIBRATION_MODEL_NAME),
        customDatePerformanceMetricAttrs: valuationFieldAttributes.get(CUSTOM_DATE_PERFORMANCE_METRIC_MODEL_NAME),
      } as CalibrationAttributes),
    [valuationFieldAttributes]
  );

  const guidelinePublicTransactionsAttributes = useMemo(
    () =>
      ({
        gptApproachAttrs: valuationFieldAttributes.get(GPT_APPROACH_MODEL_NAME),
        transactionAttrs: valuationFieldAttributes.get(GPT_TRANSACTION_MODEL_NAME),
      } as GuidelinePublicTransactionsAttributes),
    [valuationFieldAttributes]
  );

  const specifiedShareValuesAttributes = useMemo(
    () => valuationFieldAttributes.get(SSV_APPROACH_MODEL_NAME) as SpecifiedShareValuesAttributes,
    [valuationFieldAttributes]
  );

  const publicCompaniesAttributes = useMemo(
    () => backsolveAttributes?.publicCompAdjustmentAttrs as PublicCompaniesAttributes,
    [backsolveAttributes]
  );

  const valuationApproachWeightAttributes = useMemo(
    () => valuationFieldAttributes.get(VALUATION_APPROACH_WEIGHT_MODEL_NAME) as ValuationApproachWeightAttributes,
    [valuationFieldAttributes]
  );

  const allocationAttributes = useMemo(
    () =>
      ({
        allocationScenarioAttrs: allocationFieldAttributes.get(ALLOCATION_SCENARIO_MODEL_NAME),
        equityAllocationAttrs: allocationFieldAttributes.get(ALLOCATION_MODEL_NAME),
        valuationApproachWeightAttrs: allocationFieldAttributes.get(VALUATION_APPROACH_WEIGHT_MODEL_NAME),
      } as AllocationAttributes),
    [allocationFieldAttributes]
  );

  return {
    allocationAttributes,
    backsolveAttributes,
    discountedCashFlowAttributes,
    externalValuationAttributes,
    futureExitAttributes,
    guidelinePublicCompaniesAttributes,
    calibrationAttributes,
    guidelinePublicTransactionsAttributes,
    publicCompaniesAttributes,
    specifiedShareValuesAttributes,
    valuationApproachWeightAttributes,
  };
};

export default useReadValuationAllocationAttributes;

import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { FORMS_TYPES } from 'common/types';
import { CustomTextField } from 'components';
import {
  ENTERPRISE_EQUITY_OPTIONS,
  SET_AS_CALIBRATION,
  USE_MULTIPLE_PREMIUM_DISCOUNT,
  VALUATIONS_GPC_COMPS_NEED_REFRESH,
} from 'pages/ValuationsAllocation/common/constants/valuations';
import { SelectFinancialsVersions } from 'pages/ValuationsAllocation/components/ApproachDialog/components/SelectFinancialsVersions';
import { ValuationsFormState } from 'pages/ValuationsAllocation/types';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { FormState, useFormValidation } from 'services/hooks/useFormValidation';
import { getObjectValue, getStringValue, gridShortDate } from 'utilities';
import useStyles from './styles';
import { ApproachFormProps } from './types';

const APPROACH_NAME_ID = 'name';
const APPROACH_NAME_LABEL = 'Approach Name';
const APPROACH_VALUATION_DATE_ID = 'valuation_date';
const APPROACH_MARKET_DATE_LABEL = 'Select Market Date';
const APPROACH_MARKET_DATE_PLACEHOLDER = 'Select Market Date';
const ENTERPRISE_EQUITY_ID = 'enterprise_equity_value';
const ENTERPRISE_EQUITY_LABEL = 'Enterprise Value or Equity Value';

const CALIBRATION_NAME_LABEL = 'Calibration Name';

const FORM_CONSTRAINTS = Object.freeze({
  name: {
    presence: {
      allowEmpty: false,
      message: 'is required',
    },
    length: {
      message: 'must be at least 2 characters',
      minimum: 2,
    },
  },
});

const ApproachForm: FC<ApproachFormProps> = props => {
  const { formState, onFormChange, shouldDisplayGPCOptions, isEquityOptionAvailable } = props;

  const [formValues, setFormValues] = useState<ValuationsFormState['values']>(formState.values);

  const { disableFinancialsSelection, isEditingApproachName, isChangingFinancialStatement, isEditingCalibrationName }
    = useContext(ValuationContext);

  const classes = useStyles();

  const { formValidated, validateForm } = useFormValidation(FORM_CONSTRAINTS);
  const formValidatedData = getObjectValue(formValidated);
  const errors = formValidatedData?.errors as FORMS_TYPES.FormFieldErrors;

  // Validate Form
  useEffect(() => {
    validateForm(formValues as FormState['values']);
  }, [formValues, validateForm]);

  // Update Form
  useEffect(() => {
    onFormChange?.(formValidated);
  }, [formValidated, onFormChange]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;

    setFormValues(prevState => ({ ...prevState, [name]: value }));
  };

  return (
    <Grid container>
      {isEditingApproachName && (
        <>
          <CustomTextField
            className={classes.field}
            dbErrors={null}
            formErrors={errors}
            fullWidth
            id={APPROACH_NAME_ID}
            label={APPROACH_NAME_LABEL}
            name={APPROACH_NAME_ID}
            onBlur={null}
            onChange={handleChange}
            onFocus={null}
            required
            type="text"
            value={getStringValue(formValues?.name)}
          />
          {shouldDisplayGPCOptions && (
            <>
              <KeyboardDatePicker
                className={classes.field}
                emptyLabel={APPROACH_MARKET_DATE_PLACEHOLDER}
                format="MM/dd/yyyy"
                fullWidth
                label={APPROACH_MARKET_DATE_LABEL}
                onChange={newDate =>
                  handleChange({
                    target: {
                      name: APPROACH_VALUATION_DATE_ID,
                      value: gridShortDate(newDate),
                    },
                  } as ChangeEvent<HTMLInputElement>)
                }
                required
                value={formValues?.valuation_date}
              />
              <FormGroup row style={{ fontSize: '0.5rem', width: '100%' }}>
                <FormControl className={classes.formControl}>
                  <Tooltip title={!isEquityOptionAvailable ? VALUATIONS_GPC_COMPS_NEED_REFRESH : ''}>
                    <span>
                      <Autocomplete
                        id="enterprise-equity-selector"
                        options={ENTERPRISE_EQUITY_OPTIONS}
                        style={{ width: '100%' }}
                        autoHighlight
                        getOptionLabel={option => option}
                        value={formValues?.enterprise_equity_value}
                        onChange={(event, value) => {
                          handleChange({
                            target: {
                              name: ENTERPRISE_EQUITY_ID,
                              value,
                            },
                          } as ChangeEvent<HTMLInputElement>);
                        }}
                        classes={{
                          root: classes.select,
                        }}
                        renderInput={params => (
                          <TextField {...params} label={ENTERPRISE_EQUITY_LABEL} disabled={!isEquityOptionAvailable} />
                        )}
                        disabled={!isEquityOptionAvailable}
                      />
                    </span>
                  </Tooltip>
                </FormControl>
              </FormGroup>
            </>
          )}
          <FormGroup row style={{ fontSize: '0.5rem' }}>
            <FormControlLabel
              control={
                <Checkbox
                  name="set-as-calibration"
                  color="primary"
                  checked={formValues?.is_calibration || false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    event.persist();
                    setFormValues(prevState => ({ ...prevState, is_calibration: event.target.checked }));
                  }}
                />
              }
              label={<Typography variant="body2">{SET_AS_CALIBRATION}</Typography>}
            />
          </FormGroup>
          {shouldDisplayGPCOptions && (
            <FormGroup row style={{ fontSize: '0.5rem', width: '100%' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="set-multiple-premium-discount"
                    color="primary"
                    checked={formValues?.use_multiple_premium_discount || false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.persist();
                      setFormValues(prevState => ({
                        ...prevState,
                        use_multiple_premium_discount: event.target.checked,
                      }));
                    }}
                  />
                }
                label={<Typography variant="body2">{USE_MULTIPLE_PREMIUM_DISCOUNT}</Typography>}
              />
            </FormGroup>
          )}
        </>
      )}
      {isEditingCalibrationName && (
        <CustomTextField
          className={classes.field}
          dbErrors={null}
          formErrors={errors}
          fullWidth
          id={APPROACH_NAME_ID}
          label={CALIBRATION_NAME_LABEL}
          name={APPROACH_NAME_ID}
          onBlur={null}
          onChange={handleChange}
          onFocus={null}
          required
          type="text"
          value={getStringValue(formValues?.name)}
        />
      )}

      {!disableFinancialsSelection && isChangingFinancialStatement && (
        <SelectFinancialsVersions onChange={handleChange} />
      )}
    </Grid>
  );
};

export default ApproachForm;

/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-unused-vars,max-classes-per-file
import { GPC } from 'api';
import { LAST_TWELVE_MONTHS, NEXT_TWELVE_MONTHS } from 'common/constants/financials';
import { GRID_NUMBER_CHECKBOX } from 'common/constants/gridType';
import { MULTIPLE_PREMIUM_DISCOUNT_ALIAS, SELECTION_A, SELECTION_B } from 'common/constants/valuations';
import { GridType } from 'common/types/scalarSpreadsheet';
import {
  mainColumnLetters,
  MULTIPLE_BASIS_ALIAS,
  SELECTED_DATABASE_VALUE,
  SELECTED_MULTIPLE,
  SELECTION,
  SPECIFIED_LABEL,
  VALUES_OPTIONS,
  WEIGHTED_EQUITY_VALUE,
  WEIGHTED_EV,
  WEIGHTING,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { percentileOptions } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/getRowConfig';
import { getFixedValue, trimUpdateFields } from 'pages/Valuations/util/util';
import { getStringValue, parseDatabaseValue } from 'utilities';
import { columnIdToComparisonProp, columnIdToPropertyMap, columnMetricToComparisonProp } from './auxMaps';
import {
  ColumnId,
  ColumnSelection,
  GpcApproachAttrs,
  GPCReverseParser,
  ReverseParsedValuationApproachGPC,
  ValuationApproachGPCCell,
} from '../../types';

const GPC_COMPARISON_FIELDS = [
  'ltm_revenue_growth',
  'ltm_ebitda_margin',
  'ltm_gross_margin',
  'fy_plus1_revenue',
  'fy_plus2_revenue',
  'ntm_gross_margin',
  'ntm_ebitda_margin',
  'fy_plus1_ebitda',
  'fy_plus2_ebitda',
  'current_portion_of_debt',
  'short_term_debt',
  'current_debt',
  'current_portion_of_leases',
  'long_term_debt',
  'non_current_debt',
  'capital_lease_liabilities',
  'effective_tax_rate',
  'cost_borrowing',
  'beta_5yr',
  'volume',
  'price_volume_historical_1yr',
  'price_volume_historical_2yr',
  'price_volume_historical_5yr',
  'close_price',
  'market_cap',
  'enterprise_value',
  'ltm_revenue',
  'ltm_ebitda',
  'ntm_revenue',
  'ntm_ebitda',
];

// this function will update the specificApproach's selection field with the value from the cell object
export function reverseSelectionCell(
  cell: ValuationApproachGPCCell,
  specificApproach: ReverseParsedValuationApproachGPC,
  fieldPrefix = ''
) {
  const fieldName: ColumnSelection = `${fieldPrefix || (cell.columnId as string)}_${SELECTION}` as ColumnSelection;
  if (cell.value === SPECIFIED_LABEL) {
    specificApproach[fieldName] = SELECTED_DATABASE_VALUE;
  } else if (VALUES_OPTIONS.includes(cell.value as string)) {
    specificApproach[fieldName] = cell.value as string;
  } else {
    const percentileOption = (percentileOptions ?? []).find(option => option.label === cell.value);
    if (percentileOption) {
      if (Number(specificApproach.percentile_selection_a) === percentileOption.value) {
        specificApproach[fieldName] = SELECTION_A;
      } else if (Number(specificApproach.percentile_selection_b) === percentileOption.value) {
        specificApproach[fieldName] = SELECTION_B;
      }
    }
  }
}

function reverseParse(
  cell: ValuationApproachGPCCell,
  comparisonMap: Record<string, GPC>,
  updated_gpc_approach: ReverseParsedValuationApproachGPC,
  gpcApproachAttrs: GpcApproachAttrs
) {
  const comparisonProp = columnIdToComparisonProp[cell.columnId as ColumnId];
  const metricComparisonProp = columnMetricToComparisonProp[cell.columnId as ColumnId];
  const { is_evaluating_equity_value: isEvaluatingEquityValue } = updated_gpc_approach;
  if (cell.gridType === (GRID_NUMBER_CHECKBOX as GridType)) {
    comparisonMap[cell.alias][`${metricComparisonProp}_enabled`] = cell.enabled;
    if (!isEvaluatingEquityValue) {
      comparisonMap[cell.alias][comparisonProp] = parseFloat(cell.financialPeriodValue as string).toFixed(2);
    }
  }
  const decimalPlaces = gpcApproachAttrs[cell.alias as keyof GpcApproachAttrs]?.decimal_places ?? 2;

  if (cell.alias === SELECTION) {
    reverseSelectionCell(cell, updated_gpc_approach);
  } else if (cell.alias === SELECTED_MULTIPLE) {
    // this one reverse parses the manual input for the multiple
    updated_gpc_approach[`${cell.columnId as ColumnId}_${SELECTED_MULTIPLE}`] = getFixedValue(
      cell.value,
      decimalPlaces
    );
    updated_gpc_approach[`${cell.columnId as ColumnId}_${SELECTED_MULTIPLE}`] = getFixedValue(
      cell.value,
      decimalPlaces
    );
  } else if (cell.alias === WEIGHTING) {
    updated_gpc_approach[`${cell.columnId as ColumnId}_${WEIGHTING}`] = (
      parseInt(cell.value as string, 10) / 100
    ).toString();
  } else if (cell.alias === WEIGHTED_EV) {
    const enterpriseValueDecimalPlaces = gpcApproachAttrs.weighted_enterprise_value?.decimal_places;
    const propToWriteTo = isEvaluatingEquityValue ? 'weighted_equity_value' : 'weighted_enterprise_value';
    updated_gpc_approach[propToWriteTo] = getFixedValue(cell.value, enterpriseValueDecimalPlaces);
  } else if (cell.alias === WEIGHTED_EQUITY_VALUE) {
    const equityValueDecimalPlaces = gpcApproachAttrs.weighted_equity_value?.decimal_places;
    const propToWriteTo = isEvaluatingEquityValue ? 'weighted_enterprise_value' : 'weighted_equity_value';
    updated_gpc_approach[propToWriteTo] = getFixedValue(cell.value, equityValueDecimalPlaces);
  } else if (cell.alias === MULTIPLE_BASIS_ALIAS) {
    updated_gpc_approach.multiple_basis = cell.value as string;
  } else if (cell.alias === MULTIPLE_PREMIUM_DISCOUNT_ALIAS) {
    if (updated_gpc_approach.use_multiple_premium_discount) {
      const property = columnIdToPropertyMap[cell.columnId as ColumnId];
      if (property) {
        const cellValue = parseDatabaseValue({
          type: 'string',
          value: cell.value,
          format: cell.format,
          allowEmptyValues: true,
          gridType: cell.gridType,
          decimalPlaces: gpcApproachAttrs[property as keyof GpcApproachAttrs]?.decimal_places,
        });
        updated_gpc_approach[property] = cellValue;
      }
    } else {
      // reset MPD values if main boolean is false
      Object.values(columnIdToPropertyMap).forEach(prop => {
        updated_gpc_approach[prop] = null;
      });
    }
  }
}

const reverseParser: GPCReverseParser = params => {
  const { cells, tableData, fieldAttributes } = params;
  const { approach } = tableData;
  const { comparisonAttrs, gpcApproachAttrs } = fieldAttributes;
  const { valuations_approach_gpc: gpcApproach } = approach;

  if (cells) {
    const comparisons = approach?.valuations_approach_gpc?.gpc_comparison || [];
    const comparisonMap: Record<string, GPC> = comparisons.reduce((map, comparison) => {
      // eslint-disable-next-line no-param-reassign
      map[getStringValue(comparison.cap_iq_id)] = {
        ...comparison,
        ...trimUpdateFields({
          comparison,
          fieldAttributes: comparisonAttrs,
          fieldList: GPC_COMPARISON_FIELDS,
        }),
      };
      // eslint-disable-next-line no-param-reassign
      map[getStringValue(comparison.cap_iq_id)].name = comparison.company_name;
      return map;
    }, {} as any);
    const isEvaluatingEquityValue = gpcApproach.is_evaluating_equity_value;
    const ltmFinancialPeriodId
      = cells.E1.value === LAST_TWELVE_MONTHS || isEvaluatingEquityValue ? null : cells.E1.value;
    const ntmFinancialPeriodId
      = cells.G1.value === NEXT_TWELVE_MONTHS || isEvaluatingEquityValue ? null : cells.G1.value;

    const updated_gpc_approach: ReverseParsedValuationApproachGPC = {
      ...gpcApproach,
      ltm_financial_statement_period_id: ltmFinancialPeriodId as null | number,
      ntm_financial_statement_period_id: ntmFinancialPeriodId as null | number,
      first_period_selection: isEvaluatingEquityValue ? Number(cells.E1.value) : null,
      second_period_selection: isEvaluatingEquityValue ? Number(cells.F1.value) : null,
      first_period_forward_selection: isEvaluatingEquityValue ? Number(cells.G1.value) : null,
      second_period_forward_selection: isEvaluatingEquityValue ? Number(cells.H1.value) : null,
      percentile_selection_a: Number(cells.TITLES_percentile_selection_a.value),
      percentile_selection_b: Number(cells.TITLES_percentile_selection_b.value),
      use_adjusted_LTM_ebitda: isEvaluatingEquityValue ? false : gpcApproach.use_adjusted_LTM_ebitda,
      use_adjusted_NTM_ebitda: isEvaluatingEquityValue ? false : gpcApproach.use_adjusted_NTM_ebitda,
    };

    Object.values(cells).forEach(cell => {
      if (mainColumnLetters.indexOf(cell.columnLegend as string) >= 0) {
        reverseParse(cell, comparisonMap, updated_gpc_approach, gpcApproachAttrs);
      }
    });
    reverseParse(cells.D2, comparisonMap, updated_gpc_approach, gpcApproachAttrs);
    updated_gpc_approach.gpc_comparison = Object.values(comparisonMap);
    tableData.approach.valuations_approach_gpc = updated_gpc_approach;
  }
};

export default reverseParser;

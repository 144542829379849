/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import QRCode from 'react-qr-code';
import validate from 'validate.js';
import {
  AUTH_BACKUP_CODE_ERROR_LENGTH_OR_TYPE,
  AUTH_BACKUP_CODE_MESSAGE,
  AUTH_CODE_ERROR_LENGTH_OR_TYPE,
  BACKUP_CODES_NOT_AVAILABLE,
  CONFIRMATION_BUTTON_BACK,
  CONFIRMATION_BUTTON_NEXT,
  ENTER_OTP_BACKUP_CODE_SUBTITLE,
  ENTER_OTP_BACKUP_CODE_TITLE,
  ENTER_OTP_SUBTITLE,
  ENTER_OTP_TITLE,
  ERROR_BUTTON_BACK,
  ERROR_BUTTON_NEXT,
  ERROR_INSIDE,
  HIDE_KEY,
  INVALID_OTP_ERROR,
  KEY_COPIED,
  MESSAGE_BOX_TITLE_ERROR,
  MESSAGE_BOX_TITLE_SUCCESS,
  MISSING_BACKUP_CODE,
  PLEASE_GENERATE_NEW_BACKUP_CODES,
  REMEMBER_OTP_LABEL,
  RESULT_ERROR,
  RESULT_SUCCESS,
  SCALAR_OTP_COOKIE,
  SETUP_2FA_STEP_1,
  SETUP_2FA_STEP_2,
  SETUP_2FA_STEP_3,
  SETUP_2FA_SUBTITLE,
  SETUP_2FA_TITLE,
  SHOW_KEY,
  SUCCESS_INSIDE,
} from 'common/constants/login';
import { ERROR_OCCURRED } from 'common/constants/messages/validations';
import { useStore } from 'common/store';
import { BackupCodes, MessageBox } from 'components';
import GenerateBackupCodesButton from 'pages/User2FA/GenerateBackupCodesButton';
import { AuthService } from 'services';
import { useResponse } from 'services/hooks';
import { useGetUserInfo, useLogOutUser } from 'services/hooks/auth';
import AuthenticationBackupCodesInput from './AuthenticationBackupCodesInput';
import AuthenticationInput from './AuthenticationInput';

const constraints = {
  otp: {
    length: { minimum: 6, message: AUTH_CODE_ERROR_LENGTH_OR_TYPE },
    format: {
      pattern: /^\d{6}/,
      message: AUTH_CODE_ERROR_LENGTH_OR_TYPE,
    },
  },
};

const constraintsBkCode = {
  backupCode: {
    length: { minimum: 8, message: AUTH_BACKUP_CODE_ERROR_LENGTH_OR_TYPE, maximum: 8 },
    format: {
      pattern: /^[a-zA-Z2-9]{8}/,
      message: AUTH_BACKUP_CODE_ERROR_LENGTH_OR_TYPE,
    },
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 600,
    maxHeight: '100vh',
    boxSizing: 'border-box',
    padding: theme.spacing(3),

    '& h2': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    '& .scar-textField': {
      marginBottom: theme.spacing(2),
    },
  },
  subtitle: {
    overflow: 'hidden',
    overflowY: 'auto',
    marginTop: `${theme.spacing(1)}px`,
    marginBottom: `${theme.spacing(3)}px`,
  },
  actionButtons: {
    marginTop: theme.spacing(2),
  },
  cancelBtn: {
    background: theme.palette.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#f7f7f7',
      boxShadow: 'none',
    },
  },
  notWorkingBtn: {
    background: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#f7f7f7',
      boxShadow: 'none',
    },
  },
  checkboxLabel: {
    color: theme.palette.gray[300],
  },
  steps: {
    marginBottom: theme.spacing(1),
  },
  errorBtn: {
    backgroundColor: theme.palette.pink,
    color: theme.palette.white,
  },
  nextBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
  },
  modalSubtitle: {
    margin: '1rem 0',
  },
  showHideOtpKeyBtn: {
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  keyCopiedMsg: {
    marginLeft: theme.spacing(1),
  },
}));

const OTP = ({ userHas2FA, verifyOtp, setDeviceData, showActionButtons = true, setDisplayReauthenticate }) => {
  const [{ user }] = useStore();
  const logOutUser = useLogOutUser();
  const getUserInfo = useGetUserInfo();

  const { processErrorResponse } = useResponse();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    errors: {},
  });

  const defaultMessageBoxState = {
    show: false,
    title: MESSAGE_BOX_TITLE_SUCCESS,
    result: RESULT_SUCCESS,
    backBtnText: CONFIRMATION_BUTTON_BACK,
    nextBtnText: CONFIRMATION_BUTTON_NEXT,
    type: SUCCESS_INSIDE,
    success: true,
    actionBack: () => logOutUser(),
    actionNext: () => verifyOtp(true),
  };

  const [otpData, setOtpData] = useState();
  const [otpSecretKey, setOtpSecretKey] = useState();
  const [showOtpKey, setShowOtpKey] = useState();
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [rememberOtp, setRememberOtp] = useState(false);
  const [messageBoxData, setMessageBoxData] = useState(defaultMessageBoxState);
  const [isCodeWorking, setIsCodeWorking] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [backupCodes, setBackupCodes] = useState([]);
  const [isGeneratingBackupCodes, setIsGeneratingBackupCodes] = useState(false);

  const classes = useStyles();

  const handleInputChange = event => {
    event.persist();
    setFormState(formValues => ({
      ...formValues,
      values: {
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleCheckboxChange = event => {
    event.persist();
    setRememberOtp(event.target.checked);
  };

  const verifyBackupCode = useCallback(async () => {
    const { backupCode } = formState.values;
    if (!backupCode) {
      setFormState(prevFormState => ({
        ...prevFormState,
        errors: {
          backupCode: MISSING_BACKUP_CODE,
        },
      }));
      return;
    }
    const authService = AuthService.getInstance();
    try {
      const response = await authService.verifyBackupCode(backupCode);
      if (response) {
        verifyOtp(true);
        localStorage.setItem('isOtpVerified', true);
      }
    } catch (error) {
      const errorMsg = JSON.parse(JSON.stringify(error.response?.body?.token || ERROR_OCCURRED));
      setFormState(prevFormState => ({
        ...prevFormState,
        errors: {
          backupCode: [errorMsg],
        },
      }));
    }
  }, [formState.values, verifyOtp]);

  const refreshBackupTokens = async () => {
    setIsGeneratingBackupCodes(true);

    const authService = AuthService.getInstance();

    try {
      const response = await authService.createBackupCodes({
        user_has_totp_device: true,
        confirmed: true,
      });

      if (response) {
        getUserInfo();
        setBackupCodes(response.tokens);
        setOpenModal(true);
      }
    } catch (error) {
      // If we recieve a Forbidden error we need to reauthenticate the user
      if (error?.status === 403 && setDisplayReauthenticate) setDisplayReauthenticate(true);
    }

    setIsGeneratingBackupCodes(false);
  };

  const validateOtp = async ({ showMessageBox }) => {
    const authService = AuthService.getInstance();

    try {
      const response = await authService.verifyOTP(formState.values.otp);

      if (response?.verified) {
        setMessageBoxData({
          ...defaultMessageBoxState,
          show: showMessageBox,
        });

        // Only needed when OTP is rendered through the 2FA page
        if (setDeviceData) {
          setDeviceData(prevDeviceData => ({
            ...prevDeviceData,
            confirmed: true,
          }));

          // Generate new backup codes after enable 2FA
          await refreshBackupTokens();
        }

        if (!showMessageBox) {
          verifyOtp(true);
          localStorage.setItem('isOtpVerified', true);
        }

        try {
          const sanitizedUserId = Number.parseInt(user?.id, 10);
          if (Number.isNaN(sanitizedUserId)) {
            throw new Error('Invalid user ID');
          }
          if (rememberOtp && sanitizedUserId) {
            // Remember cookie for 30 days
            document.cookie = `${SCALAR_OTP_COOKIE}_${sanitizedUserId}=true;\
            SameSite=Strict;\
            path=/; expires=${new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30).toUTCString()}`;
          }
        } catch (error) {
          processErrorResponse({
            error,
            defaultErrorMessage: error.message,
          });
        }
      } else {
        setFormState(prevFormState => ({
          ...prevFormState,
          errors: {
            otp: [INVALID_OTP_ERROR],
          },
        }));
      }
    } catch (error) {
      const errorMsg = JSON.parse(JSON.stringify(error.response?.body?.detail || ERROR_OCCURRED));
      setFormState(prevFormState => ({
        ...prevFormState,
        errors: {
          otp: [errorMsg],
        },
      }));
    }
  };

  const cookieToObject = cookie => {
    const cookieObj = {};
    cookie.split('; ').forEach(c => {
      const [key, value] = c.split('=');
      // The cookie value is stored as a string, so we need to convert it to a boolean
      cookieObj[key.trim()] = value === 'true' ? !!value : value;
    });
    return cookieObj;
  };

  const getOtpUriParams = uri => {
    const params = {};
    const paramStrings = uri.split('?')[1].split('&');
    paramStrings.forEach(param => {
      const pairArr = param.split('=');
      const pairKey = pairArr[0].toLowerCase();
      const pairVal = pairArr[1];
      params[pairKey] = pairVal;
    });
    return params;
  };

  useEffect(() => {
    const generateOrReturnUserTOTP = async () => {
      try {
        const authService = AuthService.getInstance();
        const response = await authService.createTOTP();
        setOtpData(response);
        const otpUriParams = getOtpUriParams(response.url);
        setOtpSecretKey(otpUriParams?.secret || '');
      } catch (error) {
        throw new Error(error);
      }
    };

    if (!userHas2FA) {
      generateOrReturnUserTOTP();
    }

    return () => {
      setOtpData();
      setOtpSecretKey();
    };
  }, [userHas2FA]);

  useEffect(() => {
    if (otpData) {
      const { persistent_id } = otpData;
      if (setDeviceData) {
        setDeviceData(prevDeviceData => ({
          ...prevDeviceData,
          persistent_id,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpData]);

  useEffect(() => {
    const errors = validate(formState.values, !isCodeWorking ? constraints : constraintsBkCode);
    setFormState(formCurrentState => ({ ...formCurrentState, isValid: !errors, errors: errors || {} }));
  }, [formState.values, isCodeWorking]);

  const otpCookie = useMemo(() => {
    if (document.cookie && user?.id) {
      const scalarCookie
        = document.cookie.split('; ').find(row => row.startsWith(`${SCALAR_OTP_COOKIE}_${user.id}`)) || '';
      return cookieToObject(scalarCookie);
    }
    return { scalar_otp: false };
  }, [user]);

  useEffect(() => {
    // Skip the OTP check (bypass 2FA and go straight to the firm selector)
    // if the cookie is present for the current userand verifyOTP is available
    if (user?.id && otpCookie[`${SCALAR_OTP_COOKIE}_${user.id}`] && verifyOtp) {
      verifyOtp(true);
    }
  }, [otpCookie, verifyOtp, user]);

  const getOtpTitles = ({ useBackupCode }) => {
    if (useBackupCode) {
      return {
        title: ENTER_OTP_BACKUP_CODE_TITLE,
        subtitle: ENTER_OTP_BACKUP_CODE_SUBTITLE,
      };
    }

    return {
      title: ENTER_OTP_TITLE,
      subtitle: ENTER_OTP_SUBTITLE,
    };
  };

  const handleClose = (_, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }

    setOpenModal(false);
  };

  useEffect(() => {
    if (copiedToClipboard) {
      const timer = setTimeout(() => {
        setCopiedToClipboard(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [copiedToClipboard]);

  const showOrHideOtpKey = () => {
    setShowOtpKey(prevState => !prevState);
  };

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(otpSecretKey);
      setCopiedToClipboard(true);
    } catch (error) {
      throw new Error(error);
    }
  };

  if (userHas2FA && !user?.has_tokens_2fa) {
    return (
      <Paper className={classes.root}>
        <Typography variant="h3">{BACKUP_CODES_NOT_AVAILABLE}</Typography>
        <Typography variant="body1" className={classes.subtitle}>
          {PLEASE_GENERATE_NEW_BACKUP_CODES}
        </Typography>
        <Grid container justifyContent="center" spacing={2} classes={{ root: classes.actionButtons }}>
          <Grid item>
            <GenerateBackupCodesButton className="" generateNewBackupCodes={refreshBackupTokens} disabled={false} />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  if (userHas2FA) {
    return (
      <>
        <BackupCodes
          backupCodes={backupCodes}
          handleClose={handleClose}
          handleGenerateNewCodes={refreshBackupTokens}
          isGeneratingBackupCodes={isGeneratingBackupCodes}
          isModal
          openModal={openModal}
          username={user?.email}
        />

        <Paper className={classes.root}>
          <Typography variant="h3">{getOtpTitles({ useBackupCode: isCodeWorking }).title}</Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {getOtpTitles({ useBackupCode: isCodeWorking }).subtitle}
          </Typography>
          {!isCodeWorking ? (
            <AuthenticationInput formState={formState} handleChange={handleInputChange} />
          ) : (
            <>
              <AuthenticationBackupCodesInput formState={formState} handleChange={handleInputChange} />
              <Typography className={classes.checkboxLabel}>{AUTH_BACKUP_CODE_MESSAGE}</Typography>
            </>
          )}
          {!isCodeWorking && (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={rememberOtp} onChange={handleCheckboxChange} />}
                label={REMEMBER_OTP_LABEL}
                classes={{ label: classes.checkboxLabel }}
              />
            </FormGroup>
          )}
          <Grid container justifyContent="flex-end" spacing={2} classes={{ root: classes.actionButtons }}>
            <Grid item>
              <Button
                className={classes.cancelBtn}
                variant="contained"
                color="primary"
                onClick={logOutUser}
                disabled={false}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => (!isCodeWorking ? validateOtp({ showMessageBox: false }) : verifyBackupCode())}
                disabled={isEmpty(formState.values) || !formState.isValid}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Paper>
        {!isCodeWorking && (
          <Button
            className={classes.notWorkingBtn}
            variant="contained"
            color="primary"
            onClick={() => setIsCodeWorking(true)}
            disabled={false}>
            CODE NOT WORKING?
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      {otpData && !messageBoxData.show && (
        <Paper className={classes.root}>
          <Typography variant="h3">{SETUP_2FA_TITLE}</Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {SETUP_2FA_SUBTITLE}
          </Typography>
          <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item xs={4}>
              <QRCode value={otpData.url} size={150} />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body1">{SETUP_2FA_STEP_1}</Typography>
              <Typography variant="caption" className={classes.showHideOtpKeyBtn} onClick={showOrHideOtpKey}>
                {!showOtpKey ? SHOW_KEY : HIDE_KEY}
              </Typography>
              {showOtpKey && (
                <Typography
                  variant="caption"
                  className={classes.steps}
                  disabled={copiedToClipboard}
                  onClick={handleCopyToClipboard}>
                  {otpSecretKey}
                </Typography>
              )}
              {copiedToClipboard && (
                <Typography variant="caption" className={classes.keyCopiedMsg}>
                  {KEY_COPIED}
                </Typography>
              )}
              <Typography variant="body1" className={classes.steps}>
                {SETUP_2FA_STEP_2}
              </Typography>
              <Typography variant="body1" className={classes.steps}>
                {SETUP_2FA_STEP_3}
              </Typography>
            </Grid>
          </Grid>
          <AuthenticationInput formState={formState} handleChange={handleInputChange} />
          <Grid container justifyContent="flex-end" spacing={2} classes={{ root: classes.actionButtons }}>
            <Grid item>
              <Button
                id="cancel-btn"
                className={classes.cancelBtn}
                variant="contained"
                color="primary"
                onClick={() => {
                  setMessageBoxData({
                    show: true,
                    title: MESSAGE_BOX_TITLE_ERROR,
                    result: RESULT_ERROR,
                    backBtnText: ERROR_BUTTON_BACK,
                    nextBtnText: ERROR_BUTTON_NEXT,
                    type: ERROR_INSIDE,
                    success: false,
                    actionBack: () => verifyOtp(true),
                    actionNext: () => {
                      setMessageBoxData({
                        ...defaultMessageBoxState,
                        show: false,
                      });
                    },
                  });
                }}
                disabled={false}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="enable-btn"
                variant="contained"
                color="secondary"
                onClick={() => validateOtp({ showMessageBox: true })}
                disabled={isEmpty(formState.values) || !formState.isValid}>
                Enable
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
      {messageBoxData.show && (
        <Paper className={classes.root}>
          <Typography variant="h3">{messageBoxData.title}</Typography>
          <MessageBox
            title=""
            tagline={`Two-Factor Authentication ${messageBoxData.result}`}
            hasFrame={false}
            hasBgColor
            fullWidth={false}
            type={messageBoxData.type}
          />
          <Grid container justifyContent="flex-end" spacing={2} classes={{ root: classes.actionButtons }}>
            {showActionButtons && (
              <>
                <Grid item>
                  <Button
                    id="custom-text-back-btn"
                    className={classes.cancelBtn}
                    variant="contained"
                    color="primary"
                    onClick={messageBoxData.actionBack}
                    disabled={false}>
                    {messageBoxData.backBtnText}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="custom-text-next-btn"
                    variant="contained"
                    className={messageBoxData.success ? classes.nextBtn : classes.errorBtn}
                    onClick={messageBoxData.actionNext}
                    disabled={false}>
                    {messageBoxData.nextBtnText}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      )}
    </>
  );
};

OTP.propTypes = {
  userHas2FA: PropTypes.bool.isRequired,
  verifyOtp: PropTypes.func,
  setDeviceData: PropTypes.func,
  showActionButtons: PropTypes.bool,
  setDisplayReauthenticate: PropTypes.func,
};

export default OTP;

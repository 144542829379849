import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ErrorOutlineOutlined as ErrorOutlineOutlinedIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { SAVE_TO_ADD_REFERENCE, THE_DOCUMENT } from 'common/constants/documents';
import { Alert } from 'components';

const useStyles = makeStyles(() => ({
  fileName: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
}));

const PendingReference = ({ id, fileType, filename }) => {
  const classes = useStyles();
  return (
    <Alert key={id} severity="warning" isAlertVisible icon={<ErrorOutlineOutlinedIcon />}>
      {THE_DOCUMENT}
      <span className={classes.fileName}>
        {filename}.{fileType}
      </span>
      {SAVE_TO_ADD_REFERENCE}
    </Alert>
  );
};

PendingReference.propTypes = {
  id: PropTypes.number,
  fileType: PropTypes.string,
  filename: PropTypes.string,
};

export default PendingReference;

import { isNil } from 'lodash';
import * as validationMessages from 'common/constants/messages/validations';
import { SELECTION } from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { LAST_MIDDLE_COLUMN_INDEX } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/constants';

const validations = ({ cell, addFeedback, removeFeedback }) => {
  if (cell.alias === SELECTION && cell.y > LAST_MIDDLE_COLUMN_INDEX) {
    // if cell value is '0' then addFeedback(validationMessages.IS_REQUIRED_ERROR);

    if (isNil(cell.value)) {
      addFeedback(validationMessages.IS_REQUIRED_ERROR);
    } else {
      removeFeedback(validationMessages.IS_REQUIRED_ERROR);
    }
  }
};

export default validations;

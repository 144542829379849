import { PERFORMANCE_METRICS_SPREADSHEET_TITLE } from 'pages/Valuations/approaches/guidelinePublicCompanies/PerformanceMetrics/common/constants/performanceMetrics/performanceMetrics';

export const PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_TITLE = 'LTM REVENUE';
export const PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_GROWTH_TITLE = 'LTM REVENUE GROWTH';
export const PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_TITLE = 'NTM REVENUE';
export const PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_GROWTH_TITLE = 'NTM REVENUE GROWTH';
export const PERFORMANCE_METRICS_SPREADSHEET_LTM_EBITDA_TITLE = 'LTM EBITDA';
export const PERFORMANCE_METRICS_SPREADSHEET_NTM_EBITDA_TITLE = 'NTM EBITDA';
export const PERFORMANCE_METRICS_SPREADSHEET_GROSS_MARGIN_TITLE = 'GROSS MARGIN';
export const PERFORMANCE_METRICS_SPREADSHEET_EBITDA_MARGIN_TITLE = 'EBITDA MARGIN';
export const PERFORMANCE_METRICS_SPREADSHEET_TANGIBLE_BOOK_TITLE = 'TANGIBLE BOOK VALUE';
export const PERFORMANCE_METRICS_SPREADSHEET_NET_INCOME_TITLE = 'NET INCOME';

export const PERFORMANCE_METRICS_SPREADSHEET_RANK_TITLE = 'RANK';
export const PERFORMANCE_METRICS_SPREADSHEET_RANK_REVENUE_SIZE_TITLE = 'REVENUE SIZE';
export const PERFORMANCE_METRICS_SPREADSHEET_RANK_LTM_REVENUE_GROWTH_TITLE = 'LTM REVENUE GROWTH';
export const PERFORMANCE_METRICS_SPREADSHEET_RANK_NTM_REVENUE_GROWTH_TITLE = 'NTM REVENUE GROWTH';
export const PERFORMANCE_METRICS_SPREADSHEET_RANK_PROFITABILITY_TITLE = 'PROFITABILITY';

export const PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_TITLE = 'VOLATILITY';
export const PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_1_YEAR_TITLE = '1 YEAR';
export const PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_2_YEARS_TITLE = '2 YEARS';
export const PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_5_YEARS_TITLE = '5 YEARS';

export const PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_COLUMNS = Object.freeze({
  VOLATILITY_1_YEAR: 11,
  VOLATILITY_2_YEARS: 12,
  VOLATILITY_5_YEARS: 13,
});

export const PERFORMANCE_METRICS_SPREADSHEET_COLUMNS = Object.freeze({
  TITLE: 0,
  LTM_REVENUE: 1,
  LTM_REVENUE_GROWTH: 2,
  NTM_REVENUE: 3,
  NTM_REVENUE_GROWTH: 4,
  LTM_EBITDA: 5,
  NTM_EBITDA: 6,
  GROSS_MARGIN: 7,
  EBITDA_MARGIN: 8,
  TANGIBLE_BOOK: 9,
  NET_INCOME: 10,
  ...PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_COLUMNS,
});

export const PERFORMANCE_METRICS_SPREADSHEET_HEADERS = Object.freeze([
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_GROWTH_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_GROWTH_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_LTM_EBITDA_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_NTM_EBITDA_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_GROSS_MARGIN_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_EBITDA_MARGIN_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_TANGIBLE_BOOK_TITLE,
    subtitle: '',
  },
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_NET_INCOME_TITLE,
    subtitle: '',
  },
  // Volatility
  {
    value: PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_TITLE,
    subtitle: PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_1_YEAR_TITLE,
  },
  {
    value: '',
    subtitle: PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_2_YEARS_TITLE,
  },
  {
    value: '',
    subtitle: PERFORMANCE_METRICS_SPREADSHEET_VOLATILITY_5_YEARS_TITLE,
  },
]);

import { LTM, LTM_EBITDA_ALIAS as LTM_EBITDA, NTM, NTM_EBITDA_ALIAS as NTM_EBITDA } from 'common/constants/financials';
import { OptionsForEBITDA } from 'pages/Valuations/approaches/guidelinePublicCompanies/types';
import { FinancialPeriod } from './types';

const getLtmNtmEBITDAs = (financialsPeriods: FinancialPeriod[], isMultipleColumn = false) => {
  let tmpOptions: OptionsForEBITDA = {};

  if (Array.isArray(financialsPeriods)) {
    financialsPeriods.forEach(period => {
      if (period.period_type === LTM) {
        const {
          income_statement: { ebitda, adjusted_ebitda: adjustedEbitda },
        } = period;
        const key = isMultipleColumn ? `${LTM_EBITDA}_multiple` : LTM_EBITDA;
        tmpOptions = {
          [key]: [
            {
              value: ebitda ?? 0,
              label: `EBITDA: ${ebitda}`,
            },
            {
              value: adjustedEbitda ?? 0,
              label: `Adj EBITDA: ${adjustedEbitda}`,
            },
          ],
        };
      }
      if (period.period_type === NTM) {
        const {
          income_statement: { ebitda, adjusted_ebitda: adjustedEbitda },
        } = period;
        const key = isMultipleColumn ? `${NTM_EBITDA}_multiple` : NTM_EBITDA;
        tmpOptions = {
          ...tmpOptions,
          [key]: [
            {
              value: ebitda ?? 0,
              label: `EBITDA: ${ebitda}`,
            },
            {
              value: adjustedEbitda ?? 0,
              label: `Adj EBITDA: ${adjustedEbitda}`,
            },
          ],
        };
      }
    });
  }
  return tmpOptions;
};

export default getLtmNtmEBITDAs;

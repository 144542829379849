import { ValuationsApproach } from 'common/types/valuation';
import { getApproachTableName } from 'pages/ValuationsAllocation/util';
import createGpcData from './gpc/createGpcData';
import GPC from './GuidelinePublicCompanies';
import { createPerformanceMetrics } from './PerformanceMetrics';
import { CreateGpcConfigParams } from './types';

const createGpcConfig = (params: CreateGpcConfigParams) => {
  const {
    approach,
    companyName,
    compGroups,
    customClasses,
    financials,
    financialsPeriods,
    gpcAttributes,
    isDisabled,
    measurementDate,
  } = params;
  const name = getApproachTableName({ approach: approach as ValuationsApproach });

  const gpcSheet = createGpcData({
    approach,
    companyName,
    compGroups,
    financials,
    financialsPeriods,
    gpcAttributes,
    name,
    isDisabled,
    measurementDate,
  });

  const { spreadsheet: performanceMetricsSheet } = createPerformanceMetrics({
    approach,
    companyName,
    customClasses,
    financials,
    isDisabled,
  });

  return {
    approach,
    name,
    component: GPC,
    enterpriseValueReference: name,
    spreadsheets: {
      gpc: gpcSheet,
      performanceMetrics: performanceMetricsSheet,
    },
    value: approach?.enterprise_value,
  };
};

export default createGpcConfig;

/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { PREVIOUS_LABEL, RECENT_LABEL } from 'common/constants/documents';
import { GridSkeleton } from 'components/Grid';
import useDocumentsContext from 'context/DocumentsContext';
import CurrentDocuments from './components/CurrentDocuments';
import RecentAndPrevAccordions from './components/RecentAndPrevAccordions';
import SearchInDocumentsPage from './components/SearchInDocumentsPage';
import { processDocumentsInformation } from './utilities/processDocuments';

const Documents = ({ selectedMeasurementDate, measurementDates, companyDocuments, currentDocumentsProcessed }) => {
  const [selectedValue, setSelectedValue] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [previousMeasurementDates, setPreviousMeasurementDates] = useState();
  const [recentMeasurementDates, setRecentMeasurementDates] = useState();
  const { currentDocuments, setCurrentDocuments } = useDocumentsContext();
  const [expandedStates, setExpandedStates] = useState({ currentDocuments: true });

  const allDocuments = companyDocuments?.map(md => md.files).flat();

  const currentDocumentsFilenames = currentDocuments?.files.map(({ file }) => file.filename);

  useEffect(() => {
    const foundMeasurementDate = measurementDates?.find(md => md.id === selectedMeasurementDate);
    if (companyDocuments) {
      const foundCurrentDocuments = companyDocuments?.find(d => d.measurement_date.id === selectedMeasurementDate);
      setCurrentDocuments(foundCurrentDocuments);
    }
    setCurrentDate(foundMeasurementDate);
  }, [selectedMeasurementDate, measurementDates, companyDocuments]);

  useEffect(() => {
    const datesOlderThanSelected = measurementDates?.filter(md => md.date < currentDate?.date);
    const datesNewerThanSelected = measurementDates?.filter(md => md.date > currentDate?.date);

    setPreviousMeasurementDates(datesOlderThanSelected);
    setRecentMeasurementDates(datesNewerThanSelected);
  }, [currentDate]);

  const getDocumentsForQueriedDates = dates =>
    dates.map(({ id }) => companyDocuments.find(({ measurement_date }) => measurement_date.id === id) || {});

  const allObjectsEmpty = items => items.every(obj => isEmpty(obj));

  const getDocumentsFilenames = documents => {
    if (isEmpty(documents) || allObjectsEmpty(documents)) {
      return [];
    }
    return documents.reduce(
      (newArray, docs) =>
        docs?.files?.length ? [...newArray, ...docs.files.map(({ file }) => file.filename)] : newArray,
      []
    );
  };

  const previousDocuments = useMemo(
    () => (previousMeasurementDates ? getDocumentsForQueriedDates(previousMeasurementDates) : []),
    [previousMeasurementDates]
  );

  const recentDocuments = useMemo(
    () => (recentMeasurementDates ? getDocumentsForQueriedDates(recentMeasurementDates) : []),
    [recentMeasurementDates]
  );

  const previousDocumentsProcessed = useMemo(() => {
    if (isEmpty(previousDocuments) || allObjectsEmpty(previousDocuments)) {
      return [];
    }
    return previousDocuments.map(prevDoc => prevDoc && processDocumentsInformation(prevDoc)) || [];
  }, [previousDocuments]);

  const recentDocumentsProcessed = useMemo(() => {
    if (isEmpty(recentDocuments) || allObjectsEmpty(recentDocuments)) {
      return [];
    }
    return recentDocuments.map(recentDoc => recentDoc && processDocumentsInformation(recentDoc)) || [];
  }, [recentDocuments]);

  const previousDocumentsFilenames = useMemo(() => getDocumentsFilenames(previousDocuments), [previousDocuments]);

  const recentDocumentsFilenames = useMemo(() => getDocumentsFilenames(recentDocuments), [recentDocuments]);

  const mapDocumentsIdToDate = companyDocuments.reduce((acum, b) => ({ ...acum, [b.id]: b.measurement_date.name }), {});

  const handleAccordionOnChange = useCallback(
    (_event, expandedState, label) => {
      setExpandedStates(prevState => ({ ...prevState, [label]: expandedState }));
    },
    [setExpandedStates]
  );

  // handle selecting an item from search bar
  useEffect(() => {
    if (selectedValue) {
      const shouldExpandCurrentDocuments = currentDocumentsFilenames.includes(selectedValue);
      const shouldExpandPreviousDocuments = previousDocumentsFilenames.includes(selectedValue);
      const shouldExpandRecentDocuments = recentDocumentsFilenames.includes(selectedValue);
      // expand 'measurement dates' inside 'previous' and 'recent' accordion
      const expandDates = [...previousDocuments, ...recentDocuments]
        .filter(({ files }) => {
          const filenamesInDocument = files.map(({ file }) => file.filename);
          return filenamesInDocument.includes(selectedValue);
        })
        .reduce((acum, documents) => ({ ...acum, [documents?.measurement_date.date]: true }), {});

      setExpandedStates(prevState => ({
        ...prevState,
        ...expandDates,
        currentDocuments: shouldExpandCurrentDocuments,
        [PREVIOUS_LABEL]: shouldExpandPreviousDocuments,
        [RECENT_LABEL]: shouldExpandRecentDocuments,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, setExpandedStates]);

  const accordionProps = {
    isCurrentData: false,
    displayOnly: selectedValue,
    expandedStates,
    accordionClickHandler: handleAccordionOnChange,
    selectedMeasurementDate,
  };

  if (!selectedMeasurementDate || !currentDocumentsProcessed) {
    return <GridSkeleton />;
  }

  return (
    <div className="grid-bottom-margin">
      <SearchInDocumentsPage
        documents={allDocuments}
        setSelectedValue={setSelectedValue}
        mapDates={mapDocumentsIdToDate}
      />
      <RecentAndPrevAccordions documents={recentDocumentsProcessed} {...accordionProps} />
      <CurrentDocuments
        currentDocumentsProcessed={currentDocumentsProcessed}
        currentDate={currentDate}
        {...accordionProps}
      />
      <RecentAndPrevAccordions documents={previousDocumentsProcessed} {...accordionProps} />
    </div>
  );
};

Documents.propTypes = {
  selectedMeasurementDate: PropTypes.number,
  measurementDates: PropTypes.arrayOf(PropTypes.shape({})),
  companyDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  currentDocumentsProcessed: PropTypes.shape({}),
};

export default Documents;

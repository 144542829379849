/* eslint-disable no-param-reassign */
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { isEmpty, isNull, partition } from 'lodash';
import PropTypes from 'prop-types';
import { SYSTEM_COLLAPSE, USER_EXPAND } from 'common/actions/row-groups/types';
import { VALUATIONS_PAGE_KEY, VALUATIONS_PAGE_VALUE } from 'common/constants/notes';
import { useFormat } from 'common/hooks';
import { rowsGroupsReducer } from 'common/reducers/rowsGroups';
import { Widgets } from 'components';
import ScalarSpreadsheet from 'components/ScalarSpreadsheet';
import CompGroupContext from 'context/CompGroupContext';
import AddGpcDialog from 'pages/Valuations/approaches/guidelinePublicCompanies/AddGpcDialogue';
import useCellOptions from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/useCellOptions';
import { parseGpcComparison } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/utilities';
import { PUBLIC_COMPANIES, SORT_ASC, SORT_DESC, UPDATED_BENCHMARK_ROWS } from 'pages/Valuations/util/constants';
import ValuationContext from 'pages/ValuationsAllocation/ValuationContext';
import { useNotes } from 'services/hooks/notes';
import { getNumberValue, getStringValue } from 'utilities';
import { FIRST_NON_FORWARD_ID, SELECTION, VALUATION_GPC_TABLE_SLUG } from './constants';
import getGPCRowConfig from './gpc/config/getRowConfig';
import gpcRowTransformer from './gpc/config/rowTransformer';
import {
  createColumns as performanceMetricsCreateColumns,
  rowConfig as performanceMetricsRowConfig,
  useCustomClasses as usePerformanceMetricsCustomClasses,
} from './PerformanceMetrics/config';
import getFlattenedData from './utils/getFlattenedData';
import { useCombinedGPCComparisonPerfMetricAttrs } from './utils/useCombinedGPCComparisonPerfMetricAttrs';

const GuidelinePublicCompanies = ({ spreadsheets, onChange, workbook }) => {
  const gpcSpreadsheet = spreadsheets.gpc;
  const { tableData: initialTableData } = gpcSpreadsheet;
  const performanceMetricsSpreadsheet = spreadsheets.performanceMetrics;
  const { financials, approach, companyName, isDisabled } = initialTableData;
  const {
    use_multiple_premium_discount: useMultiplePremiumDiscountFromDB,
    is_evaluating_equity_value: isEvaluatingEquityValue,
  } = approach.valuations_approach_gpc;
  const [format, formatDispatch] = useFormat();
  const prevEvaluatingRef = useRef(isEvaluatingEquityValue);
  const prevMultiplePremiumDiscountRef = useRef(useMultiplePremiumDiscountFromDB);
  const {
    resetConfiguration,
    compGroups,
    setAreThereChanges,
    financialsPeriods,
    setNotesInApproach,
    measurementDate,
    gpcAttributes,
  } = useContext(ValuationContext);

  const combinedGPCComparisonPerfMetricAttrs = useCombinedGPCComparisonPerfMetricAttrs(gpcAttributes);

  const [openGpcDialog, setOpenGpcDialog] = useState(false);
  const [collapsibleColumns, setCollapsibleColumns] = useState({
    [FIRST_NON_FORWARD_ID]: true,
  });
  const [hasMultiplePremiumDiscount, setHasMultiplePremiumDiscount] = useState(
    useMultiplePremiumDiscountFromDB || false
  );
  const approachPanelId = approach.panelId;
  const { notes, setNotes, notesHasChanged, onAddNote, onUpdateNotes, onDeleteNote } = useNotes();

  const { customClasses: performanceMetricsCustomClasses } = usePerformanceMetricsCustomClasses();

  const [cellOptions, handleOnChange] = useCellOptions(onChange, approach.valuations_approach_gpc);

  const [rowGroups, setRowGroups] = useReducer(rowsGroupsReducer, {
    [SELECTION]: hasMultiplePremiumDiscount ? USER_EXPAND : SYSTEM_COLLAPSE,
  });

  // Store companies as they are removed from the table
  const removedGPCs = useRef([]);

  const getUpdatedGPC = ({ gpc }) => {
    if (
      approach.valuations_approach_gpc.gpc_comparison.findIndex(
        comparison => comparison.cap_iq_id === gpc.cap_iq_id.value
      ) >= 0
    ) {
      return null;
    }

    const flattened = getFlattenedData({
      data: gpc,
      attributes: combinedGPCComparisonPerfMetricAttrs,
    });

    flattened.first_metric_enabled = true;
    flattened.second_metric_enabled = true;
    flattened.first_forward__metric_enabled = true;
    flattened.second_forward_metric_enabled = true;
    flattened.name = flattened.company_name;
    const { calendar_years_financials: calendarYearsFinancials } = parseGpcComparison({
      data: gpc,
      attributes: combinedGPCComparisonPerfMetricAttrs,
    });
    flattened.calendar_years_financials = calendarYearsFinancials;
    flattened.reference_for_backsolve = flattened.cap_iq_id;

    return {
      ...approach,
      valuations_approach_gpc: {
        ...approach.valuations_approach_gpc,
        gpc_comparison: [...approach.valuations_approach_gpc.gpc_comparison, flattened],
      },
    };
  };

  const [lastSortedColumn, setLastSortedColumn] = useState(null);

  useEffect(() => {
    if (prevMultiplePremiumDiscountRef.current !== useMultiplePremiumDiscountFromDB) {
      setHasMultiplePremiumDiscount(useMultiplePremiumDiscountFromDB || false);
      prevMultiplePremiumDiscountRef.current = useMultiplePremiumDiscountFromDB;
    }
  }, [useMultiplePremiumDiscountFromDB]);

  const updateApproach = useCallback(
    (approachGpc, tableData, updatedSortedColumn = {}) => {
      const rowConfigParams = {
        companyName,
        approach: approachGpc,
        allCompGroups: compGroups,
        isDisabled,
      };

      // Update GPC Spreadsheet

      gpcSpreadsheet.reset({
        tableData,
        rowConfig: getGPCRowConfig(rowConfigParams),
        columns: gpcRowTransformer({
          approach: approachGpc,
          financials,
          financialsPeriods,
          financialStatementId: tableData.approach.financial_statement,
          sortedColumn: updatedSortedColumn,
          measurementDate,
        }),
      });

      // Update Performance Metrics Spreadsheet

      const performanceMetricsColumns = performanceMetricsCreateColumns({
        valuationsApproachGpc: approachGpc,
        financials,
      });

      performanceMetricsSpreadsheet.reset({
        columns: performanceMetricsColumns,
        rowConfig: performanceMetricsRowConfig({
          companyName: rowConfigParams.companyName,
          customClasses: performanceMetricsCustomClasses,
          valuationsApproachGpc: rowConfigParams.approach,
        }),
        tableData: {
          columns: performanceMetricsColumns,
          customData: cellOptions,
          isDisabled,
        },
      });

      if (approachGpc.is_benchmark) {
        document.dispatchEvent(new Event(UPDATED_BENCHMARK_ROWS));
      }

      resetConfiguration();
    },
    [
      cellOptions,
      companyName,
      compGroups,
      isDisabled,
      gpcSpreadsheet,
      financials,
      financialsPeriods,
      performanceMetricsSpreadsheet,
      measurementDate,
      performanceMetricsCustomClasses,
      resetConfiguration,
    ]
  );

  useEffect(() => {
    const updateTableUIAsync = async () => {
      const rowConfigParams = {
        companyName,
        approach: gpcSpreadsheet.tableData.approach.valuations_approach_gpc,
        allCompGroups: compGroups,
        isDisabled,
      };
      const updatedColumns = gpcRowTransformer({
        approach: gpcSpreadsheet.tableData.approach.valuations_approach_gpc,
        financials,
        financialsPeriods,
        financialStatementId: gpcSpreadsheet.tableData.approach.financial_statement,
        sortedColumn: {},
        measurementDate,
      });
      gpcSpreadsheet.reset({
        rowConfig: getGPCRowConfig(rowConfigParams),
        columns: updatedColumns,
      });
      await workbook.evaluateSpreadsheets(Object.values(spreadsheets));
    };
    if (prevEvaluatingRef.current !== isEvaluatingEquityValue) {
      updateTableUIAsync();
    }
    prevEvaluatingRef.current = isEvaluatingEquityValue;
  }, [
    isEvaluatingEquityValue,
    financials,
    financialsPeriods,
    measurementDate,
    gpcSpreadsheet,
    companyName,
    isDisabled,
    compGroups,
    workbook,
    spreadsheets,
  ]);

  const onAdd = async gpcData => {
    // To make the implementation of onAdd here and in the comp groups consistent, the argument
    // passed to the function is an array (even when it contains a single object in this case)
    const { gpc } = gpcData[0];
    const updatedApproach = getUpdatedGPC({ gpc });
    if (updatedApproach) {
      const { valuations_approach_gpc: approachGpc } = updatedApproach;

      const { tableData } = gpcSpreadsheet;

      tableData.approach.valuations_approach_gpc = {
        ...approachGpc,
        gpc_comparison: approachGpc.gpc_comparison.map((comp, index) => ({
          ...comp,
          order: index,
        })),
      };
      updateApproach(approachGpc, tableData);
    }
  };

  const onRemove = async deletedComparison => {
    const { valuations_approach_gpc: gpc } = approach;
    const compId = deletedComparison.comp_group_id || deletedComparison.gpc_approach_comp_group;
    const currentDeletedCompGroups = gpc.deleted_comp_groups || [];

    // 1. update the specific approach to no longer include this thing
    // Exclude the deleted comparison and others removed previously (if any)
    const filteredComparisons = approach?.valuations_approach_gpc?.gpc_comparison
      .filter(
        comparison =>
          comparison.cap_iq_id !== deletedComparison.cap_iq_id
          && !removedGPCs.current.map(item => item.capitalIQId).includes(comparison.cap_iq_id)
      )
      .map((comp, index) => ({
        ...comp,
        order: index,
      }));

    // Remove the comp group relation of the other companies
    // with the same comp group as the deleted one
    filteredComparisons.forEach(company => {
      if (company.gpc_approach_comp_group === compId) {
        delete company.gpc_approach_comp_group;
      }
      if (company.comp_group_id === compId) {
        delete company.comp_group_id;
      }
    });

    const objectKeyCompGroup = gpc?.valuationapproachgpccompgroup_set?.find(
      item => item.id === deletedComparison.gpc_approach_comp_group
    );

    const valuationKeysToSave = gpc?.valuationapproachgpccompgroup_set?.filter(
      item => item.comp_group !== objectKeyCompGroup?.comp_group
    );

    const tmpValuationsApproachGpc = {
      ...gpc,
      gpc_comparison: filteredComparisons,
      valuationapproachgpccompgroup_set: valuationKeysToSave,
    };

    removedGPCs.current.push({
      // Needed for new approaches that have not been saved yet
      capitalIQId: deletedComparison.cap_iq_id,
      // Existing approaches (already persisted to the database) have an id
      gpcComparisonsId: deletedComparison.id ? deletedComparison.id : null,
    });

    const { tableData } = gpcSpreadsheet;

    tableData.approach.valuations_approach_gpc = {
      ...gpc,
      valuationapproachgpccompgroup_set: valuationKeysToSave,
      gpc_comparison: filteredComparisons,
      // Add deleted_gpc_comparisons only in existing approaches
      // since they do not make sense in brand new ones
      deleted_gpc_comparisons: approach.id
        ? removedGPCs.current.map(item => item.gpcComparisonsId).filter(id => !isNull(id))
        : [],
      // If the company has a comp group, remove it from the list of deleted comp groups
      deleted_comp_groups: deletedComparison.gpc_approach_comp_group
        ? [...currentDeletedCompGroups, ...[compId]]
        : currentDeletedCompGroups,
    };

    updateApproach(tmpValuationsApproachGpc, tableData);
    setAreThereChanges(true);
  };

  const getFlattenedItem = useCallback(
    companyItem => {
      const { gpc } = companyItem;
      const { comp_group_id, comp_group_name, ...newGpc } = gpc;
      const flattened = getFlattenedData({ data: newGpc, attributes: combinedGPCComparisonPerfMetricAttrs });

      flattened.first_metric_enabled = true;
      flattened.second_metric_enabled = true;
      flattened.first_forward_metric_enabled = true;
      flattened.second_forward_metric_enabled = true;
      flattened.reference_for_backsolve = flattened.cap_iq_id;
      flattened.name = flattened.company_name;
      const { calendar_years_financials: calendarYearsFinancials } = parseGpcComparison({
        data: gpc,
        attributes: combinedGPCComparisonPerfMetricAttrs,
      });
      flattened.calendar_years_financials = calendarYearsFinancials;

      if (comp_group_id && comp_group_name) {
        flattened.comp_group_id = comp_group_id;
        flattened.comp_group_name = comp_group_name;
        flattened.temp_ref = comp_group_id;
      }
      return flattened;
    },
    [combinedGPCComparisonPerfMetricAttrs]
  );

  const getCompGroupsData = useCallback(
    (comparisons, compGroupToDelete) => {
      const { tableData } = gpcSpreadsheet;
      const { valuationapproachgpccompgroup_set } = tableData.approach.valuations_approach_gpc;
      const compGroupsData
        = valuationapproachgpccompgroup_set?.filter(item => compGroupToDelete !== item.comp_group) || [];
      const newCompGroups = comparisons
        .filter(comparison => comparison.comp_group_id && compGroupToDelete !== comparison.comp_group_id)
        .filter(item => !compGroupsData.some(compGroupData => compGroupData.id === item.comp_group_id))
        .map(comparisonCompGroupItem => ({
          isNew: true,
          comp_group: comparisonCompGroupItem.comp_group_id,
          comp_group_name: comparisonCompGroupItem.comp_group_name,
          use_latest_comp_group_version: true,
        }));
      const uniqueNewCompGroupsData = [...new Map(newCompGroups.map(item => [item.comp_group, item])).values()];
      return [...compGroupsData, ...uniqueNewCompGroupsData];
    },
    [gpcSpreadsheet]
  );

  const getComparisonsData = useCallback(
    (comparisons, comparisonsToDelete, compGroupsData, useLatestVersion) => {
      const { tableData } = gpcSpreadsheet;
      const { gpc_comparison } = tableData.approach.valuations_approach_gpc;
      const remainingTransactions = gpc_comparison.filter(
        currentTransaction => !comparisonsToDelete.includes(currentTransaction.id || currentTransaction.temp_id)
      );
      const comparisonsWithUpdatedCompGroup = remainingTransactions.map(comparison => {
        const transactionCompGroupId = comparison.gpc_approach_comp_group || comparison.compgroupId;
        const hasCompGroup = compGroupsData.some(compGroup => compGroup.id === transactionCompGroupId);
        if (!hasCompGroup) {
          const { gpc_approach_comp_group, comp_group_id, comp_group_name, temp_ref, ...newTransactionObj }
            = comparison;
          return newTransactionObj;
        }

        return comparison;
      });
      comparisons.forEach(comparison => {
        const currentTransactionIdx = comparisonsWithUpdatedCompGroup.findIndex(
          current => current.cap_iq_id === comparison.cap_iq_id
        );
        if (currentTransactionIdx !== -1) {
          const currentTransactionData = comparisonsWithUpdatedCompGroup[currentTransactionIdx];
          if (useLatestVersion) {
            comparisonsWithUpdatedCompGroup[currentTransactionIdx] = {
              id: currentTransactionData.id,
              ...comparison,
            };
          } else {
            const { comp_group_id, comp_group_name, temp_ref, gpc_approach_comp_group, ...updatedCurrentTransaction }
              = currentTransactionData;
            comparisonsWithUpdatedCompGroup[currentTransactionIdx] = {
              id: currentTransactionData.id,
              ...updatedCurrentTransaction,
            };
          }
        } else {
          comparisonsWithUpdatedCompGroup.push(comparison);
        }
      });
      return comparisonsWithUpdatedCompGroup;
    },
    [gpcSpreadsheet]
  );

  const getDeletedCompGroupsIDs = useCallback(
    compGroupToDelete => {
      const { tableData } = gpcSpreadsheet;
      const { valuationapproachgpccompgroup_set } = tableData.approach.valuations_approach_gpc;
      const findMatch = compGroupItem => compGroupToDelete === compGroupItem.comp_group;
      return valuationapproachgpccompgroup_set
        .filter(compGroupItem => !compGroupItem.isNew && findMatch(compGroupItem))
        .map(compGroupItem => compGroupItem.id);
    },
    [gpcSpreadsheet]
  );

  const saveCompGroups = useCallback(
    async (comparisons, useLatestVersion, comparisonsToDelete, compGroupToDelete) => {
      const { tableData } = gpcSpreadsheet;
      const validComparisons = comparisons.filter(item => item.valid_gpc_results);

      const flattenedComparisons = validComparisons.map(companyItem => getFlattenedItem(companyItem));
      const compGroupsData = getCompGroupsData(flattenedComparisons, compGroupToDelete);
      const gpcComparison = getComparisonsData(
        flattenedComparisons,
        comparisonsToDelete,
        compGroupsData,
        useLatestVersion
      );

      const valuationApproachGPC = {
        ...tableData.approach.valuations_approach_gpc,
        gpc_comparison: gpcComparison,
        valuationapproachgpccompgroup_set: compGroupsData,
      };

      if (!isEmpty(comparisonsToDelete)) {
        valuationApproachGPC.deleted_gpc_comparisons = comparisonsToDelete;
      }
      if (compGroupToDelete) {
        const deletedCompGroups = getDeletedCompGroupsIDs(compGroupToDelete);
        valuationApproachGPC.deleted_comp_groups = deletedCompGroups;
      }
      tableData.approach.valuations_approach_gpc = valuationApproachGPC;
      updateApproach(valuationApproachGPC, tableData);
    },
    [getCompGroupsData, getComparisonsData, getDeletedCompGroupsIDs, gpcSpreadsheet, updateApproach, getFlattenedItem]
  );

  const deleteCompGroup = useCallback(
    () => compGroupToDelete => {
      const { tableData } = gpcSpreadsheet;

      const { gpc_comparison, valuationapproachgpccompgroup_set, deleted_gpc_comparisons, deleted_comp_groups }
        = tableData.approach.valuations_approach_gpc;
      const currentDeletedComparisons = deleted_gpc_comparisons || [];
      const currentDeletedCompGroups = deleted_comp_groups || [];

      const comparisonResult = partition(gpc_comparison, item => {
        const compGroupId = item.gpc_approach_comp_group || item.comp_group_id;
        return compGroupId === compGroupToDelete;
      });

      const compGroupData = valuationapproachgpccompgroup_set.filter(compGroup => {
        const compGroupId = compGroup.id || compGroup.comp_group;
        return compGroupId !== compGroupToDelete;
      });

      const comparisonsToDelete = comparisonResult[0];
      const remainingComparisons = comparisonResult[1];

      const updatedApproach = {
        gpc_comparison: remainingComparisons,
        valuationapproachgpccompgroup_set: compGroupData,
        deleted_comp_groups: [...new Set([...currentDeletedCompGroups, ...[compGroupToDelete]])],
      };

      if (comparisonsToDelete) {
        updatedApproach.deleted_gpc_comparisons = [...currentDeletedComparisons, ...comparisonsToDelete]
          ?.filter(({ id }) => id)
          ?.map(({ id }) => id);
      }
      tableData.approach.valuations_approach_gpc = {
        ...tableData.approach.valuations_approach_gpc,
        ...updatedApproach,
      };
      updateApproach(tableData.approach.valuations_approach_gpc, tableData);
    },
    [gpcSpreadsheet, updateApproach]
  );

  const compGroupContextValue = useMemo(
    () => ({
      saveCompGroups,
      deleteCompGroup,
      tableData: gpcSpreadsheet.tableData,
      approachType: PUBLIC_COMPANIES,
      cellOptions,
      setUseMultiplePremiumDiscount: setHasMultiplePremiumDiscount,
      useMultiplePremiumDiscount: hasMultiplePremiumDiscount,
    }),
    [cellOptions, deleteCompGroup, gpcSpreadsheet, saveCompGroups, hasMultiplePremiumDiscount]
  );

  useEffect(() => {
    if (!isEmpty(notes)) {
      setNotesInApproach(prevState => {
        const tmpNotes = prevState.filter(note => note.panelId !== approachPanelId);
        return [...tmpNotes, { notes, panelId: approachPanelId, notesHasChanged }];
      });
    }
  }, [notes, notesHasChanged, approachPanelId, setNotesInApproach]);

  // Multiple Premium Discount
  useEffect(() => {
    setRowGroups({ type: hasMultiplePremiumDiscount ? USER_EXPAND : SYSTEM_COLLAPSE, row: SELECTION });
  }, [hasMultiplePremiumDiscount, setRowGroups]);

  const updateUseMultiplePremiumDiscount = useCallback(async () => {
    const { tableData } = gpcSpreadsheet;
    tableData.approach.valuations_approach_gpc = {
      ...tableData.approach.valuations_approach_gpc,
      use_multiple_premium_discount: hasMultiplePremiumDiscount,
    };
    updateApproach(tableData.approach.valuations_approach_gpc, tableData);
  }, [gpcSpreadsheet, updateApproach, hasMultiplePremiumDiscount]);

  // Update the Use Multiple Premium Discount when clicking on the button
  useEffect(() => {
    updateUseMultiplePremiumDiscount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMultiplePremiumDiscount]);

  // End of Multiple Premium Discount

  const sortGPC = useCallback(
    (key, isStringColumn = false, isDateColumn = false) => {
      const { tableData } = gpcSpreadsheet;

      const currentSortedColumn = getStringValue(lastSortedColumn?.[key]);
      const currentSortedColumnIsDesc = currentSortedColumn === SORT_DESC;

      const updatedSortedColumn = {
        [key]: currentSortedColumnIsDesc ? SORT_ASC : SORT_DESC,
      };

      const sortedComparison = tableData.approach.valuations_approach_gpc.gpc_comparison.sort((a, b) => {
        if (a?.[key] && b?.[key]) {
          setLastSortedColumn(updatedSortedColumn);

          if (currentSortedColumnIsDesc) {
            return isStringColumn
              ? getStringValue(a?.[key]).localeCompare(getStringValue(b?.[key]))
              : getNumberValue(a?.[key]) - getNumberValue(b?.[key]);
          }

          return isStringColumn
            ? getStringValue(b?.[key]).localeCompare(getStringValue(a?.[key]))
            : getNumberValue(b?.[key]) - getNumberValue(a?.[key]);
        }

        return 0;
      });

      const valuationApproachGPC = {
        ...tableData.approach.valuations_approach_gpc,
        gpc_comparison: sortedComparison.map((comp, index) => ({
          ...comp,
          order: index,
        })),
      };

      tableData.approach.valuations_approach_gpc = valuationApproachGPC;
      updateApproach(valuationApproachGPC, tableData, updatedSortedColumn);
    },
    [gpcSpreadsheet, lastSortedColumn, updateApproach]
  );

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <CompGroupContext.Provider value={compGroupContextValue}>
        <ScalarSpreadsheet
          {...gpcSpreadsheet}
          accessSheet
          addSingleRow={() => setOpenGpcDialog(true)}
          rowGroups={rowGroups}
          setRowGroups={setRowGroups}
          allowSortColumn
          collapsibleColumns={collapsibleColumns}
          deleteRowFn={onRemove}
          format={format}
          formatDispatch={formatDispatch}
          key={gpcSpreadsheet.name}
          onChange={handleOnChange}
          setCollapsibleColumns={setCollapsibleColumns}
          sheet={gpcSpreadsheet}
          sortColumnFn={sortGPC}
          sortedColumn={lastSortedColumn}
          tableTerms={{
            tableSlug: VALUATION_GPC_TABLE_SLUG,
          }}
          workbook={workbook}
        />
      </CompGroupContext.Provider>
      <Grid container spacing={3}>
        <Grid item xs={12} container direction="column" alignItems="flex-end" justifyContent="flex-end">
          <AddGpcDialog
            onAdd={onAdd}
            saveCompGroups={saveCompGroups}
            deleteCompGroup={deleteCompGroup}
            tableData={initialTableData}
            source="valuation"
            currentComps={gpcSpreadsheet.tableData.approach.valuations_approach_gpc.gpc_comparison}
            openGpcDialog={openGpcDialog}
            setOpenGpcDialog={setOpenGpcDialog}
            isDisabled={isDisabled}
          />
        </Grid>
      </Grid>
      <br />
      <ScalarSpreadsheet
        {...performanceMetricsSpreadsheet}
        key={performanceMetricsSpreadsheet.name}
        onChange={onChange}
        sheet={performanceMetricsSpreadsheet}
        workbook={workbook}
        format={format}
        formatDispatch={formatDispatch}
        displayColumnIndicator
        displayRowIndicator
      />
      <br />
      <Widgets
        notesProps={{
          pageType: VALUATIONS_PAGE_VALUE,
          pageTypeKey: VALUATIONS_PAGE_KEY,
          pageTypeId: approach.id,
          notes,
          isApproach: true,
          setNotes,
          onAddNote,
          onUpdateNotes,
          onDeleteNote,
          isDisabled,
        }}
      />
    </Box>
  );
};

GuidelinePublicCompanies.propTypes = {
  spreadsheets: PropTypes.object,
  onChange: PropTypes.func,
  workbook: PropTypes.object,
};

export default GuidelinePublicCompanies;

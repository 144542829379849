import {
  SECOND_FORWARD_ID,
  SECOND_NON_FORWARD_ID,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import {
  LTM_EBITDA_MULTIPLE_ID,
  NTM_EBITDA_MULTIPLE_ID,
} from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';
import { GetEbitdaValueForViewerCell } from './types';

const getEbitdaValueForViewer = (params: GetEbitdaValueForViewerCell) => {
  const { cell, options, valuation_approach_adjustments, is_transaction_comps: isTransactionComps = false } = params;
  const { columnId } = cell;
  const { use_adjusted_LTM_ebitda: useAdjLTMEbitda, use_adjusted_NTM_ebitda: useAdjNTMEbitda }
    = valuation_approach_adjustments;
  const ltmEbitdaAliasToValidate = isTransactionComps ? LTM_EBITDA_MULTIPLE_ID : SECOND_NON_FORWARD_ID;
  const ntmEbitdaAliasToValidate = isTransactionComps ? NTM_EBITDA_MULTIPLE_ID : SECOND_FORWARD_ID;

  if (columnId === ltmEbitdaAliasToValidate) {
    return useAdjLTMEbitda ? options[columnId][1].value : options[columnId][0].value;
  }

  if (columnId === ntmEbitdaAliasToValidate) {
    return useAdjNTMEbitda ? options[columnId][1].value : options[columnId][0].value;
  }

  return null;
};

export default getEbitdaValueForViewer;

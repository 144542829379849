import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { NOT_APPLICABLE } from 'common/constants/general';
import FeaturedSpreadsheetContext from 'components/FeaturedSpreadsheet/context/FeaturedSpreadsheetContext';
import withTooltip from 'components/WithTooltip';
import { formatNumbers } from 'utilities';

const useStyles = makeStyles({
  root: {
    width: '100%',
    '&& .MuiCheckbox-root': {
      padding: '0px 16px 0px 3px',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  strike: {
    textDecoration: 'line-through',
    color: grey,
  },
});

const Strike = ({ value, enabled, isInvalidExpr, classes, isNegativeOrZero }) => {
  const valueLabel = isInvalidExpr ? NOT_APPLICABLE : value;
  if (!enabled && !isNegativeOrZero) {
    return <span className={classes.strike}>{valueLabel}</span>;
  }
  return <span>{valueLabel}</span>;
};

const NumberMultipleCheckbox = ({ cell, selected }) => {
  const {
    enabled: cellEnabled,
    ignoreExpr: cellIgnoreExpr,
    expr: cellExpr,
    value: cellValue,
    format: cellFormat,
    suffix: cellSuffix,
    allowNegativeValue: cellAllowNegativeValue,
    prefix: cellPrefix,
    currency: cellCurrency,
    currencyCode: cellCurrencyCode,
    blankIfNull: cellBlankIfNull,
    staticCurrency: cellStaticCurrency,
  } = cell;

  const classes = useStyles();
  const { onCellsChanged } = useContext(FeaturedSpreadsheetContext);
  const [enabled, setEnabled] = useState(cellEnabled);
  const [disableChangeState, setDisableChangeState] = useState(false);
  const [isNegativeOrZero, setIsNegativeOrZero] = useState(false);
  const prevIsNegativeOrZeroRef = useRef(false);

  const isInvalidExpr = cellIgnoreExpr === true;

  const changeCell = useCallback(
    enable => {
      cell.setProps({ enabled: enable });
      setEnabled(enable);
      onCellsChanged([{ cell, value: cellExpr }]);
    },
    [cell, cellExpr, onCellsChanged]
  );

  useEffect(() => {
    if (isInvalidExpr || isNegativeOrZero) {
      changeCell(false);
    } else {
      setEnabled(cellEnabled);
    }

    setDisableChangeState(isInvalidExpr);
  }, [cellEnabled, changeCell, isInvalidExpr, isNegativeOrZero]);

  useEffect(() => {
    if (cellValue <= 0) {
      setIsNegativeOrZero(true);
      if (cellEnabled) {
        changeCell(false);
      }
    } else {
      setIsNegativeOrZero(false);
      if (!cellEnabled && prevIsNegativeOrZeroRef.current) {
        changeCell(true);
      }
    }

    prevIsNegativeOrZeroRef.current = isNegativeOrZero;
  }, [cellValue, cellEnabled, changeCell, isNegativeOrZero]);

  // Function to check if the target is a table cell container or a checkbox
  const isValidTarget = useCallback(target => {
    const isDataGridContainer = target.tagName === 'SPAN' && target.classList.contains('data-grid-container');
    const isInputCheckbox = target.tagName === 'INPUT' && target.type === 'checkbox';
    return isDataGridContainer || isInputCheckbox;
  }, []);

  const keyUpDownHandler = useCallback(
    event => {
      const { code, type, target } = event;
      // If the target is valid
      if (isValidTarget(target)) {
        if (selected && code === 'Space') {
          // Prevent the default spacebar behavior (scroll down)
          event.preventDefault();
          if (type === 'keyup' && !isNegativeOrZero) {
            changeCell(!cellEnabled);
          }
        }
      } else {
        // Remove the keyup event to prevent the spacebar from being captured by the component elsewhere
        document.removeEventListener('keyup', keyUpDownHandler);
      }
    },
    [isValidTarget, selected, isNegativeOrZero, changeCell, cellEnabled]
  );

  // Click handler to check if the user clicked outside a cell
  const handleClick = useCallback(
    event => {
      // If the click did not occur inside a table cell ('td'), remove the keyup event
      if (!event.target.closest('td')) {
        document.removeEventListener('keyup', keyUpDownHandler);
      }
    },
    [keyUpDownHandler]
  );

  useEffect(() => {
    document.addEventListener('keyup', keyUpDownHandler);
    document.addEventListener('keydown', keyUpDownHandler);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('keyup', keyUpDownHandler);
      document.removeEventListener('keydown', keyUpDownHandler);
      document.removeEventListener('click', handleClick);
    };
  }, [keyUpDownHandler, handleClick]);

  const handleChange = useCallback(
    ({ target: { checked } }) => {
      changeCell(checked);
    },
    [changeCell]
  );

  const CellValueFormatted = useMemo(
    () =>
      formatNumbers({
        format: cellFormat,
        prefix: cellPrefix,
        suffix: cellSuffix,
        allowNegativeValue: cellAllowNegativeValue,
        currency: cellCurrency,
        currencyCode: cellCurrencyCode,
        blankIfNull: cellBlankIfNull,
        staticCurrency: cellStaticCurrency,
        value: cellValue,
      }),

    [
      cellAllowNegativeValue,
      cellBlankIfNull,
      cellCurrency,
      cellCurrencyCode,
      cellFormat,
      cellPrefix,
      cellStaticCurrency,
      cellSuffix,
      cellValue,
    ]
  );

  const numberDisplay = useMemo(
    () => (
      <Strike
        value={CellValueFormatted}
        enabled={enabled}
        isInValidExpr={isInvalidExpr}
        classes={classes}
        isNegativeOrZero={isNegativeOrZero}
      />
    ),
    [CellValueFormatted, enabled, isInvalidExpr, classes, isNegativeOrZero]
  );

  let content;
  const renderFormControl = control => (
    <FormControl className={classes.root}>
      <FormControlLabel className={classes.root} control={control} label={numberDisplay} labelPlacement="start" />
    </FormControl>
  );
  if (isNegativeOrZero) {
    content = selected ? (
      renderFormControl(<span style={{ paddingRight: '2.2rem' }} />)
    ) : (
      <span className="unselected-checkbox-cell">-</span>
    );
  } else {
    content = selected ? (
      renderFormControl(<Checkbox disabled={disableChangeState} checked={enabled} onChange={handleChange} />)
    ) : (
      <span className="unselected-checkbox-cell">{numberDisplay}</span>
    );
  }
  return <div>{content}</div>;
};

NumberMultipleCheckbox.propTypes = {
  cell: PropTypes.shape({
    alias: PropTypes.string,
    allowNegativeValue: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({}), PropTypes.instanceOf(undefined)]),
    code: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.instanceOf(undefined)]),
    columnId: PropTypes.string,
    columnLegend: PropTypes.string,
    columnOrder: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(undefined)]),
    component: PropTypes.shape({}),
    defaultValue: PropTypes.number,
    dependencies: PropTypes.instanceOf(Set),
    enabled: PropTypes.bool,
    evaluated: PropTypes.bool,
    expr: PropTypes.string,
    forceComponent: PropTypes.bool,
    format: PropTypes.shape({}),
    gridType: PropTypes.string,
    hidden: PropTypes.bool,
    ignoreExpr: PropTypes.bool,
    ignoreRowCopy: PropTypes.bool,
    invalidExpr: PropTypes.bool,
    isEditableTitleCell: PropTypes.bool,
    isExpr: PropTypes.bool,
    isGptRow: PropTypes.bool,
    isRequired: PropTypes.bool,
    key: PropTypes.string,
    linkedCells: PropTypes.instanceOf(Set),
    parentColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(undefined)]),
    providers: PropTypes.instanceOf(Set),
    readOnly: PropTypes.bool,
    revenue: PropTypes.string,
    rowNumber: PropTypes.number,
    row_ref: PropTypes.string,
    setProps: PropTypes.func,
    sheet: PropTypes.shape({}),
    suffix: PropTypes.string,
    symbols: PropTypes.instanceOf(Set),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    valueViewer: PropTypes.func,

    // Unknown fields
    prefix: PropTypes.string,
    currency: PropTypes.string,
    currencyCode: PropTypes.string,
    blankIfNull: PropTypes.bool,
    staticCurrency: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
};

Strike.propTypes = {
  value: PropTypes.string,
  enabled: PropTypes.bool,
  isInvalidExpr: PropTypes.bool,
  classes: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.instanceOf(undefined)]),
  isNegativeOrZero: PropTypes.bool,
};

export default withTooltip(NumberMultipleCheckbox);

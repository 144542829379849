import React from 'react';
import { range } from 'mathjs';
import { GRID_NUMBER_CHECKBOX } from 'common/constants/gridType';
import { MULTIPLE_PREMIUM_DISCOUNT_ALIAS, VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT } from 'common/constants/valuations';
import {
  largeCurrencyFormat,
  oneDecimalPercentFormatValidateFloatTrue,
  weightingPercentFormat,
  xSmallSuffixFormat,
  xStandardSuffixFormat,
} from 'common/formats/formats';
import { GridType } from 'common/types/scalarSpreadsheet';
import { SelectValueViewer } from 'components';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import NumberCheckbox from 'components/FeaturedSpreadsheet/components/NumberMultipleCheckbox';
import * as constants from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import MultipleTypeSelect from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/MultipleTypeSelect';
import MultipleTypeValueViewer from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/MultipleTypeValueViewer';
import { CompanyInfoDialog } from 'pages/Valuations/components/CompanyInfoDialog';
import CompGroupRowHeader from 'pages/Valuations/components/CompGroupRowHeader';
import {
  get25thPercentileExpression,
  get75thPercentileExpression,
  getAppliedMultipleExpression,
  getMeanExpression,
  getMedianExpression,
  getVariableExpressions,
} from 'pages/Valuations/util/util';
import { VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY } from 'pages/ValuationsAllocation/common/constants/valuations';
import { getPercentileOptions } from 'pages/ValuationsAllocation/util';
import { getSelectionCellOptions } from 'pages/ValuationsAllocation/util/getSelectionCellOptions';
import { generateColorFromString, getStringValue } from 'utilities';
import { APPLIED_MULTIPLE_ROW_NUMBER, COMPANY_REVENUE_ROW_NUMBER, MULTIPLE_TYPE_ROW_NUMBER } from './constants';
import { GPCConfigurationRow, GPCRowConfig, RowConfigComparison } from '../../types';

export const percentileOptions: { label: string; value: number }[] = getPercentileOptions([...Array(99)]) as {
  label: string;
  value: number;
}[];
// create a variable that is the percentileOptions (which itself is a list of objects with the shape {label,value}) and create a
// map of the labels to the values
export const percentileOptionsMap
  = percentileOptions
  ?? [].reduce((acc, { label, value }) => {
    acc[label] = value;
    return acc;
  }, {});

const getRowConfig: GPCRowConfig = rowConfigParams => {
  const { companyName, approach, allCompGroups, isDisabled } = rowConfigParams;
  // Public comps have an "order" field that resembles the sorting the user has done on the UI.
  // For that reason, we don't need to worry about the sort column or the type (asc or desc).
  const gpcComparison = approach?.gpc_comparison?.sort((a, b) => (a?.order as number) - (b?.order as number)) || [];
  const isEvaluatingEquityValue = approach?.is_evaluating_equity_value ?? false;
  const currentCompGroups = approach?.valuationapproachgpccompgroup_set || [];
  const gpcRange = range(1, gpcComparison.length + 1)
    .map(rowNumber => `@${rowNumber + 2}`)
    .toString()
    .replace(/"/g, '');

  const getIdentifier = (comparison: RowConfigComparison) => {
    const compGroupId
      = 'gpc_approach_comp_group' in comparison ? comparison.gpc_approach_comp_group : comparison.comp_group_id;
    if (compGroupId) {
      const compGroupOfComparison = currentCompGroups.find(item => (item?.id ?? item.comp_group) === compGroupId);
      if (!compGroupOfComparison || !allCompGroups) {
        return null;
      }

      const comparisonCompGroupInfo = allCompGroups.find(
        compGroupItem => compGroupItem.id === compGroupOfComparison?.comp_group
      );

      return {
        id: comparisonCompGroupInfo?.id,
        name: comparisonCompGroupInfo?.name,
      };
    }
    return null;
  };

  const getCompGroupData = (comparison: RowConfigComparison) => {
    const comparisonCompGroupInfo = getIdentifier(comparison);
    if (comparisonCompGroupInfo) {
      return {
        id: comparisonCompGroupInfo.id,
        name: comparison.comp_group_name,
        color: generateColorFromString(comparison.comp_group_name),
      };
    }
    return null;
  };

  const comparisonRows: GPCConfigurationRow[] = gpcComparison.map(comparison => ({
    alias: getStringValue(comparison.cap_iq_id),
    gridType: GRID_NUMBER_CHECKBOX as GridType,
    component: <NumberCheckbox />,
    ignoreRowCopy: true,
    forceComponent: !isDisabled,
    format: xSmallSuffixFormat,
    isTitleWithLabel: true,
    defaultValue: 0,
    isGpcRow: true,
    className: 'company-title',
    value: (
      <CompGroupRowHeader
        value={comparison.company_name}
        comparisonData={comparison}
        compGroupData={getCompGroupData(comparison as RowConfigComparison)}
        companyDialogComponent={CompanyInfoDialog}
        allowShowCompanyDialog
        isGPC
      />
    ),
  }));

  const selectionOptions = getSelectionCellOptions({
    specificApproach: approach,
  });
  const firstPercentileSelectionValue = approach?.percentile_selection_a;
  const secondPercentileSelectionValue = approach?.percentile_selection_b;
  const variableExpressions = getVariableExpressions({
    companiesRange: gpcRange,
    currentSelectionOptions: selectionOptions,
    numberOfCompanies: gpcComparison.length,
  });

  return [
    {
      className: 'table-header spreadsheet-table-title gpc-table-header',
      readOnly: true,
      value: 'Public Comps',
      gridType: 'string',
      rowSpan: 2,
      style: { textAlign: 'center' },
      ignoreRowCopy: true,
      isTitleOrHeader: true,
    } as GPCConfigurationRow,
    {
      className: `table-header ${isEvaluatingEquityValue ? 'smaller-font' : ''}`,
      alias: 'title',
      readOnly: true,
      value: '',
      gridType: 'string',
      isTitleOrHeader: true,
    } as GPCConfigurationRow,
    ...comparisonRows,
    {
      alias: constants.MEDIAN_ALIAS,
      readOnly: true,
      value: 'Median',
      expr: getMedianExpression(gpcRange),
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      isClassNameRequired: true,
      className: 'row-label-indented division-top-only',
    } as GPCConfigurationRow,
    {
      alias: constants.MEAN_ALIAS,
      readOnly: true,
      value: 'Mean',
      expr: getMeanExpression(gpcRange),
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      className: 'row-label-indented',
    } as GPCConfigurationRow,
    {
      alias: constants.SEVENTY_FIFTH_ALIAS,
      readOnly: true,
      value: '75th Percentile',
      hidden: true,
      expr: get75thPercentileExpression(gpcRange),
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      className: 'row-label-indented',
      isVisible: false,
    } as GPCConfigurationRow,
    {
      alias: constants.TWENTY_FIFTH_ALIAS,
      readOnly: true,
      value: '25th Percentile',
      hidden: true,
      expr: get25thPercentileExpression(gpcRange),
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      className: 'row-label-indented',
      isVisible: false,
    } as GPCConfigurationRow,
    {
      alias: constants.PERCENTILE_SELECTION_A_ALIAS,
      readOnly: true,
      dataEditor: (props: any) => <GridSelect {...props} options={percentileOptions} />,
      valueViewer: (props: any) => <SelectValueViewer {...props} options={percentileOptions} />,
      dropdown: true,
      isEditableTitleCell: true,
      value: firstPercentileSelectionValue,
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      className: 'row-label-indented',
      useScalarSpreadsheetCell: true,
    } as GPCConfigurationRow,
    {
      readOnly: true,
      dataEditor: (props: any) => <GridSelect {...props} options={percentileOptions} />,
      valueViewer: (props: any) => <SelectValueViewer {...props} options={percentileOptions} />,
      dropdown: true,
      isEditableTitleCell: true,
      value: secondPercentileSelectionValue,
      alias: constants.PERCENTILE_SELECTION_B_ALIAS,
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
      className: 'row-label-indented',
      useScalarSpreadsheetCell: true,
    } as GPCConfigurationRow,
    {
      alias: constants.SELECTED_MULTIPLE,
      value: 'Specified Multiple',
      gridType: 'number',
      format: xStandardSuffixFormat,
      readOnly: isDisabled,
      defaultValue: 0,
      ignoreRowCopy: true,
      className: 'row-label-indented',
    } as GPCConfigurationRow,
    {
      className: 'subtitle row-label-bold',
      alias: constants.GPC_SUMMARY,
      readOnly: true,
      value: 'Public Comps Summary',
    } as GPCConfigurationRow,
    {
      alias: constants.COMPANY,
      readOnly: true,
      value: `${companyName}`,
      gridType: 'number',
      format: largeCurrencyFormat,
      ignoreRowCopy: true,
      allowNegativeValue: true,
    } as GPCConfigurationRow,
    {
      alias: constants.SELECTION,
      readOnly: isDisabled,
      dataEditor: (props: any) => <MultipleTypeSelect {...props} />,
      valueViewer: (props: any) => <MultipleTypeValueViewer {...props} />,
      value: 'Multiple Type',
      ignoreRowCopy: true,
      options: selectionOptions,
    } as unknown as GPCConfigurationRow,
    {
      alias: MULTIPLE_PREMIUM_DISCOUNT_ALIAS,
      readOnly: isDisabled,
      value: VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT,
      ignoreRowCopy: true,
      gridType: 'number',
      format: oneDecimalPercentFormatValidateFloatTrue,
      allowNegativeValue: true,
      hideInGptConfig: true,
      parent: constants.SELECTION,
    } as GPCConfigurationRow,
    {
      alias: constants.SELECTED_MULTIPLE_VALUE,
      readOnly: true,
      value: 'Applied Multiple',
      expr: getAppliedMultipleExpression({
        offset: MULTIPLE_TYPE_ROW_NUMBER,
        comparisonsLength: gpcComparison.length,
        variableExpressions,
      }),
      gridType: 'number',
      format: xStandardSuffixFormat,
      ignoreRowCopy: true,
    } as GPCConfigurationRow,
    {
      alias: VALUATIONS_SPREADSHEET_ENTERPRISE_VALUE_KEY,
      readOnly: true,
      value: `${isEvaluatingEquityValue ? 'Equity' : 'Enterprise'} Value`,
      // Revenue (row [11 + gpcComparison.length]) * Applied Multiple (row [14 + gpcComparison.length])
      expr: `=@${COMPANY_REVENUE_ROW_NUMBER + gpcComparison.length} * @${
        APPLIED_MULTIPLE_ROW_NUMBER + gpcComparison.length
      }`,
      ignoreRowCopy: true,
      style: { fontWeight: 'bold' },
      gridType: 'number',
      format: largeCurrencyFormat,
      allowNegativeValue: false,
      className: 'row-label-bold',
    } as GPCConfigurationRow,
    {
      alias: constants.WEIGHTING,
      readOnly: isDisabled,
      value: 'Weighting',
      gridType: 'number',
      ignoreRowCopy: true,
      format: { ...weightingPercentFormat, isDecimal: false },
      ignoreAutoScroll: true,
    } as GPCConfigurationRow,
    {
      className: 'subtitle weighted-ev-label',
      alias: constants.WEIGHTED_EV,
      readOnly: true,
      value: `Weighted ${isEvaluatingEquityValue ? 'Equity' : 'Enterprise'} Value`,
      ignoreRowCopy: true,
      isNotNavigable: true,
    } as GPCConfigurationRow,
    {
      alias: constants.PLUS_CASH_ALIAS,
      hideInGptConfig: true,
      value: `${isEvaluatingEquityValue ? 'Less' : 'Plus'} Cash`,
      readOnly: true,
    } as GPCConfigurationRow,
    {
      alias: constants.LESS_DEBT_ALIAS,
      hideInGptConfig: true,
      value: `${isEvaluatingEquityValue ? 'Plus' : 'Less'} Debt`,
      readOnly: true,
    } as GPCConfigurationRow,
    {
      className: 'subtitle weighted-ev-label',
      alias: constants.WEIGHTED_EQUITY_VALUE,
      readOnly: true,
      value: `Weighted ${isEvaluatingEquityValue ? 'Enterprise' : 'Equity'} Value`,
      ignoreRowCopy: true,
      isNotNavigable: true,
    } as GPCConfigurationRow,
  ];
};

export default getRowConfig;

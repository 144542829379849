import { createStyles, makeStyles } from '@material-ui/styles';
import { ITheme } from 'theme/types';

const elementWidth = '100%';

const useStyles = makeStyles((theme: ITheme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(2.5),
    },
    formControl: {
      minWidth: elementWidth,
    },
    select: {
      maxWidth: elementWidth,
      color: theme.palette.gray[500],
    },
  })
);

export default useStyles;

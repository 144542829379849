// TODO: Check and update the constants or move to ValuationsAllocation
export const VALUATIONS_TITLE = 'Valuations';
export const VALUATIONS_SLUG = 'valuations';
export const VALUATION_SUMMARY = 'Summary';
export const VALUATION_SUMMARY_PANEL = 'valuation-panel';
export const TOTAL_APPROACH = 'total_approach';
export const INDIVIDUAL_APPROACH = 'individual_approach';
export const CAP_TABLE_SELECTION_TITLE = 'Cap Table Selection';
export const CAP_TABLE_SELECTION_ALIAS = 'cap_table';
export const DATA_UNAVAILABLE = 'Data Unavailable';
export const DEFAULT_ZERO_VALUE = '0.00';
export const FUND_OWNERSHIP_LOCKED_MESSAGE
  = 'This Valuation table version is used in a Final Valuation, any changes saved could affect that valuation.';
export const GET_APPROACHES_ERROR = 'An error occurred while retrieving the valuation approaches';
export const IS_ACTIVE = 'Active';
export const MARKET_ADJUSTMENT_REQUIREMENT
  = 'This feature requires at least one Public Comps valuation approach and saving the current backsolve first.';
export const MISSING_SECURITIES = 'Missing Cap Table Securities';
export const MISSING_SECURITIES_MESSAGE = 'Please add securities to your selected cap table';
export const MULTIPLE_PREMIUM_DISCOUNT_ALIAS = 'multiple_premium_discount';
export const SELECTION_A = 'Selection A';
export const SELECTION_B = 'Selection B';

export const VALUATION_NOTES_ALIAS = 'valuation_notes';

// Labels

export const VALUATIONS_VERSION_LABEL = 'Version';
export const VALUATIONS_PERCENTILE_LABEL = 'Percentile';
export const VALUATIONS_OTHER_LABEL = 'Other';

// Messages

export const VALUATIONS_SSV_NO_SECURITIES_MESSAGE = 'You can not save a SSV approach with no securities';

// Currency

export const VALUATIONS_DEFAULT_CURRENCY = 'USD';
export const VALUATIONS_DEFAULT_CURRENCY_SYMBOL = '$';

// Market Adjustment

export const VALUATIONS_MARKET_ADJUSTMENT = 'Market Adjustment';
export const VALUATIONS_MARKET_ADJUSTMENT_ADD = `Add ${VALUATIONS_MARKET_ADJUSTMENT}`;
export const VALUATIONS_MARKET_ADJUSTMENT_REMOVE = `Remove ${VALUATIONS_MARKET_ADJUSTMENT}`;

// Multiple Premium Discount

export const VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT = 'Multiple Premium/(Discount)';
export const VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT_ADD = `Add ${VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT}`;
export const VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT_REMOVE = `Remove ${VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT}`;

// Regex and Patterns

export const VALUATIONS_PERCENTILE_PATTERN = [/(st)/, /(nd)/, /(rd)/, /(th)/];
export const VALUATIONS_TRANSACTION_ID_VALIDATION_REGEX = /^IQTR\d{9,12}$/;

// Value Cards
export const EQUITY_VALUE_CARD_TITLE = 'Equity Value';
export const FIRM_TOTAL_CARD_TITLE = 'Firm Total';
export const LIVE_VALUE_CARDS = [VALUATIONS_SLUG];

import { HIDDEN_ROW_NUMBER } from '../../../../common/constants/calibration';
import { getNumberValue, getObjectValue } from '../../../../utilities';
import { transposeFinancials } from '../../../ValuationsAllocation/util';

export const createNewPerformance = idCalibration => ({
  name: '_',
  enterprise_value: '',
  ltm_revenue: '',
  ltm_revenue_multiple: '',
  ltm_revenue_growth: '',
  ntm_revenue: '',
  ntm_revenue_multiple: '',
  ntm_revenue_growth: '',
  ltm_ebitda: '',
  ltm_ebitda_multiple: '',
  ntm_ebitda: '',
  ntm_ebitda_multiple: '',
  gross_margin: '',
  ebitda_margin: '',
  calibration: idCalibration,
});

export const decimalResults = (valueString, decimalIndex, decimalPlaces) => {
  if (decimalIndex !== -1) {
    const first10Decimals = valueString.substring(0, decimalIndex + decimalPlaces);
    return parseFloat(first10Decimals);
  }
  return parseFloat(valueString) ?? 0;
};

const DEFAULT_DECIMAL_PLACES = 11;
const SPECIFIC_FIELDS_DECIMAL_PLACES = 3;
const SPECIFIC_FIELDS = ['ltm_revenue', 'ltm_ebitda', 'ntm_revenue', 'ntm_ebitda'];

export const PerformanceCalculator = ({
  numCols,
  numRows,
  performanceMetricsKeys,
  performanceKeys,
  idCalibration,
  calibration,
}) => {
  for (let colIndex = 0; colIndex < numCols; colIndex++) {
    const newPerformance = createNewPerformance(idCalibration);

    for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
      const key = Object.keys(performanceMetricsKeys[rowIndex])[colIndex];
      const decimalValue = performanceMetricsKeys[rowIndex][key].value;
      const field = performanceKeys[rowIndex];

      const decimalPlaces = SPECIFIC_FIELDS.includes(field) ? SPECIFIC_FIELDS_DECIMAL_PLACES : DEFAULT_DECIMAL_PLACES;

      if (!isNaN(decimalValue)) {
        const valueString = decimalValue.toString();
        const decimalIndex = valueString.indexOf('.');

        newPerformance[field] = decimalResults(valueString, decimalIndex, decimalPlaces);
      } else {
        newPerformance.name = decimalValue;
      }
    }
    const { name } = newPerformance;
    if ((typeof name === 'number' && name !== 0) || typeof name === 'string') {
      calibration.performances.push(newPerformance);
    }
  }
  return calibration.performances;
};

// metricsUtils.js
export const updateCompsWithMetrics = (compsWithMetrics, correspondingCompsWithMetrics) => {
  let updatedCompsWithMetrics = [...compsWithMetrics];

  updatedCompsWithMetrics = updatedCompsWithMetrics.map(compWithMetric => {
    const correspondingComp = correspondingCompsWithMetrics.find(comp => comp.cap_iq_id === compWithMetric.cap_iq_id);

    if (correspondingComp && correspondingComp.custom_date_performance_metrics.length > 0) {
      const correspondingMetric = correspondingComp.custom_date_performance_metrics[0];

      if (compWithMetric.custom_date_performance_metrics.length > 0) {
        // eslint-disable-next-line no-param-reassign
        compWithMetric.custom_date_performance_metrics[0].id = correspondingMetric.id;
      }
    }

    return compWithMetric;
  });

  return updatedCompsWithMetrics;
};

export const determineValue = (current, property, calculateValue, auxProperty) => {
  if (calculateValue) {
    return calculateValue(current);
  }

  const primaryValue = current?.[property];
  const auxiliaryValue = auxProperty ? current?.[auxProperty] : undefined;

  if (typeof primaryValue === 'number' || typeof auxiliaryValue === 'number') {
    return getNumberValue(primaryValue ?? auxiliaryValue);
  }

  return primaryValue ?? auxiliaryValue;
};

export const getFinancials = (financials_version, financialStatementsList) => {
  const financial = financials_version;
  const selectedFinancialVersion = financialStatementsList.filter(tab => tab.id === Number(financial));
  return transposeFinancials(getObjectValue(selectedFinancialVersion[0]));
};

export const getFinalValueByAlias = (value, gridType, calibrationData, alias) => {
  let result = value;
  if (value === '' || value === 0) {
    if (gridType === 'number') {
      result = calibrationData[alias] ?? 0;
    } else {
      result = calibrationData[alias] ?? '';
    }
  }

  return result;
};

export const validateHiddenRows = (rowNumber, style) => {
  let resultStyle = style;
  if (rowNumber === HIDDEN_ROW_NUMBER) {
    resultStyle = { visibility: 'hidden', border: 'none' };
  }
  return resultStyle;
};

export const getPlusDebtAndLessCashInputsTable = (
  isDisabled,
  isLastColumn,
  gridType,
  calibrationData,
  readOnly,
  value,
  valueType
) => {
  let newGridType = gridType;
  let newReadOnly = readOnly;
  let newValue = value;

  if (isLastColumn) {
    newGridType = 'number';
  }
  if (Number(calibrationData.financials_version) === 0) {
    if (isDisabled === false) {
      newReadOnly = false;
    }

    newValue = valueType ? calibrationData.less_debt_inputs : calibrationData.plus_cash_inputs;
  }

  return {
    gridType: newGridType,
    readOnly: newReadOnly,
    value: newValue,
  };
};

export const findSelectedCalibrationApproach = (public_comps_approach_calibration, approach, approaches) => {
  let newApproach = approach;
  if (public_comps_approach_calibration !== undefined) {
    const selectedApproach = approaches?.find(x => x.id === Number(public_comps_approach_calibration)) || {
      approach_type: '',
      name: '',
    };

    if (selectedApproach !== undefined) {
      newApproach = selectedApproach;
    }
  }

  return newApproach;
};

export const findSelectedCalibrationFinancial = (CalibrationData, financialStatementsList, financials) => {
  const version = CalibrationData.financials_version;
  let newFinancials = financials;
  const selectedFinancial = financialStatementsList?.find(x => x.id === Number(version) && version !== undefined) || {
    approach_type: '',
    name: '',
  };

  if (selectedFinancial.name !== '') {
    newFinancials = transposeFinancials(getObjectValue(selectedFinancial));
  } else {
    newFinancials.ltm_revenue = CalibrationData.ltm_revenue_inputs ?? 0;
    newFinancials.ntm_revenue = CalibrationData.ntm_revenue_inputs ?? 0;
    newFinancials.ltm_ebitda = CalibrationData.ltm_ebitda_inputs ?? 0;
    newFinancials.ntm_ebitda = CalibrationData.ntm_ebitda_inputs ?? 0;
  }

  return {
    financials: newFinancials,
    version,
  };
};

export const getPerformanceMetricsKeys = (columnsPerformance, prefix) =>
  columnsPerformance.flatMap(dict =>
    Object.keys(dict).filter(key => key.startsWith(prefix) && !key.includes('current_company'))
  );

export const getCalibratedKeys = (keys, panelIdNew, suffix) =>
  keys.filter(key => key.includes(suffix)).map(key => `calibration_performance_${panelIdNew}.${key}`);

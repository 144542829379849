export const PERFORMANCE_METRICS_SPREADSHEET_COLUMN_KEY_PREFIX = 'performance_metrics';
export const PERFORMANCE_METRICS_SPREADSHEET_COLUMN_NAME = 'Column';
export const PERFORMANCE_METRICS_SPREADSHEET_COLUMN_NAME_PLURAL = 'Columns';
export const PERFORMANCE_METRICS_SPREADSHEET_SLUG = 'gpc-performance-metrics';
export const PERFORMANCE_METRICS_SPREADSHEET_TABLE = `${PERFORMANCE_METRICS_SPREADSHEET_SLUG}-table`;
export const PERFORMANCE_METRICS_SPREADSHEET_HEADER_CLASSNAME = `${PERFORMANCE_METRICS_SPREADSHEET_TABLE}-header`;
export const PERFORMANCE_METRICS_SPREADSHEET_RANK_VOLATILITY_HEADER_CLASSNAME = `${PERFORMANCE_METRICS_SPREADSHEET_TABLE}-rank-volatility-header`;
export const ONE_YEAR_VOLATILITY_PERCENTILES_SELECTION = 'one_year_volatility_percentile_selection';
export const TWO_YEAR_VOLATILITY_PERCENTILES_SELECTION = 'two_year_volatility_percentile_selection';
export const FIVE_YEAR_VOLATILITY_PERCENTILES_SELECTION = 'five_year_volatility_percentile_selection';
export const ONE_YEAR_VOLATILITY = 'one_year_volatility';
export const TWO_YEAR_VOLATILITY = 'two_year_volatility';
export const FIVE_YEAR_VOLATILITY = 'five_year_volatility';
export const PERFORMANCE_METRICS_SPREADSHEET_TITLE = 'Performance Metrics';
export const COMPS_DATA_NEED_REFRESH = 'You may need to Refresh Data to obtain this values';
export const TANGIBLE_BOOK_COLUMN_LEGEND = 'I';
export const NET_INCOME_COLUMN_LEGEND = 'J';
export const TANGIBLE_BOOK_VALUE_DESCRIPTION = 'Book value of common equity, net of goodwill and intangible assets';
export const NET_INCOME_DESCRIPTION
  = 'Net income, adjusted for preferred dividends, discontinued operations, extraordinary items, and accounting changes';

export const VOLATILITY_YEARS = {
  11: ONE_YEAR_VOLATILITY_PERCENTILES_SELECTION,
  12: TWO_YEAR_VOLATILITY_PERCENTILES_SELECTION,
  13: FIVE_YEAR_VOLATILITY_PERCENTILES_SELECTION,
};

export const VOLATILITY_YEARS_VALUES = [
  ONE_YEAR_VOLATILITY_PERCENTILES_SELECTION,
  TWO_YEAR_VOLATILITY_PERCENTILES_SELECTION,
  FIVE_YEAR_VOLATILITY_PERCENTILES_SELECTION,
];

export const VOLATILITY_YEARS_COMPANY_CELL = {
  11: ONE_YEAR_VOLATILITY,
  12: TWO_YEAR_VOLATILITY,
  13: FIVE_YEAR_VOLATILITY,
};

// Multiple options constants
export const PERCENTILE_25 = '25th Percentile';
export const PERCENTILE_75 = '75th Percentile';
export const SPECIFIED_LABEL = 'Specified';
export const MEDIAN_LABEL = 'Median';
export const MEAN_LABEL = 'Mean';

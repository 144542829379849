import { BalanceSheetAllow, FinancialStatementPeriodFullWrite, IncomeStatementAllow } from 'api';
import { LAST_TWELVE_MONTHS, NEXT_TWELVE_MONTHS } from 'common/constants/financials';
import {
  COMPANY,
  ENTERPRISE_VALUE_COLUMN_LEGEND,
  FIRST_FORWARD_ID,
  FIRST_NON_FORWARD_ID,
  FORWARD_PRICE_TO_EARNINGS_PERIOD,
  FORWARD_PRICE_TO_TANGIBLE_PERIOD,
  LTM_EBITDA,
  ltmColumnLetters,
  MAIN_COLUMNS_IDS,
  MARKET_CAP_COLUMN_LEGEND,
  MEAN_ALIAS,
  MEDIAN_ALIAS,
  NTM_EBITDA,
  ntmColumnLetters,
  PERCENTILE_25,
  PERCENTILE_75,
  PERCENTILE_SELECTION_A_ALIAS,
  PERCENTILE_SELECTION_B_ALIAS,
  PRICE_TO_EARNINGS_PERIOD,
  PRICE_TO_TANGIBLE_PERIOD,
  SECOND_FORWARD_ID,
  SECOND_NON_FORWARD_ID,
  SELECTED_MULTIPLE,
  SELECTED_MULTIPLE_VALUE,
  SELECTION,
  SEVENTY_FIFTH_ALIAS,
  SPECIFIED_LABEL,
  TWENTY_FIFTH_ALIAS,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import { percentileOptions } from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/getRowConfig';
import { getBooleanValue, getNumberValue } from 'utilities';
import { legendCalendarYearPropMap, legendColumnIdMap } from './auxMaps';
import { getFinancialPeriodValue } from './utilities';
import {
  GPCAfterCellChanged,
  GPCChange,
  ReverseParsedValuationApproachGPC,
  StringMapperObject,
  ValuationApproachGPCCell,
  ValuationApproachGPCCells,
} from '../../types';

const getChangedColumnIndicator = ({
  cell,
  isEvaluatingEquityValue,
}: {
  cell: ValuationApproachGPCCell;
  isEvaluatingEquityValue: boolean;
}) => {
  if (isEvaluatingEquityValue) {
    return legendColumnIdMap[cell.alias];
  }
  return cell.alias === LAST_TWELVE_MONTHS ? ltmColumnLetters : ntmColumnLetters;
};

const getComparisonPropToRead = ({
  legend,
  isEvaluatingEquityValue,
}: {
  legend: string;
  isEvaluatingEquityValue: boolean;
}) => {
  if (!isEvaluatingEquityValue) {
    return ['E', 'G'].includes(legend) ? 'revenue' : 'ebitda';
  }
  return legendCalendarYearPropMap[legend];
};

const afterPeriodSelectorCellChanged = ({
  valuations_approach_gpc,
  cell,
  financialsPeriods,
  value,
  changes,
  cells,
}: {
  valuations_approach_gpc: ReverseParsedValuationApproachGPC;
  cell: ValuationApproachGPCCell;
  financialsPeriods: Partial<FinancialStatementPeriodFullWrite>[];
  value: string | number | null | undefined;
  changes: GPCChange[];
  cells: ValuationApproachGPCCells;
}) => {
  let comparisonRow = 3;
  const { is_evaluating_equity_value, gpc_comparison: comparisons } = valuations_approach_gpc;
  const isEvaluatingEquityValue = getBooleanValue(is_evaluating_equity_value);
  const columnLettersToParse = getChangedColumnIndicator({ cell, isEvaluatingEquityValue });
  const numeratorColumnLegend = isEvaluatingEquityValue ? MARKET_CAP_COLUMN_LEGEND : ENTERPRISE_VALUE_COLUMN_LEGEND;
  (comparisons ?? []).forEach(comparison => {
    if (comparison.calendar_years_financials) {
      (columnLettersToParse as string[]).forEach(legend => {
        const type = getComparisonPropToRead({ legend, isEvaluatingEquityValue });
        let financialPeriodValue = getFinancialPeriodValue({
          comparison,
          periods: financialsPeriods,
          periodId: value,
          type,
          isEvaluatingEquityValue,
        });

        financialPeriodValue = getNumberValue(financialPeriodValue as string);
        const expr = !financialPeriodValue ? 0 : `=${numeratorColumnLegend}${comparisonRow}/${financialPeriodValue}`;

        changes.push({
          cell: cells[legend + comparisonRow],
          value: expr,
        });
      });

      comparisonRow += 1;
    }
  });

  const companyCells = Object.values(cells)
    .filter(cell => cell.alias === COMPANY && MAIN_COLUMNS_IDS.includes(cell.columnId as string))
    .filter(({ columnLegend }) => columnLettersToParse.includes(columnLegend as string));

  const selectedPeriod = financialsPeriods.find(p => p?.id?.toString() === value?.toString());
  const {
    ebitda = '0',
    total_revenue: revenue = '0',
    net_income: netIncome = '0',
  } = (selectedPeriod?.income_statement as IncomeStatementAllow) ?? {};
  const { tangible_book_value: tangibleBookValue = '0' } = selectedPeriod?.balance_sheet as BalanceSheetAllow;
  const firstAndThirdColumnFinancialProp = isEvaluatingEquityValue ? tangibleBookValue : revenue;
  const secondAndFourthColumnFinancialProp = isEvaluatingEquityValue ? netIncome : ebitda;
  const newPeriodValue: StringMapperObject = {
    [FIRST_NON_FORWARD_ID]: firstAndThirdColumnFinancialProp as string,
    [FIRST_FORWARD_ID]: firstAndThirdColumnFinancialProp as string,
    [SECOND_NON_FORWARD_ID]: secondAndFourthColumnFinancialProp as string,
    [SECOND_FORWARD_ID]: secondAndFourthColumnFinancialProp as string,
  };

  companyCells.forEach(cell => {
    changes.push({
      cell,
      value: newPeriodValue[cell.columnId as string] as string,
    });
  });
};

export function selectionChange(
  cells: ValuationApproachGPCCells,
  changes: GPCChange[],
  cell: ValuationApproachGPCCell,
  value: string | number | null | undefined,
  useMultiplePremiumDiscount = false,
  selectionUpdates: { [key: string]: number } = {}
) {
  let selectionA = percentileOptions[(cells.TITLES_percentile_selection_a.value as number) - 1];
  let selectionB = percentileOptions[(cells.TITLES_percentile_selection_b.value as number) - 1];
  if ('percentile_selection_a' in selectionUpdates) {
    selectionA = percentileOptions[selectionUpdates.percentile_selection_a - 1];
  }
  if ('percentile_selection_b' in selectionUpdates) {
    selectionB = percentileOptions[selectionUpdates.percentile_selection_b - 1];
  }
  const selectionMap = {
    Mean: MEAN_ALIAS,
    Median: MEDIAN_ALIAS,
    [SPECIFIED_LABEL]: SELECTED_MULTIPLE,
    [PERCENTILE_75]: SEVENTY_FIFTH_ALIAS,
    [PERCENTILE_25]: TWENTY_FIFTH_ALIAS,
    [selectionA?.label]: PERCENTILE_SELECTION_A_ALIAS,
    [selectionB?.label]: PERCENTILE_SELECTION_B_ALIAS,
  };

  const appliedMultipleCell = Object.values(cells).find(
    cellParam => cellParam.alias === SELECTED_MULTIPLE_VALUE && cellParam.columnId === cell.columnId
  ) as ValuationApproachGPCCell;
  const mpdExpr = useMultiplePremiumDiscount ? ` * (1 + ${cell.columnId}_MPD)` : '';
  changes.push({
    cell: appliedMultipleCell,
    value: `=${cell.columnId}_${selectionMap[value as string]}${mpdExpr}`,
  });
}

export function percentileChange(
  cell: ValuationApproachGPCCell,
  value: any,
  cells: ValuationApproachGPCCells,
  changes: GPCChange[],
  useMultiplePremiumDiscount = false
) {
  const previousSelectionOption = percentileOptions[Number(cell.value) - 1]?.label;
  // eslint-disable-next-line no-case-declarations
  const currentOption = percentileOptions[value - 1]?.label;
  const selectionUpdates = {
    [cell.alias]: value,
  };
  Object.values(cells)
    .filter(selectionCell => selectionCell.alias === SELECTION && selectionCell.value === previousSelectionOption)
    .forEach(selectionCell => {
      changes.push({
        cell: selectionCell,
        value: currentOption,
      });

      selectionChange(cells, changes, selectionCell, currentOption, useMultiplePremiumDiscount, selectionUpdates);
    });
}

const afterCellChanged: GPCAfterCellChanged = (changes, cells, rowConfig, tableData) => {
  const { approach, financialsPeriods } = tableData;
  const { valuations_approach_gpc } = approach;
  const { use_multiple_premium_discount, is_evaluating_equity_value: isEvaluatingEquityValue }
    = valuations_approach_gpc;

  changes.forEach(change => {
    const { cell, value } = change;

    switch (cell.alias) {
      // if the alias is to the percentile header, then we need to update the selection cells
      // if the alias if for the selection cells, then we need to update the formula for the applied multiple cell

      case PERCENTILE_SELECTION_A_ALIAS:
      case PERCENTILE_SELECTION_B_ALIAS:
        percentileChange(cell, value, cells, changes, use_multiple_premium_discount);
        break;

      case SELECTION:
        selectionChange(cells, changes, cell, value, use_multiple_premium_discount);
        break;

      case COMPANY:
        // need to adjust these two conditions
        if (cell.columnId === SECOND_NON_FORWARD_ID && !isEvaluatingEquityValue) {
          valuations_approach_gpc.use_adjusted_LTM_ebitda
            = Number(change.value) !== Number(cell?.sheet?.tableData.financials[LTM_EBITDA] ?? 0);
        }
        if (cell.columnId === SECOND_FORWARD_ID && !isEvaluatingEquityValue) {
          valuations_approach_gpc.use_adjusted_NTM_ebitda
            = Number(change.value) !== Number(cell?.sheet?.tableData.financials[NTM_EBITDA] ?? 0);
        }
        break;

      case MEDIAN_ALIAS:
      case MEAN_ALIAS:
      case SELECTED_MULTIPLE_VALUE:
        changes.push({
          cell,
          value: cell.expr,
        });
        break;
      case LAST_TWELVE_MONTHS:
      case NEXT_TWELVE_MONTHS:
      case PRICE_TO_EARNINGS_PERIOD:
      case PRICE_TO_TANGIBLE_PERIOD:
      case FORWARD_PRICE_TO_EARNINGS_PERIOD:
      case FORWARD_PRICE_TO_TANGIBLE_PERIOD:
        afterPeriodSelectorCellChanged({
          valuations_approach_gpc,
          cell,
          financialsPeriods,
          value,
          changes,
          cells,
        });
        break;
      default:
        break;
    }
  });

  return changes;
};

export default afterCellChanged;

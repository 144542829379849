import React from 'react';
import { Divider, Paper } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import { isUndefined } from 'lodash';
import { ITheme } from 'theme/types';

const useStyles = makeStyles((theme: ITheme) => ({
  infoPaper: {
    marginRight: '1em',
    padding: '1em',
    textAlign: 'center',
    minWidth: '200px',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  titleSkeleton: {
    width: '80%',
    margin: 'auto',
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    height: 2,
    width: '20%',
    margin: `${theme.spacing()}px auto`,
  },
  body: {
    fontFamily: theme.typography.mainFont,
    fontSize: '1.125em',
  },
  bodySkeleton: {
    width: '50%',
    margin: 'auto',
  },
}));

type InfoCardProps = {
  title: string;
  body: string;
};

const InfoCard = ({ title, body }: InfoCardProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.infoPaper}>
      {title && !isUndefined(body) ? (
        <small className={classes.title}>{title}</small>
      ) : (
        <Skeleton className={classes.titleSkeleton} variant="text" />
      )}
      <Divider className={classes.divider} />
      {!isUndefined(body) ? (
        <p className={classes.body}>{body ?? '-'}</p>
      ) : (
        <Skeleton className={classes.bodySkeleton} variant="text" />
      )}
    </Paper>
  );
};

export default InfoCard;

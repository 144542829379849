import { EBITDA_ALIAS, TOTAL_REVENUE_ALIAS } from 'common/constants/financials';
import {
  ENTERPRISE_VALUE_COLUMN_ID,
  FIRST_FORWARD_ID,
  FIRST_NON_FORWARD_ID,
  FORWARD_PRICE_TO_EARNINGS_PERIOD,
  FORWARD_PRICE_TO_TANGIBLE_PERIOD,
  MARKET_CAP_ID,
  NET_INCOME,
  PRICE_TO_EARNINGS_PERIOD,
  PRICE_TO_TANGIBLE_PERIOD,
  SECOND_FORWARD_ID,
  SECOND_NON_FORWARD_ID,
  TANGIBLE_BOOK_VALUE,
  TICKER_SYMBOL_ID,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import {
  ColumnId,
  MPDColumnEnabled,
  StringMapperObject,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/types';
import { FinancialsTransposed } from 'pages/ValuationsAllocation/util';
import { STOCK_PRICE_KEY } from './constants';

type FinancialsKey = keyof FinancialsTransposed;

// this will be used to map prop names depending on the mode (enterprise or equity value)
export const financialsKeyMap: Record<string, Record<string, FinancialsKey>> = {
  [FIRST_NON_FORWARD_ID]: {
    true: TANGIBLE_BOOK_VALUE,
    false: 'ltm_revenue',
  },
  [SECOND_NON_FORWARD_ID]: {
    true: 'ltm_net_income',
    false: 'ltm_ebitda',
  },
  [FIRST_FORWARD_ID]: {
    true: 'tangible_book_value',
    false: 'ntm_revenue',
  },
  [SECOND_FORWARD_ID]: {
    true: 'ntm_net_income',
    false: 'ntm_ebitda',
  },
};

type TitleToFspMap = {
  [key: string]: string;
};
export const titleToFspPropMap: TitleToFspMap = {
  Revenue: TOTAL_REVENUE_ALIAS,
  EBITDA: EBITDA_ALIAS,
  'Price-to-Tangible Book': TANGIBLE_BOOK_VALUE,
  'Price-to-Earnings': NET_INCOME,
};

export const columnIdToPropertyMap: { [key in ColumnId]: MPDColumnEnabled } = {
  [FIRST_NON_FORWARD_ID]: 'first_non_forward_multiple_premium_discount',
  [SECOND_NON_FORWARD_ID]: 'second_non_forward_multiple_premium_discount',
  [FIRST_FORWARD_ID]: 'first_forward_multiple_premium_discount',
  [SECOND_FORWARD_ID]: 'second_forward_multiple_premium_discount',
};

export const columnIdToComparisonProp: {
  [key in ColumnId]: 'ltm_revenue' | 'ltm_ebitda' | 'ntm_revenue' | 'ntm_ebitda';
} = {
  [FIRST_NON_FORWARD_ID]: 'ltm_revenue',
  [SECOND_NON_FORWARD_ID]: 'ltm_ebitda',
  [FIRST_FORWARD_ID]: 'ntm_revenue',
  [SECOND_FORWARD_ID]: 'ntm_ebitda',
};

export const columnMetricToComparisonProp: {
  [key in ColumnId]: 'first_metric' | 'second_metric' | 'first_forward_metric' | 'second_forward_metric';
} = {
  [FIRST_NON_FORWARD_ID]: 'first_metric',
  [SECOND_NON_FORWARD_ID]: 'second_metric',
  [FIRST_FORWARD_ID]: 'first_forward_metric',
  [SECOND_FORWARD_ID]: 'second_forward_metric',
};

export const columndIdToCYFinancialProp: { [key: string]: { [key: string]: string } } = {
  [FIRST_NON_FORWARD_ID]: {
    true: 'tangible',
    false: 'revenue',
  },
  [SECOND_NON_FORWARD_ID]: {
    true: 'price',
    false: 'ebitda',
  },
  [FIRST_FORWARD_ID]: {
    true: 'tangible',
    false: 'revenue',
  },
  [SECOND_FORWARD_ID]: {
    true: 'price',
    false: 'ebitda',
  },
};

export const legendKeyMap: { [key: string]: string } = {
  A: TICKER_SYMBOL_ID,
  B: STOCK_PRICE_KEY,
  C: MARKET_CAP_ID,
  D: ENTERPRISE_VALUE_COLUMN_ID,
};

export const legendColumnIdMap: StringMapperObject = {
  [PRICE_TO_TANGIBLE_PERIOD]: ['E'],
  [PRICE_TO_EARNINGS_PERIOD]: ['F'],
  [FORWARD_PRICE_TO_TANGIBLE_PERIOD]: ['G'],
  [FORWARD_PRICE_TO_EARNINGS_PERIOD]: ['H'],
};

export const legendCalendarYearPropMap: StringMapperObject = {
  E: 'tangible',
  F: 'price',
  G: 'tangible',
  H: 'price',
};

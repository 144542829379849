import {
  EBITDA_MARGIN_ALIAS,
  LTM_EBITDA_ALIAS,
  LTM_REVENUE_ALIAS,
  NET_INCOME_ALIAS,
  NTM_EBITDA_ALIAS,
  NTM_REVENUE_ALIAS,
  TANGIBLE_BOOK_VALUE_ALIAS,
} from 'common/constants/financials';
import { NUMBER, PERCENTAGE } from 'common/constants/gridType';
import { largeCurrencyFormat, oneDecimalPercentFormatValidateFloatTrue } from 'common/formats/formats';
import {
  PERFORMANCE_METRICS_SPREADSHEET_EBITDA_MARGIN_TITLE,
  PERFORMANCE_METRICS_SPREADSHEET_GROSS_MARGIN_TITLE,
  PERFORMANCE_METRICS_SPREADSHEET_LTM_EBITDA_TITLE,
  PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_GROWTH_TITLE,
  PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_TITLE,
  PERFORMANCE_METRICS_SPREADSHEET_NET_INCOME_TITLE,
  PERFORMANCE_METRICS_SPREADSHEET_NTM_EBITDA_TITLE,
  PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_GROWTH_TITLE,
  PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_TITLE,
  PERFORMANCE_METRICS_SPREADSHEET_TANGIBLE_BOOK_TITLE,
} from '../../../PerformanceMetrics/common/constants/performanceMetrics/sheetTitles';

export const LTM_GROSS_MARGIN_ALIAS = 'ltm_gross_margin';
export const LTM_EBITDA_MARGIN_ALIAS = 'ltm_ebitda_margin';

export const METRIC_TITLES_AND_ALIASES = {
  [PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_TITLE]: {
    alias: LTM_REVENUE_ALIAS,
    formatConfig: { gridType: NUMBER, format: largeCurrencyFormat },
    label: 'LTM Revenue',
    selectionEnabled: false,
  },
  [PERFORMANCE_METRICS_SPREADSHEET_LTM_REVENUE_GROWTH_TITLE]: {
    alias: 'ltm_revenue_growth',
    formatConfig: { gridType: PERCENTAGE, format: oneDecimalPercentFormatValidateFloatTrue },
    label: 'LTM Revenue Growth',
    selectionEnabled: true,
  },
  [PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_TITLE]: {
    alias: NTM_REVENUE_ALIAS,
    formatConfig: { gridType: NUMBER, format: largeCurrencyFormat },
    label: 'NTM Revenue',
    selectionEnabled: false,
  },
  [PERFORMANCE_METRICS_SPREADSHEET_NTM_REVENUE_GROWTH_TITLE]: {
    alias: 'ntm_revenue_growth',
    formatConfig: { gridType: PERCENTAGE, format: oneDecimalPercentFormatValidateFloatTrue },
    label: 'NTM Revenue Growth',
    selectionEnabled: true,
  },
  [PERFORMANCE_METRICS_SPREADSHEET_LTM_EBITDA_TITLE]: {
    alias: LTM_EBITDA_ALIAS,
    formatConfig: { gridType: NUMBER, format: largeCurrencyFormat },
    label: 'LTM EBITDA',
    selectionEnabled: false,
  },
  [PERFORMANCE_METRICS_SPREADSHEET_NTM_EBITDA_TITLE]: {
    alias: NTM_EBITDA_ALIAS,
    formatConfig: { gridType: NUMBER, format: largeCurrencyFormat },
    label: 'NTM EBITDA',
    selectionEnabled: false,
  },
  [PERFORMANCE_METRICS_SPREADSHEET_GROSS_MARGIN_TITLE]: {
    alias: LTM_GROSS_MARGIN_ALIAS,
    formatConfig: { gridType: PERCENTAGE, format: oneDecimalPercentFormatValidateFloatTrue },
    label: 'Gross Margin',
    selectionEnabled: false,
  },
  [PERFORMANCE_METRICS_SPREADSHEET_EBITDA_MARGIN_TITLE]: {
    alias: EBITDA_MARGIN_ALIAS,
    formatConfig: { gridType: PERCENTAGE, format: oneDecimalPercentFormatValidateFloatTrue },
    label: 'EBITDA Margin',
    selectionEnabled: false,
  },
  [PERFORMANCE_METRICS_SPREADSHEET_TANGIBLE_BOOK_TITLE]: {
    alias: TANGIBLE_BOOK_VALUE_ALIAS,
    formatConfig: { gridType: NUMBER, format: largeCurrencyFormat },
    label: 'Tangible Book Value',
    selectionEnabled: false,
  },
  [PERFORMANCE_METRICS_SPREADSHEET_NET_INCOME_TITLE]: {
    alias: NET_INCOME_ALIAS,
    formatConfig: { gridType: NUMBER, format: largeCurrencyFormat },
    label: 'Net Income',
    selectionEnabled: false,
  },
};

export const ADJUSTMENT_METRIC_OPTIONS = Object.entries(METRIC_TITLES_AND_ALIASES)
  .filter(([, value]) => value.selectionEnabled)
  .map(([, { label, alias, formatConfig }]) => ({
    label,
    alias,
    value: label,
    formatConfig,
  }));

export const READ_ONLY_CELLS = ['B7', 'C2', 'C6'];
export const TOTALS_ROW_CELLS = ['A8', 'B8', 'C8'];
export const CELLS_TO_FORMAT = ['B4', 'B5', 'B6', 'C4', 'C5', 'C6', 'C7', 'C8'];

const VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT = 'Multiple Premium Discount';
const CUSTOM_DATE = 'Custom Date';
const MEASUREMENT_DATE = 'Measurement Date';
export const MULTIPLE_PREMIUM_DISCOUNT_CUSTOM_DATE = `${VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT} | ${CUSTOM_DATE}`;
export const MULTIPLE_PREMIUM_DISCOUNT_MEASUREMENT_DATE = `${VALUATIONS_MULTIPLE_PREMIUM_DISCOUNT} | ${MEASUREMENT_DATE}`;

export const COMPANY_PERFORMANCE_METRIC_CUSTOM_KEY = 'companyPerformanceMetric';

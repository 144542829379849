import {
  LTM_EBITDA_ID,
  LTM_EBITDA_MULTIPLE_ID,
  LTM_REVENUE_ID,
  LTM_REVENUE_MULTIPLE_ID,
  NTM_EBITDA_ID,
  NTM_EBITDA_MULTIPLE_ID,
  NTM_REVENUE_ID,
  NTM_REVENUE_MULTIPLE_ID,
} from 'pages/Valuations/approaches/GuidelineTransactions/config/constants';
import { FinancialsTransposed } from 'pages/ValuationsAllocation/util';

type FinancialsKey = keyof FinancialsTransposed;

export const FINANCIALS_COMPANY_INFO_KEYS: Record<string, FinancialsKey> = {
  [LTM_REVENUE_MULTIPLE_ID]: LTM_REVENUE_ID,
  [LTM_EBITDA_MULTIPLE_ID]: LTM_EBITDA_ID,
  [NTM_REVENUE_MULTIPLE_ID]: NTM_REVENUE_ID,
  [NTM_EBITDA_MULTIPLE_ID]: NTM_EBITDA_ID,
};

/* eslint-disable import/prefer-default-export */
import {
  ADJUSTED_EBITDA_ROW_NUMBER,
  EBITDA_ROW_NUMBER,
  GROSS_PROFIT_ROW_NUMBER,
  NET_INCOME_ROW_NUMBER,
  OPERATING_EXPENSES_ROW_NUMBER,
  TOTAL_REVENUE_ROW_NUMBER,
  TOTAL_SALES_COST_ROW_NUMBER,
} from './incomeStatement';
import { NOT_APPLICABLE } from '../general';

export const PERFORMANCE_METRICS_TITLE = 'Performance Metrics';

export const REVENUE_GROWTH_RATE_TITLE = 'Revenue Growth Rate';
export const REVENUE_GROWTH_RATE_ALIAS = 'revenue_growth_rate';
export const REVENUE_GROWTH_RATE_ROW_NUMBER = 4;

// Revenue Growth Rate = (Current Total Revenue / Previous Total Revenue) - 1
export const REVENUE_GROWTH_RATE_EXPR = `=IF(OR(incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER} == 0, incomeStatement.#${TOTAL_REVENUE_ROW_NUMBER} == 0), "${NOT_APPLICABLE}", (incomeStatement.#${TOTAL_REVENUE_ROW_NUMBER} / incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER}) - 1)`;

export const TOTAL_COST_OF_SALES_TITLE = 'Cost of Sales %';
export const TOTAL_COST_OF_SALES_ALIAS = 'total_sales_cost';
export const TOTAL_COST_OF_SALES_ROW_NUMBER = 5;
export const TOTAL_COST_OF_SALES_EXPR = `=IF(incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER} != 0, incomeStatement.@${TOTAL_SALES_COST_ROW_NUMBER} / incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER}, "${NOT_APPLICABLE}")`;

export const GROSS_MARGIN_TITLE = 'Gross Margin';
export const GROSS_MARGIN_ALIAS = 'gross_margin';
export const GROSS_MARGIN_ROW_NUMBER = 6;
export const GROSS_MARGIN_EXPR = `=IF(incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER} != 0, incomeStatement.@${GROSS_PROFIT_ROW_NUMBER} / incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER}, "${NOT_APPLICABLE}")`;

export const OPERATING_EXPENSES_SALES_TITLE = 'Operating Expense % of Sales';
export const OPERATING_EXPENSES_SALES_ALIAS = 'operating_expenses';
export const OPERATING_EXPENSES_SALES_ROW_NUMBER = 7;
export const OPERATING_EXPENSES_SALES_EXPR = `=IF(incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER} != 0, incomeStatement.@${OPERATING_EXPENSES_ROW_NUMBER} / incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER}, "${NOT_APPLICABLE}")`;

export const EBITDA_MARGIN_TITLE = 'EBITDA Margin';
export const EBITDA_MARGIN_ALIAS = 'ebitda_margin';
export const EBITDA_MARGIN_ROW_NUMBER = 8;
export const EBITDA_MARGIN_EXPR = `=IF(incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER} != 0, incomeStatement.@${EBITDA_ROW_NUMBER} / incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER}, "${NOT_APPLICABLE}")`;

export const ADJUSTED_EBITDA_MARGIN_TITLE = 'Adjusted EBITDA Margin';
export const ADJUSTED_EBITDA_MARGIN_ALIAS = 'adjusted_ebitda_margin';
export const ADJUSTED_EBITDA_MARGIN_ROW_NUMBER = 9;
export const ADJUSTED_EBITDA_MARGIN_EXPR = `=IF(incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER} != 0, incomeStatement.@${ADJUSTED_EBITDA_ROW_NUMBER} / incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER}, "${NOT_APPLICABLE}")`;

export const PROFIT_MARGIN_TITLE = 'Net Profit Margin';
export const PROFIT_MARGIN_ALIAS = 'profit_margin';
export const PROFIT_MARGIN_ROW_NUMBER = 10;
export const PROFIT_MARGIN_EXPR = `=IF(incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER} != 0, incomeStatement.@${NET_INCOME_ROW_NUMBER} / incomeStatement.@${TOTAL_REVENUE_ROW_NUMBER}, "${NOT_APPLICABLE}")`;

export const FINANCIALS_PERFORMANCE_METRICS_LTM_REVENUE_GROWTH_ACURATE_TOOLTIP
  = 'LTM Revenue Growth will only be accurate when at least 2 years of historical financials are provided';

export const ENTERPRISE_VALUE_ALIAS = 'enterprise_value';
export const LTM_REVENUE_ALIAS = 'ltm_revenue';
export const NTM_REVENUE_ALIAS = 'ntm_revenue';
export const LTM_EBITDA_ALIAS = 'ltm_ebitda';
export const NTM_EBITDA_ALIAS = 'ntm_ebitda';
export const LTM_REVENUE_GROWTH_ALIAS = 'ltm_revenue_growth';
export const NTM_REVENUE_GROWTH_ALIAS = 'ntm_revenue_growth';
export const TANGIBLE_BOOK_VALUE_ALIAS = 'tangible_book_value';
export const NET_INCOME_COMPARISONS_ALIAS = 'net_income_to_common_excl_extra_items';
export const PRICE_VOLUME_HISTORICAL_1YR_ALIAS = 'price_volume_historical_1yr';
export const PRICE_VOLUME_HISTORICAL_2YR_ALIAS = 'price_volume_historical_2yr';
export const PRICE_VOLUME_HISTORICAL_5YR_ALIAS = 'price_volume_historical_5yr';

export const PERFORMANCE_METRIC_ALIASES = [
  LTM_REVENUE_ALIAS,
  NTM_REVENUE_ALIAS,
  LTM_EBITDA_ALIAS,
  NTM_EBITDA_ALIAS,
  LTM_REVENUE_GROWTH_ALIAS,
  NTM_REVENUE_GROWTH_ALIAS,
  GROSS_MARGIN_ALIAS,
  EBITDA_MARGIN_ALIAS,
  TANGIBLE_BOOK_VALUE_ALIAS,
  NET_INCOME_COMPARISONS_ALIAS,
];

import React, { ChangeEventHandler, FocusEvent, FocusEventHandler, useMemo, useState } from 'react';
import { InputProps, TextField } from '@material-ui/core';
import { FORMS_TYPES } from 'common/types';

type CustomTextFieldProps = {
  id: string;
  type: string;
  name: string;
  label: string;
  className?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | null;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | null;
  formErrors?: FORMS_TYPES.FormFieldErrors;
  dbErrors?: Record<string, unknown> | null;
  fullWidth?: boolean;
  InputProps?: Partial<InputProps>;
  error?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  required?: boolean;
};

const CustomTextField = (props: CustomTextFieldProps) => {
  const { id, type, name, label, value, onChange, formErrors, dbErrors, onBlur, onFocus, ...restProps } = props;
  const [wasFocused, setWasFocused] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const errors = useMemo(() => {
    if (formErrors) {
      const tmpErrors = [];

      if (formErrors[name]) {
        tmpErrors.push(...(Array.isArray(formErrors[name]) ? formErrors[name] : [formErrors[name]]));
      }

      if (dbErrors?.[name]) {
        tmpErrors.push(formErrors[name]);
      }
      return tmpErrors.length ? tmpErrors.join('. ') : '';
    }
    return '';
  }, [formErrors, dbErrors, name]);

  const onInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    setIsEditing(true);
    setWasFocused(true);

    onFocus?.(e);
  };

  const onInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    setIsEditing(false);

    onBlur?.(e);
  };

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      onChange={onChange}
      value={value}
      error={!isEditing && wasFocused && !!errors}
      helperText={!isEditing && wasFocused && errors}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      autoComplete="off"
      type={type || 'text'}
      {...restProps}
    />
  );
};

export default CustomTextField;

import React from 'react';
import { Paper } from '@material-ui/core';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import clsx from 'clsx';

type InlineMessageBoxProps = {
  text: string;
  fullWidth?: boolean;
  showIcon?: boolean;
};

const InlineMessageBox = ({ text, fullWidth, showIcon }: InlineMessageBoxProps) => (
  <div className={clsx('inline-message-box', fullWidth && 'inline-message-box--full-with')}>
    <Paper elevation={0} className="inline-message-box__container">
      {showIcon && <ErrorOutlineIcon className="inline-message-box__icon" data-testid="inline-message-box__icon" />}

      <p className="inline-message-box__text">{text}</p>
    </Paper>
  </div>
);

export default InlineMessageBox;

import { useCallback } from 'react';
import { isNull, isUndefined } from 'lodash';
import moment from 'moment';
import {
  EBITDA_MARGIN_ALIAS,
  ENTERPRISE_VALUE_ALIAS,
  GROSS_MARGIN_ALIAS,
  LTM_REVENUE_GROWTH_ALIAS,
  NET_INCOME_COMPARISONS_ALIAS,
  NTM_REVENUE_GROWTH_ALIAS,
  TANGIBLE_BOOK_VALUE_ALIAS,
} from 'common/constants/financials';
import { NOT_APPLICABLE } from 'common/constants/general';
import { useStore } from 'common/store';
import { useGetCompsByDate } from 'services/hooks';
import { ISO_DATE_FORMAT } from 'utilities';
import { LTM_EBITDA, LTM_REVENUE, NTM_EBITDA, NTM_REVENUE } from '../constants';

const useUpdateCustomDatePerformanceMetrics = ({ gpcComparison, format, gpcAttributes, calibrationAttributes }) => {
  const { firmId } = useStore();
  const [fetchGpcCompData] = useGetCompsByDate();
  const getValue = (comp, key) => (!isUndefined(comp[key]?.value) ? comp[key]?.value : comp[key]);

  const getParsedValue = useCallback(
    (comp, key) => {
      const value = getValue(comp, key);
      return value.toFixed(gpcAttributes?.customDatePerformanceMetricAttrs?.[key]?.decimal_places);
    },
    [gpcAttributes]
  );

  const getCalibrationParseValue = useCallback(
    (comp, key) => {
      const value = getValue(comp, key);
      return value.toFixed(calibrationAttributes?.customDatePerformanceMetricAttrs?.[key]?.decimal_places);
    },
    [calibrationAttributes]
  );

  const getPublicCompanyId = useCallback(
    (capIqId, gpcComparisonNew = undefined) => {
      let publicCompany;

      if (gpcComparisonNew !== undefined) {
        publicCompany = gpcComparisonNew.find(comp => comp.cap_iq_id === capIqId);
      } else {
        publicCompany = gpcComparison.find(comp => comp.cap_iq_id === capIqId);
      }

      return publicCompany?.id;
    },
    [gpcComparison]
  );

  const createPerformanceMetric = useCallback(
    (comps, formattedDate) =>
      comps.map(comp => ({
        name: comp.company_name.value,
        as_of_date: formattedDate,
        cap_iq_id: comp.cap_iq_id.value,
        ltm_revenue: getParsedValue(comp, LTM_REVENUE),
        ltm_ebitda: getParsedValue(comp, LTM_EBITDA),
        ltm_revenue_growth: getParsedValue(comp, LTM_REVENUE_GROWTH_ALIAS),
        ntm_revenue: getParsedValue(comp, NTM_REVENUE),
        ntm_ebitda: getParsedValue(comp, NTM_EBITDA),
        ntm_revenue_growth: getParsedValue(comp, NTM_REVENUE_GROWTH_ALIAS),
        gross_margin: getParsedValue(comp, GROSS_MARGIN_ALIAS),
        ebitda_margin: getParsedValue(comp, EBITDA_MARGIN_ALIAS),
        tangible_book_value: getParsedValue(comp, TANGIBLE_BOOK_VALUE_ALIAS),
        net_income_to_common_excl_extra_items: getParsedValue(comp, NET_INCOME_COMPARISONS_ALIAS),
      })),
    [getParsedValue]
  );

  const createGPCComparison = useCallback(
    (asOfDateComps, formattedDate, gpcComparisonNew = undefined) =>
      asOfDateComps?.map(comp => {
        const customDatePerformanceMetrics = {
          public_company: getPublicCompanyId(comp.cap_iq_id.value, gpcComparisonNew),
          name: comp.company_name || comp.name || comp.company_name.value || comp.name.value,
          as_of_date: formattedDate,
          ltm_revenue: getParsedValue(comp, LTM_REVENUE),
          ltm_ebitda: getParsedValue(comp, LTM_EBITDA),
          ltm_revenue_growth: getParsedValue(comp, LTM_REVENUE_GROWTH_ALIAS),
          ntm_revenue: getParsedValue(comp, NTM_REVENUE),
          ntm_ebitda: getParsedValue(comp, NTM_EBITDA),
          ntm_revenue_growth: getParsedValue(comp, NTM_REVENUE_GROWTH_ALIAS),
          gross_margin: getParsedValue(comp, GROSS_MARGIN_ALIAS),
          ebitda_margin: getParsedValue(comp, EBITDA_MARGIN_ALIAS),
          enterprise_value: getParsedValue(comp, 'enterprise_value'),
          tangible_book_value: getParsedValue(comp, TANGIBLE_BOOK_VALUE_ALIAS),
          net_income_to_common_excl_extra_items: getParsedValue(comp, NET_INCOME_COMPARISONS_ALIAS),
        };

        let compIndex;

        if (gpcComparisonNew !== undefined) {
          compIndex = gpcComparisonNew.findIndex(comparison => comparison.cap_iq_id === comp.cap_iq_id.value);
          return { ...gpcComparisonNew[compIndex], custom_date_performance_metrics: [customDatePerformanceMetrics] };
        }

        compIndex = gpcComparison.findIndex(comparison => comparison.cap_iq_id === comp.cap_iq_id.value);
        return { ...gpcComparison[compIndex], custom_date_performance_metrics: [customDatePerformanceMetrics] };
      }),
    [getParsedValue, getPublicCompanyId, gpcComparison]
  );

  const createCalibrationPerformances = useCallback(
    asOfDateComps =>
      asOfDateComps?.map(comp => ({
        cap_iq_id: comp.cap_iq_id?.value || comp.cap_iq_id,
        name: comp.company_name?.value || comp.company_name,
        enterprise_value: getCalibrationParseValue(comp, ENTERPRISE_VALUE_ALIAS),
        gross_margin: getCalibrationParseValue(comp, GROSS_MARGIN_ALIAS),
        ebitda_margin: getCalibrationParseValue(comp, EBITDA_MARGIN_ALIAS),
        ltm_revenue: getCalibrationParseValue(comp, LTM_REVENUE),
        ltm_revenue_growth: getCalibrationParseValue(comp, LTM_REVENUE_GROWTH_ALIAS),
        ntm_revenue: getCalibrationParseValue(comp, NTM_REVENUE),
        ntm_revenue_growth: getCalibrationParseValue(comp, NTM_REVENUE_GROWTH_ALIAS),
        ltm_ebitda: getCalibrationParseValue(comp, LTM_EBITDA),
        ntm_ebitda: getCalibrationParseValue(comp, NTM_EBITDA),
      })),
    [getCalibrationParseValue]
  );

  const validateCustomDatePerformanceMetrics = useCallback(
    compsWithCustomDatePerformanceMetrics =>
      compsWithCustomDatePerformanceMetrics?.map(comp => {
        const { cap_iq_id: capIqId } = comp;
        const customCompanyPerfMetricsFirstResult = compsWithCustomDatePerformanceMetrics[0];

        Object.keys(customCompanyPerfMetricsFirstResult).forEach(key => {
          if (
            customCompanyPerfMetricsFirstResult[key] === NOT_APPLICABLE
            || customCompanyPerfMetricsFirstResult[key] === 0
          ) {
            customCompanyPerfMetricsFirstResult[key] = 0;
          } else if (
            typeof customCompanyPerfMetricsFirstResult[key] === 'object'
            && !isNull(customCompanyPerfMetricsFirstResult[key])
            && 'value' in customCompanyPerfMetricsFirstResult[key]
          ) {
            customCompanyPerfMetricsFirstResult[key] = customCompanyPerfMetricsFirstResult[key].value;
          } else if (
            typeof customCompanyPerfMetricsFirstResult[key] === 'string'
            && !Number.isNaN(parseFloat(customCompanyPerfMetricsFirstResult[key]))
          ) {
            customCompanyPerfMetricsFirstResult[key] = parseFloat(customCompanyPerfMetricsFirstResult[key]).toFixed(
              calibrationAttributes.customDatePerformanceMetricAttrs[key]?.decimal_places || 2
            );
          } else if (
            typeof customCompanyPerfMetricsFirstResult[key] === 'number'
            && !Number.isInteger(customCompanyPerfMetricsFirstResult[key])
          ) {
            customCompanyPerfMetricsFirstResult[key] = customCompanyPerfMetricsFirstResult[key].toFixed(
              calibrationAttributes.customDatePerformanceMetricAttrs[key]?.decimal_places || 2
            );
          }
        });
        customCompanyPerfMetricsFirstResult.cap_iq_id = capIqId;
        return { ...comp };
      }),
    [calibrationAttributes]
  );

  const currencyCode = format?.currency.code;

  const getCompsWithCustomDatePerformanceMetrics = useCallback(
    async asOfDateParam => {
      const formattedDate = moment(asOfDateParam).format(ISO_DATE_FORMAT);
      const identifiers = gpcComparison.map(({ cap_iq_id }) => cap_iq_id);
      const asOfDateComps = await fetchGpcCompData(identifiers, formattedDate, currencyCode, firmId, true);
      const comparisonsWithCustomDatePerformanceMetrics = createGPCComparison(asOfDateComps?.results, formattedDate);
      return comparisonsWithCustomDatePerformanceMetrics;
    },
    [createGPCComparison, fetchGpcCompData, firmId, currencyCode, gpcComparison]
  );

  const getCompsWithCustomDatePerformanceMetricsCalibration = useCallback(
    async (asOfDateParam, gpcComparisonNew) => {
      const formattedDate = moment(asOfDateParam).format(ISO_DATE_FORMAT);
      const identifiers = gpcComparisonNew?.map(({ cap_iq_id }) => cap_iq_id);
      const asOfDateComps = await fetchGpcCompData(identifiers, formattedDate, currencyCode, firmId, true);
      const comparisonsWithCustomDatePerformanceMetrics = createCalibrationPerformances(asOfDateComps?.results);
      return comparisonsWithCustomDatePerformanceMetrics;
    },
    [fetchGpcCompData, currencyCode, firmId, createCalibrationPerformances]
  );

  const getCustomPerformanceMetricsForGPCComparison = useCallback(
    async (asOfDateParam, capIqIds) => {
      const formattedDate = moment(asOfDateParam).format(ISO_DATE_FORMAT);
      const asOfDateComps = await fetchGpcCompData(capIqIds, formattedDate, currencyCode, firmId, true);
      return createPerformanceMetric(asOfDateComps.results, formattedDate);
    },
    [createPerformanceMetric, currencyCode, fetchGpcCompData, firmId]
  );

  return {
    getCompsWithCustomDatePerformanceMetrics,
    validateCustomDatePerformanceMetrics,
    getCustomPerformanceMetricsForGPCComparison,
    getCompsWithCustomDatePerformanceMetricsCalibration,
  };
};

export default useUpdateCustomDatePerformanceMetrics;

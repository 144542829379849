/* eslint-disable react-hooks/exhaustive-deps */
import React, { ComponentType, useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { logIn } from 'common/constants/paths';
import { useIsUserLoggedIn, useVerifyAccessToken } from 'services/hooks/auth';

interface CustomRouteProps extends RouteProps {
  isPrivate?: boolean;
  layout?: ComponentType;
}

const CustomRoute = (props: CustomRouteProps) => {
  const isUserLoggedIn = useIsUserLoggedIn();
  const verifyAccesToken = useVerifyAccessToken();
  const [verified, setVerified] = useState(false);
  const accessToken = localStorage.getItem('access');

  const { layout: Layout, component: Component, isPrivate = false, ...rest } = props;

  useEffect(() => {
    // Log in the user if has valid access token
    if (!isUserLoggedIn && accessToken && !verified) {
      verifyAccesToken();
      setVerified(true);
    }

    return () => {
      setVerified(false);
    };
  }, []);

  const render = (props: RouteComponentProps) => {
    // Protect private page from unauthorized users
    if (!isUserLoggedIn && !accessToken && isPrivate) {
      return <Redirect to={{ pathname: logIn, state: { from: props.location } }} />;
    }

    if (Layout && Component) {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }

    return Component ? <Component {...props} /> : <></>;
  };

  return <Route {...rest} render={render} />;
};

export default CustomRoute;

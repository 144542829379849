/* eslint-disable no-param-reassign */
import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { range } from 'mathjs';
import { GPC } from 'api';
import { LAST_TWELVE_MONTHS, LTM, NEXT_TWELVE_MONTHS, NTM } from 'common/constants/financials';
import { DEFAULT_ZERO_VALUE, MULTIPLE_PREMIUM_DISCOUNT_ALIAS } from 'common/constants/valuations';
import { currencyFormat, smallCurrencyFormat, weightingPercentFormat } from 'common/formats/formats';
import { GridType, Row } from 'common/types/scalarSpreadsheet';
import { SelectValueViewer } from 'components';
import { GridSelect } from 'components/FeaturedSpreadsheet/components';
import {
  COMPANY,
  ENTERPRISE_VALUE_COLUMN_ID,
  ENTERPRISE_VALUE_ID,
  ENTERPRISE_VALUE_OPTION,
  EV_EXCL_OPERATING_LEASES_OPTION,
  LTM_EBITDA_CUSTOM_KEY,
  LTM_REVENUE_CUSTOM_KEY,
  mainColumnLetters,
  middleColumnLetters,
  MULTIPLE_BASIS_ALIAS,
  MULTIPLE_BASIS_OPTIONS,
  NTM_EBITDA_CUSTOM_KEY,
  NTM_REVENUE_CUSTOM_KEY,
  SELECTED_MULTIPLE_VALUE,
  SELECTION,
  WEIGHTED_EQUITY_VALUE,
  WEIGHTED_EV,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import * as constants from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';
import FinancialStatementPeriods from 'pages/Valuations/approaches/guidelinePublicCompanies/FinancialStatementPeriods';
import {
  columndIdToCYFinancialProp,
  legendKeyMap,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/config/auxMaps';
import handleOptionsNumberFormat from 'pages/Valuations/approaches/guidelinePublicCompanies/gpc/handleOptionsNumberFormat';
import { getEbitdaValueForViewer, getLtmNtmEBITDAs } from 'pages/ValuationsAllocation/util';
import { FinancialPeriod } from 'pages/ValuationsAllocation/util/getLtmNtmEBITDAs/types';
import { getBooleanValue, getStringValue, gridLocalShortDate, parseValue } from 'utilities';
import { alphabetGenerator } from 'utilities/alphabet-utilities';
import { getPeriodsOptions } from './auxParser';
import { STOCK_PRICE_KEY } from './constants';
import { addBenchmarkCustomKeys, getSelectionValue, handleMultipleCell } from './utilities';
import {
  CleanReadOnlyCellsParams,
  GenerateStartingCellsProps,
  GetCellDefaultProps,
  GPCColumn,
  GPCConfigurationRow,
  GPCParser,
  HandleEnterpriseValueCellParams,
  PeriodId,
  PeriodOption,
  ReverseParsedValuationApproachGPC,
  UpdateCellPropsParams,
  ValuationApproachGPCCell,
} from '../../types';

function getExpr(columnLegend: string, expr: string) {
  return expr ? expr.replace(/@/g, columnLegend) : '';
}

function getVal(column: GPCColumn, row: GPCConfigurationRow) {
  if (row.gridType === ('gridNumberCheckbox' as GridType)) {
    return column[row.alias]?.number;
  }
  // if the column id is ENTERPRISE_VALUE_COLUMN_ID and the row.alias is 'title' we will need to look up a different value
  // this needs to check the column/row to see if we are on a company row
  if (column.id === ENTERPRISE_VALUE_ID && row.alias === 'title') {
    return column.multiple_basis;
  }
  return !isUndefined(column[row.alias]) ? column[row.alias] : null;
}

const financialPeriodCell = ({
  financialPeriodsOptions,
  isDisabled,
  isEvaluatingEquityValue,
}: {
  financialPeriodsOptions: PeriodOption[];
  isDisabled: boolean;
  isEvaluatingEquityValue: boolean;
}) =>
  ({
    colSpan: isEvaluatingEquityValue ? 1 : 2,
    rowSpan: 1,
    hidden: false,
    readOnly: financialPeriodsOptions.length <= 2 || isDisabled,
    dataEditor: (props: any) => <FinancialStatementPeriods {...props} options={financialPeriodsOptions} />,
    valueViewer: (props: any) => <SelectValueViewer {...props} options={financialPeriodsOptions} />,
    dropdown: true,
  } as ValuationApproachGPCCell);

const getFractionDigitsByKey = (key: string) => (key === STOCK_PRICE_KEY ? 2 : 0);

const getValue = (
  isMiddleColumnValue: boolean,
  row: GPCConfigurationRow,
  column: GPCColumn,
  columnLegend: string,
  approach: ReverseParsedValuationApproachGPC
) => {
  if (row.alias === SELECTION) {
    return getSelectionValue(row, column, approach);
  }

  // the company rows in a, b, c, d have different stuff than those columns from the rowconfig
  // and need to be handled special

  if (isMiddleColumnValue) {
    return column[row.alias]?.[legendKeyMap[columnLegend]];
  }

  // The company rows have object data types, both a number and an enabled status,
  // and the below code keeps the cellReferenceBar from breaking
  return getVal(column, row);
};

const getCellDefaultProps = (params: GetCellDefaultProps) => {
  const {
    approach,
    cell,
    columnLegend,
    expr,
    financialsPeriods,
    isMiddleColumnValue,
    row,
    shouldRevertSelector,
    tmpCell,
    type,
    useFinancialStatementAdjustedEbitda,
    value,
    isEvaluatingEquityValue,
  } = params;

  let cellProps = { ...tmpCell };

  // Copy default props from the row config only if we are in the main columns
  if (mainColumnLetters.indexOf(columnLegend) >= 0) {
    if (shouldRevertSelector) {
      cellProps = {
        ...row,
        ...cell,
        expr: expr(),
      };

      delete cellProps.dataEditor;
      delete cellProps.valueViewer;
      delete cellProps.isEditableTitleCell;
      delete cellProps.useScalarSpreadsheetCell;
    } else {
      cellProps = {
        ...row,
        ...cell,
        // EBITDAs and REVENUEs may be negative
        allowNegativeValue: constants.MAIN_COLUMNS_IDS.includes(cell.columnId as string),
        expr: expr(),
        value: parseValue(value, type, null, null, row.dbType),
      };

      const options = getLtmNtmEBITDAs(financialsPeriods as FinancialPeriod[]);
      options[constants.SECOND_NON_FORWARD_ID] = options.ltm_ebitda;
      options[constants.SECOND_FORWARD_ID] = options.ntm_ebitda;

      if (
        cell.alias === COMPANY
        && [constants.SECOND_NON_FORWARD_ID, constants.SECOND_FORWARD_ID].includes(cell.columnId as string)
        && useFinancialStatementAdjustedEbitda
        && !isEvaluatingEquityValue
        && options[cell.columnId as string]?.some(option => option.value !== options[cell.columnId as string][0].value)
      ) {
        cellProps = {
          ...cellProps,
          readOnly: false,
          dataEditor: (props: any) =>
            GridSelect({
              ...props,
              options: options[cell.columnId as string],
              handleOptionsNumberFormat,
            }),
          valueViewer: (props: any) => SelectValueViewer({ ...props, options: options[cell.columnId as string] }),
          value: getEbitdaValueForViewer({
            cell: cell as { columnId: string },
            options,
            valuation_approach_adjustments: approach.valuations_approach_gpc,
          }),
        };
      }
      if (cell.alias === MULTIPLE_PREMIUM_DISCOUNT_ALIAS) {
        const useMultiplePremiumDiscount = approach?.valuations_approach_gpc?.use_multiple_premium_discount;
        const values: { [key: string]: number } = {
          [constants.FIRST_NON_FORWARD_ID]: parseFloat(
            approach?.valuations_approach_gpc?.first_non_forward_multiple_premium_discount ?? '0'
          ),
          [constants.SECOND_NON_FORWARD_ID]: parseFloat(
            approach?.valuations_approach_gpc?.second_non_forward_multiple_premium_discount ?? '0'
          ),
          [constants.FIRST_FORWARD_ID]: parseFloat(
            approach?.valuations_approach_gpc?.first_forward_multiple_premium_discount ?? '0'
          ),
          [constants.SECOND_FORWARD_ID]: parseFloat(
            approach?.valuations_approach_gpc?.second_forward_multiple_premium_discount ?? '0'
          ),
        };

        const cellValue
          = values[cell.columnId as string] !== 0 && useMultiplePremiumDiscount
            ? values[cell.columnId as string].toString()
            : DEFAULT_ZERO_VALUE;
        cellProps = {
          ...cellProps,
          gridType: 'percentage',
          readOnly: false,
          value: cellValue,
          customKey: `${cell.columnId}_MPD`,
          format: weightingPercentFormat,
        };
      }

      if (cell.alias === SELECTED_MULTIPLE_VALUE) {
        let customKey;
        switch (cell.columnId) {
          case constants.SECOND_NON_FORWARD_COLUMN_LEGEND:
            // eslint-disable-next-line no-param-reassign
            customKey = LTM_EBITDA_CUSTOM_KEY;
            break;
          case constants.SECOND_FORWARD_ID:
            // eslint-disable-next-line no-param-reassign
            customKey = NTM_EBITDA_CUSTOM_KEY;
            break;
          case constants.FIRST_NON_FORWARD_ID:
            // eslint-disable-next-line no-param-reassign
            customKey = LTM_REVENUE_CUSTOM_KEY;
            break;
          case constants.FIRST_FORWARD_ID:
            // eslint-disable-next-line no-param-reassign
            customKey = NTM_REVENUE_CUSTOM_KEY;
            break;
          default:
            break;
        }
        cellProps = {
          ...cellProps,
          customKey,
        };
      }
    }
  } else if (isMiddleColumnValue && columnLegend !== 'A') {
    const fractionDigits = getFractionDigitsByKey(legendKeyMap[columnLegend]);
    cellProps = {
      ...cell,
      gridType: 'number',
      format: currencyFormat({ fractionDigits }),
    };
  }

  return cellProps;
};

const handleEnterpriseValueCell = ({ cell, expr, isMiddleColumnValue, tmpCell }: HandleEnterpriseValueCellParams) => {
  let enterpriseValueCell = { ...tmpCell };

  if (cell.alias === 'title' && cell.columnId === ENTERPRISE_VALUE_COLUMN_ID) {
    enterpriseValueCell = {
      ...enterpriseValueCell,
      readOnly: false,
      alias: MULTIPLE_BASIS_ALIAS,
      dataEditor: (props: any) =>
        GridSelect({
          ...props,
          options: MULTIPLE_BASIS_OPTIONS,
          enumerated: true,
        }),
      valueViewer: (props: any) =>
        SelectValueViewer({
          ...props,
          options: MULTIPLE_BASIS_OPTIONS,
          useLabel: true,
        }),
    };
  } else if (cell.alias !== 'title' && cell.columnId === ENTERPRISE_VALUE_COLUMN_ID && isMiddleColumnValue) {
    enterpriseValueCell = {
      ...enterpriseValueCell,
      readOnly: true,
      expr: expr(),
    };
  }

  return enterpriseValueCell;
};

const updateCellProps = (params: UpdateCellPropsParams) => {
  const {
    approach,
    cell,
    cellProps,
    column,
    columnLegend,
    expr,
    financialsPeriods,
    periodYears,
    row,
    useFinancialStatementAdjustedEbitda,
    isEvaluatingEquityValue,
  } = params;
  const { value, type, isMiddleColumnValue, rowNumber } = cellProps;

  let tmpCell = cell;
  const shouldRevertSelector = constants.PERCENTILE_ROWS.includes(row.alias);

  tmpCell = getCellDefaultProps({
    approach,
    cell,
    columnLegend,
    expr,
    financialsPeriods,
    isMiddleColumnValue,
    row,
    shouldRevertSelector,
    tmpCell,
    type,
    useFinancialStatementAdjustedEbitda,
    value,
    isEvaluatingEquityValue,
  });

  addBenchmarkCustomKeys(cell, tmpCell, column);

  if (typeof column[row.alias] === 'object' && !isNull(column[row.alias])) {
    if (mainColumnLetters.indexOf(columnLegend) >= 0) {
      const rowData = column[row.alias];
      const financialKey = columndIdToCYFinancialProp[column.id][isEvaluatingEquityValue as unknown as string];
      const customYear = periodYears[column.id];
      const financialPeriodValue
        = customYear && rowData?.calendarYears?.[customYear]
          ? rowData.calendarYears[customYear][financialKey]
          : rowData.number;

      const isValidPeriodValue = parseFloat(financialPeriodValue) !== 0;

      tmpCell.ignoreExpr = !isValidPeriodValue;
      tmpCell.enabled = rowData.enabled && isValidPeriodValue;
      const numeratorColumnLegend = isEvaluatingEquityValue
        ? constants.MARKET_CAP_COLUMN_LEGEND
        : constants.ENTERPRISE_VALUE_COLUMN_LEGEND;
      tmpCell.expr = isValidPeriodValue ? `=${numeratorColumnLegend}${rowNumber}/${financialPeriodValue}` : '0';
      tmpCell.financialPeriodValue = financialPeriodValue;
      if (!isValidPeriodValue) {
        tmpCell.value = 0;
      }
    }
  }

  tmpCell = handleEnterpriseValueCell({
    cell,
    expr,
    isMiddleColumnValue,
    tmpCell,
  });

  return tmpCell;
};

const cleanReadOnlyCells = (params: CleanReadOnlyCellsParams) => {
  const { cell, cells, columnLegend, row, weightedEnterpriseValueRow, weightedEquityValueRow } = params;
  const plusCashRow = weightedEnterpriseValueRow + 1;
  const lessDebtRow = weightedEnterpriseValueRow + 2;
  // Clean Multiple Type and Weighted Enterprise Value cells
  switch (columnLegend) {
    case 'A': // Ticker Symbol
    case 'B': // Stock Price
    case 'C': // Market Cap
    case 'D': // Enterprise Value
      // Clean Multiple Type cells
      if (row?.alias === SELECTION) {
        cell.expr = null;
        cell.gridType = 'string';
        cell.value = '';
      }

      // Clean Weighted Enterprise Value cells
      cells[columnLegend + weightedEnterpriseValueRow] = {
        ...cells[columnLegend + weightedEnterpriseValueRow],
        expr: null,
        gridType: 'string',
        value: '',
      };

      cells[columnLegend + plusCashRow] = {
        ...cells[columnLegend + plusCashRow],
        expr: null,
        gridType: 'string',
        value: '',
      };

      cells[columnLegend + lessDebtRow] = {
        ...cells[columnLegend + lessDebtRow],
        expr: null,
        gridType: 'string',
        value: '',
      };

      cells[columnLegend + weightedEquityValueRow] = {
        ...cells[columnLegend + weightedEquityValueRow],
        expr: null,
        gridType: 'string',
        value: '',
      };
      break;

    default:
      break;
  }
};

const generateStartingCells = ({
  regularFinancialPeriodsOptions,
  priceToTangibleOptions,
  ltmPeriodId,
  ntmPeriodId,
  firstPeriodId,
  firstForwardPeriodId,
  secondPeriodId,
  secondForwardPeriodId,
  isDisabled,
  valuationDate,
  isEvaluatingEquityValue,
}: GenerateStartingCellsProps) => {
  const getPeriodValueByLabel = (label: string, tangibleOptions = false) =>
    (tangibleOptions ? priceToTangibleOptions : regularFinancialPeriodsOptions).find(p => p.label === label)?.value;

  const cells: Record<string, ValuationApproachGPCCell> = {
    A1: {
      alias: '',
      key: 'A1',
      value: 'CAPITAL IQ EXTRA INFO',
      readOnly: true,
      colSpan: 4,
      rowSpan: 1,
      parentColumn: constants.FIRST_NON_FORWARD_ID,
      className: 'table-header full-width-label',
    },
  };
  if (isEvaluatingEquityValue) {
    const financialsAvailablePeriodsIds = priceToTangibleOptions.map(option => option.value);
    const periodSelectionIsValid = (periodId: PeriodId) =>
      periodId && financialsAvailablePeriodsIds.includes(Number(periodId));

    cells.E1 = {
      ...financialPeriodCell({ financialPeriodsOptions: priceToTangibleOptions, isDisabled, isEvaluatingEquityValue }),
      key: 'E1',
      alias: constants.PRICE_TO_TANGIBLE_PERIOD,
      value: periodSelectionIsValid(firstPeriodId)
        ? firstPeriodId
        : getPeriodValueByLabel(`As of ${gridLocalShortDate(valuationDate)}`, true),
      shouldDisplayTooltip: true,
    };
    // value for forward price to tangible period is next year from valuation date year
    const nextYear = new Date(valuationDate).getFullYear() + 1;
    const nextYearOption: PeriodOption = priceToTangibleOptions.find(option =>
      option.label.includes(nextYear.toString())
    ) as PeriodOption;
    cells.G1 = {
      ...financialPeriodCell({ financialPeriodsOptions: priceToTangibleOptions, isDisabled, isEvaluatingEquityValue }),
      key: 'G1',
      alias: constants.FORWARD_PRICE_TO_TANGIBLE_PERIOD,
      value: periodSelectionIsValid(firstForwardPeriodId)
        ? firstForwardPeriodId
        : getPeriodValueByLabel(nextYearOption.label, true),
    };
    cells.F1 = {
      ...financialPeriodCell({
        financialPeriodsOptions: regularFinancialPeriodsOptions,
        isDisabled,
        isEvaluatingEquityValue,
      }),
      key: 'F1',
      alias: constants.PRICE_TO_EARNINGS_PERIOD,
      value: periodSelectionIsValid(secondPeriodId) ? secondPeriodId : getPeriodValueByLabel(LAST_TWELVE_MONTHS),
    };
    cells.H1 = {
      ...financialPeriodCell({
        financialPeriodsOptions: regularFinancialPeriodsOptions,
        isDisabled,
        isEvaluatingEquityValue,
      }),
      key: 'H1',
      alias: constants.FORWARD_PRICE_TO_EARNINGS_PERIOD,
      value: periodSelectionIsValid(secondForwardPeriodId)
        ? secondForwardPeriodId
        : getPeriodValueByLabel(NEXT_TWELVE_MONTHS),
    };
  } else {
    cells.E1 = {
      ...financialPeriodCell({
        financialPeriodsOptions: regularFinancialPeriodsOptions,
        isDisabled,
        isEvaluatingEquityValue,
      }),
      key: 'E1',
      alias: LAST_TWELVE_MONTHS,
      value: ltmPeriodId ?? getPeriodValueByLabel(LAST_TWELVE_MONTHS),
      shouldDisplayTooltip: true,
    };
    cells.G1 = {
      ...financialPeriodCell({
        financialPeriodsOptions: regularFinancialPeriodsOptions,
        isDisabled,
        isEvaluatingEquityValue,
      }),
      key: 'G1',
      alias: NEXT_TWELVE_MONTHS,
      value: ntmPeriodId ?? getPeriodValueByLabel(NEXT_TWELVE_MONTHS),
    };
  }
  return cells;
};

const parser: GPCParser = async ({ columns, rowConfig, tableData }) => {
  const { approach, financialsPeriods, isDisabled } = tableData;
  const { valuations_approach_gpc, valuation_date: valuationDate } = approach;
  const {
    financials: {
      use_adjusted_ebitda: useFinancialStatementAdjustedEbitda,
      total_cash_equivalents: plusCash,
      total_debt: lessDebt,
    },
  } = tableData;

  const getWeightedEquityValueExpr = () => `=E${rowConfig.length - 3} + ${plusCash} - ${lessDebt}`;
  const getWeightedEnterpriseValueExpr = () => `=E${rowConfig.length - 3} - ${plusCash} + ${lessDebt}`;

  const {
    gpc_comparison,
    ltm_financial_statement_period_id,
    ntm_financial_statement_period_id,
    use_multiple_premium_discount,
    first_period_selection: firstPeriodId,
    second_period_selection: secondPeriodId,
    first_period_forward_selection: firstForwardPeriodId,
    second_period_forward_selection: secondForwardPeriodId,
    is_evaluating_equity_value: isEvaluatingEquityValue,
  } = valuations_approach_gpc;
  const comparisons: GPC[] = gpc_comparison || [];

  const getPeriodId = (type: number) => {
    const id = type === LTM ? ltm_financial_statement_period_id : ntm_financial_statement_period_id;

    if (id) {
      return id;
    }

    return null;
  };

  const regularFinancialPeriodsOptions = getPeriodsOptions({ financialsPeriods });
  const priceToTangibleOptions = getPeriodsOptions({ financialsPeriods, priceToTangibleOptions: true, valuationDate });
  let periodYears: { [key: string]: string | null } = {
    [constants.FIRST_NON_FORWARD_ID]: null,
    [constants.SECOND_NON_FORWARD_ID]: null,
    [constants.FIRST_FORWARD_ID]: null,
    [constants.SECOND_FORWARD_ID]: null,
  };

  const buildPeriodYears = ({
    id,
    key,
    tangibleOptions = false,
  }: {
    id: number | null | undefined;
    key: string;
    tangibleOptions?: boolean;
  }) => {
    if (!id) {
      return;
    }

    const ltmPeriod = (tangibleOptions ? priceToTangibleOptions : regularFinancialPeriodsOptions).find(
      p => p.value.toString() === id.toString()
    );

    if (ltmPeriod) {
      periodYears = {
        ...periodYears,
        [key]: ltmPeriod?.label,
      };
    }
  };

  const ltmPeriodId = getPeriodId(LTM);
  const ntmPeriodId = getPeriodId(NTM);

  if (isEvaluatingEquityValue) {
    buildPeriodYears({ id: firstPeriodId, key: constants.FIRST_NON_FORWARD_ID, tangibleOptions: true });
    buildPeriodYears({ id: secondPeriodId, key: constants.SECOND_NON_FORWARD_ID });
    buildPeriodYears({ id: firstForwardPeriodId, key: constants.FIRST_FORWARD_ID, tangibleOptions: true });
    buildPeriodYears({ id: secondForwardPeriodId, key: constants.SECOND_FORWARD_ID });
  } else {
    buildPeriodYears({ id: ltmPeriodId, key: constants.FIRST_NON_FORWARD_ID });
    buildPeriodYears({ id: ltmPeriodId, key: constants.SECOND_NON_FORWARD_ID });
    buildPeriodYears({ id: ntmPeriodId, key: constants.FIRST_FORWARD_ID });
    buildPeriodYears({ id: ntmPeriodId, key: constants.SECOND_FORWARD_ID });
  }

  // includes periods selectors
  let cells = generateStartingCells({
    regularFinancialPeriodsOptions,
    priceToTangibleOptions,
    isEvaluatingEquityValue: getBooleanValue(isEvaluatingEquityValue),
    isDisabled,
    valuationDate,
    firstPeriodId,
    firstForwardPeriodId,
    secondPeriodId,
    secondForwardPeriodId,
    ltmPeriodId,
    ntmPeriodId,
  });

  // Below are custom generated cells
  // this generates the weighted EV cell
  const weightingRow = rowConfig.length - 4;
  const valueRow = rowConfig.length - 5;
  const plusCashRow = rowConfig.length - 2;
  const lessDebtRow = rowConfig.length - 1;

  function getExpressionPiece(legend: string) {
    return `(${legend}${weightingRow}/100 * ${legend}${valueRow})`;
  }

  cells[`E${rowConfig.length - 3}`] = {
    key: `E${rowConfig.length - 3}`,
    colSpan: 4,
    alias: WEIGHTED_EV,
    customKey: isEvaluatingEquityValue ? WEIGHTED_EQUITY_VALUE : WEIGHTED_EV,
    columnLegend: 'E',
    gridType: 'number',
    expr: `=${mainColumnLetters.map(legend => getExpressionPiece(legend)).join(' + ')}`,
    format: smallCurrencyFormat,
    className: 'subtitle large-cell-value',
    isNotNavigable: true,
  };
  cells[`E${plusCashRow}`] = {
    key: `E${plusCashRow}`,
    colSpan: 4,
    alias: constants.PLUS_CASH_ALIAS,
    columnLegend: 'E',
    gridType: 'number',
    customKey: constants.PLUS_CASH_ALIAS,
    value: plusCash,
    format: smallCurrencyFormat,
    className: 'large-cell-value',
    isNotNavigable: true,
  };
  cells[`E${lessDebtRow}`] = {
    key: `E${lessDebtRow}`,
    colSpan: 4,
    alias: constants.LESS_DEBT_ALIAS,
    columnLegend: 'E',
    gridType: 'number',
    customKey: constants.LESS_DEBT_ALIAS,
    value: lessDebt,
    format: smallCurrencyFormat,
    className: 'large-cell-value',
    isNotNavigable: true,
  };
  cells[`E${rowConfig.length}`] = {
    key: `E${rowConfig.length}`,
    colSpan: 4,
    alias: 'equity_value',
    columnLegend: 'E',
    gridType: 'number',
    customKey: isEvaluatingEquityValue ? WEIGHTED_EV : WEIGHTED_EQUITY_VALUE,
    expr: isEvaluatingEquityValue ? getWeightedEnterpriseValueExpr() : getWeightedEquityValueExpr(),
    format: smallCurrencyFormat,
    className: 'subtitle large-cell-value',
    isNotNavigable: true,
  };

  // these map the extra bottom cells for the bottom rows, as those rows are set to ignore
  // generating default cells
  middleColumnLetters
    .flatMap(letter => [
      {
        key: `${letter}${rowConfig.length - 3}`,
        parentColumn: constants.FIRST_NON_FORWARD_ID,
        className: 'subtitle',
        alias: '',
      },
      {
        key: `${letter}${plusCashRow}`,
        parentColumn: constants.FIRST_NON_FORWARD_ID,
        alias: '',
      },
      {
        key: `${letter}${lessDebtRow}`,
        parentColumn: constants.FIRST_NON_FORWARD_ID,
        alias: '',
      },
      {
        key: `${letter}${rowConfig.length}`,
        parentColumn: constants.FIRST_NON_FORWARD_ID,
        className: 'subtitle',
        alias: '',
      },
    ])
    .forEach(cell => {
      cells[cell.key] = cell;
    });
  // end Custom generated cells

  const alphabet = alphabetGenerator([], columns.length);

  const handleTitleColumnCell = (params: { column: GPCColumn; key: string }) => {
    const { column, key } = params;

    if (
      key === 'E1'
      || key === `E${rowConfig.length - 3}`
      || key === `E${rowConfig.length}`
      || key === `E${rowConfig.length - 2}`
      || key === `E${rowConfig.length - 1}`
    ) {
      cells = {
        ...cells,
        [key]: {
          ...cells[key],
          isParent: column.isParent,
          columnId: column.id,
        },
      };
    }
  };

  const getEnterpriseValueExpression = (params: {
    cellExpr: string;
    column: GPCColumn;
    isMiddleColumnValue: boolean;
    row: Row;
  }) => {
    const { cellExpr, column, isMiddleColumnValue, row } = params;
    let expression = cellExpr;

    if (column.id === ENTERPRISE_VALUE_COLUMN_ID && row.alias !== 'title' && isMiddleColumnValue) {
      expression = `=FROM_KEY_VALUE_PAIRS([['${ENTERPRISE_VALUE_OPTION}', ${
        column[row.alias].enterprise_value
      }],['${EV_EXCL_OPERATING_LEASES_OPTION}', ${column[row.alias].ev_excl_operating_leases}]], @2)`;
    }

    return expression;
  };

  function handleRow(column: GPCColumn, columnLegend: string) {
    const rowRange = range(1, comparisons.length + 1)
      .map(rowNumber => `${columnLegend}${rowNumber + 2}`)
      .toString()
      .replace(/"/g, '');

    return (row: GPCConfigurationRow, index: number) => {
      const rowNumber = index + 1;
      const key = columnLegend + rowNumber;

      // Add isParent to Title column cell
      handleTitleColumnCell({ column, key });
      const indexesToIgnore = [
        0,
        rowConfig.length - 4,
        rowConfig.length - 1,
        rowConfig.length - 2,
        rowConfig.length - 3,
      ];
      if (indexesToIgnore.includes(index)) {
        return;
      }

      const isMiddleColumnValue = (legendKeyMap[columnLegend]
        && index !== 1
        && index <= comparisons.length + 1) as boolean;

      // cell below the dropdowns for periods get their value set here by reading the column title
      const value = getValue(isMiddleColumnValue, row, column, columnLegend, valuations_approach_gpc);
      const type = row.gridType ?? null;

      let cellExpr = getStringValue(row.expr);

      cellExpr = getEnterpriseValueExpression({ cellExpr, column, isMiddleColumnValue, row });

      const expr = () => getExpr(columnLegend, cellExpr);

      // I don't want the default props in some columns as the 'middle'
      // cells represent entirely different kinds of data in each column
      let cell: ValuationApproachGPCCell = {
        key,
        alias: row.alias || '',
        columnId: column.id,
        columnLegend,
        columnOrder: column.order,
        customFormat: column.customFormat || null,
        ignoreAutoScroll: row.ignoreAutoScroll,
        isNotNavigable: row.isNotNavigable,
        isParent: column.isParent,
        isVisible: row.isVisible,
        parentColumn: column.parentColumn,
        rowNumber,
        value,
        isGpcRow: true,
      };

      const cellProps = {
        value,
        type,
        isMiddleColumnValue,
        rowNumber,
      };

      cell = updateCellProps({
        approach,
        cell,
        cellProps,
        column,
        columnLegend,
        expr,
        financialsPeriods,
        periodYears,
        row,
        useFinancialStatementAdjustedEbitda,
        isEvaluatingEquityValue: getBooleanValue(isEvaluatingEquityValue),
      });

      const isPercentileCell = constants.PERCENTILE_ROWS.includes(cell.alias);
      if (isPercentileCell) {
        if (constants.MAIN_COLUMNS_IDS.includes(cell.columnId as string)) {
          const percentileExpr = `=PERCENTILE(FILTER_ENABLED(${rowRange}), TITLES_${cell.alias})`;
          cell.expr = percentileExpr;
        }
      }

      // Clean Multiple Type and Weighted Enterprise Value cells
      cleanReadOnlyCells({
        cell,
        cells,
        columnLegend,
        row,
        weightedEnterpriseValueRow: rowConfig.length - 3,
        weightedEquityValueRow: rowConfig.length,
      });

      handleMultipleCell(cell, column, use_multiple_premium_discount);

      cells = {
        ...cells,
        [key]: cell,
      };
    };
  }

  // Parse body cells
  columns.forEach((column, columnIndex) => {
    const columnLegend = alphabet[columnIndex];
    rowConfig.forEach(handleRow(column, columnLegend));
  });

  return cells;
};

export default parser;

import { isUndefined } from 'lodash';
import {
  SELECTED_DATABASE_VALUE,
  SPECIFIED_LABEL,
} from 'pages/Valuations/approaches/guidelinePublicCompanies/constants';

const addApproachDataInColumn = (tableColumn, approach) => {
  const tempCableColumn = { ...tableColumn };
  // pull the necessary data out of the approach object and add it to the column
  tempCableColumn.selected_multiple = approach[`${tableColumn.id}_selected_multiple`];
  tempCableColumn.selection = approach[`${tableColumn.id}_selection`];
  if (tempCableColumn.selection === SELECTED_DATABASE_VALUE) {
    tempCableColumn.selection = SPECIFIED_LABEL;
  }
  const weighting = approach[`${tableColumn.id}_weighting`];
  if (!isUndefined(weighting)) {
    tempCableColumn.weighting = approach[`${tableColumn.id}_weighting`] * 100;
  }
  return { ...tempCableColumn };
};

export default addApproachDataInColumn;

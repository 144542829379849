import C from './Calibration';
import createCalibrationInputsData from './Inputs/createCalibrationInputsData';
import createCalibrationOutputsData from './Outputs/createCalibrationOutputsData';
import { createCalibrationPerformanceMetrics } from './performance_metrics';
import { PerformanceMetricsColumn } from './performance_metrics/config/createColumns';
import { CreateApproachCalibrationConfigParams } from './types';
import { mapPerformancesToGPCWithCapIQ } from './utilities';
import { findSelectedCalibrationApproach, findSelectedCalibrationFinancial } from './utils';
import { GPC } from '../../../../api';
import { emptyFinancials } from '../../../../common/constants/calibration';
import { Row } from '../../../../common/types/scalarSpreadsheet';
import { FinancialsTransposed } from '../../../ValuationsAllocation/util';
import { Approach } from '../guidelinePublicCompanies/types';

const createCalibrationConfig = (params: CreateApproachCalibrationConfigParams) => {
  const {
    approaches,
    company,
    companyExchangeRate,
    customClasses,
    calibration,
    name,
    financialStatementsList,
    isDisabled,
  } = params;

  let { approach } = params;

  const columnsPerformanceCalibration: PerformanceMetricsColumn[] = [];
  const rowsPerformanceCalibration: Row[] = [];

  const calibrationData = calibration;

  approach = findSelectedCalibrationApproach(
    calibrationData.public_comps_approach_calibration,
    approach,
    approaches
  ) as Approach;

  const selectedApproach = { ...approach };

  let financials = emptyFinancials as FinancialsTransposed;

  let version = 0;

  if (calibration) {
    const results = findSelectedCalibrationFinancial(calibrationData, financialStatementsList, financials);
    financials = results.financials;
    version = results.version;
  }

  const panelId = calibration.panelId.slice(0, 5);

  if (selectedApproach) {
    calibration.approach = selectedApproach;
  }

  const { gpc_comparison: comparisons } = calibration.approach?.valuations_approach_gpc ?? {};

  const gpcModels: GPC[] = mapPerformancesToGPCWithCapIQ(calibration.performances || [], comparisons || []);

  const { spreadsheet: calibrationSheetInputs } = createCalibrationInputsData({
    calibration,
    financials,
    company,
    companyExchangeRate,
    name: `calibration_inputs_${panelId}`,
    approaches,
    financialStatementsList,
    columnsPerformanceCalibration,
    rowsPerformanceCalibration,
    version,
    approach: selectedApproach,
    isDisabled,
    comparisons: gpcModels.length > 0 ? gpcModels : comparisons,
  });

  const { spreadsheet: calibrationSheetPerformance } = createCalibrationPerformanceMetrics({
    calibration,
    name: `calibration_performance_${panelId}`,
    approach: selectedApproach,
    company,
    customClasses,
    financials,
    isDisabled,
    columnsPerformanceCalibration,
    rowsPerformanceCalibration,
    comparisons: gpcModels.length > 0 ? gpcModels : comparisons,
  });

  const { spreadsheet: calibrationSheetOutputs } = createCalibrationOutputsData({
    calibration,
    financials,
    approach: selectedApproach,
    name: `calibration_outputs_${panelId}`,
    isDisabled,
    comparisons: gpcModels.length > 0 ? gpcModels : comparisons,
  });

  return {
    name,
    calibration,
    component: C,
    spreadsheets: {
      inputs: calibrationSheetInputs,
      outputs: calibrationSheetOutputs,
      performance: calibrationSheetPerformance,
    },
  };
};

export default createCalibrationConfig;

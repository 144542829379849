import { isEmpty } from 'lodash';
import { Cells } from 'common/types/scalarSpreadsheet';
import { ValuationsApproach } from 'common/types/valuation';
import { SpreadsheetConfig } from 'components/ScalarSpreadsheet/utilities/SpreadsheetConfig';
import { PUBLIC_COMPANIES, PUBLIC_TRANSACTIONS } from 'pages/Valuations/util/constants';
import { SHEET_CONFIG_CONSTANTS } from 'pages/ValuationsAllocation/common/constants/futureExit/';
import { FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES } from 'pages/ValuationsAllocation/common/constants/futureExit/sheetConfigs';
import { FinancialsTransposed, getApproachTableName } from 'pages/ValuationsAllocation/util';
import { getNumberValue, getObjectValue, getStringValue } from 'utilities';
import { createColumns } from './createColumns';
import { customAfterCellsChanged } from './customAfterCellsChanged';
import { Change } from './customAfterCellsChanged/types';
import { customParser } from './customParser';
import { CustomParserParams } from './customParser/types';
import { customReverseParser } from './customReverseParser';
import { FutureEquityCellCustomData, FutureEquityReverseParserParams } from './customReverseParser/types';
import { rowConfig } from './rowConfig';
import { CreateFutureEquityValueSheetParams } from './types';
import mapApproaches from './utils/mapCompValues';

const { FUTURE_EQUITY_SPREADSHEET_TABLE_TERMS } = SHEET_CONFIG_CONSTANTS;

const createFutureEquityConfiguration = (params: CreateFutureEquityValueSheetParams) => {
  const {
    approach,
    approaches,
    futureExitApproachAttrs,
    isDisabled,
    isStatic,
    measurementDate,
    financials,
    staticFinancials,
  } = params;

  const { valuations_approach_future_exit: valuationsApproachFutureExitApproach } = getObjectValue(approach);
  const name = getApproachTableName({
    approach,
    tableSuffix: FUTURE_EQUITY_SPREADSHEET_TABLE_NAMES.FUTURE_EQUITY_VALUE,
  });

  const utilizedFinancials: FinancialsTransposed = staticFinancials
    ? {
      ltm_revenue: getNumberValue(staticFinancials?.ltmData?.income_statement?.total_revenue),
      ntm_revenue: getNumberValue(staticFinancials?.ntmData?.income_statement?.total_revenue),
      ltm_ebitda: getNumberValue(staticFinancials?.ltmData?.income_statement?.ebitda),
      ltm_adjusted_ebitda: getNumberValue(staticFinancials?.ltmData?.income_statement?.adjusted_ebitda),
      ntm_ebitda: getNumberValue(staticFinancials?.ntmData?.income_statement?.ebitda),
      ntm_adjusted_ebitda: getNumberValue(staticFinancials?.ntmData?.income_statement?.adjusted_ebitda),
      ltm_ebitda_margin: getNumberValue(staticFinancials?.ltmData?.income_statement?.ebitda_margin),
      ltm_gross_margin: getNumberValue(staticFinancials?.ltmData?.income_statement?.gross_margin),
      tangible_book_value: getNumberValue(staticFinancials?.ltmData?.balance_sheet?.tangible_book_value),
      ltm_revenue_growth_rate: getNumberValue(staticFinancials?.ltmData?.income_statement?.revenue_growth_rate),
      ntm_revenue_growth_rate: getNumberValue(staticFinancials?.ntmData?.income_statement?.revenue_growth_rate),
      total_cash_equivalents: getNumberValue(staticFinancials?.ltmData?.balance_sheet?.total_cash_equivalents),
      total_debt: getNumberValue(staticFinancials?.ltmData?.balance_sheet?.total_debt),
      use_adjusted_ebitda: staticFinancials?.use_adjusted_ebitda ?? false,
      net_income: getNumberValue(staticFinancials?.ltmData?.income_statement?.net_income),
      assets_total: getNumberValue(staticFinancials?.ltmData?.balance_sheet?.assets_total),
      intangibles: getNumberValue(staticFinancials?.ltmData?.balance_sheet?.assets_intangibles),
      liabilities_total: getNumberValue(staticFinancials?.ltmData?.balance_sheet?.liabilities_total),
      id: undefined,
      ltm_net_income: getNumberValue(staticFinancials?.ltmData?.income_statement?.net_income),
      ntm_net_income: getNumberValue(staticFinancials?.ntmData?.income_statement?.net_income),
    }
    : financials;

  const staticApproachValues = isStatic ? mapApproaches({ approaches }) : null;

  const compsList = (approaches || [])
    .filter((a: ValuationsApproach) =>
      [PUBLIC_COMPANIES, PUBLIC_TRANSACTIONS].includes(getStringValue(a?.approach_type))
    )
    .map(({ panelId, id, name }: ValuationsApproach) => ({
      value: id ?? panelId,
      label: name,
    }));

  const getColumns = () =>
    createColumns({
      valuationsApproachFutureExitApproach,
      measurementDate,
      financials: utilizedFinancials,
      approaches,
    });

  const columns = getColumns();

  const config = rowConfig({
    isDisabled,
    measurementDate,
    valuationsApproachFutureExitApproach,
  });

  const parser = (parserParams: CustomParserParams) =>
    customParser({
      ...parserParams,
      compsList,
      financials: utilizedFinancials,
      measurementDate,
    });

  const reverseParser = (reverseParserParams: FutureEquityReverseParserParams) =>
    customReverseParser({
      ...reverseParserParams,
      approach,
      approaches,
    });

  const afterCellsChanged = (initialChanges: Array<Change>, cells: Cells<FutureEquityCellCustomData>) =>
    customAfterCellsChanged({
      initialChanges,
      cells,
      approaches,
      approach,
      financials: utilizedFinancials,
      staticApproachValues,
    });

  const futureEquitySheet = !isEmpty(columns)
    ? new SpreadsheetConfig({
      allowConfirmAndDeleteColumn: false,
      allowCopyColumn: false,
      allowReorderColumns: false as unknown as SpreadsheetConfig['allowReorderColumns'],
      alwaysDisplayLegend: false,
      columns,
      currencyFormatter: true,
      fieldAttributes: futureExitApproachAttrs,
      format: undefined,
      name,
      page: FUTURE_EQUITY_SPREADSHEET_TABLE_TERMS.tableName as unknown as SpreadsheetConfig['page'],
      parser,
      reverseParser: reverseParser as unknown as SpreadsheetConfig['reverseParser'],
      afterCellChanged: afterCellsChanged as unknown as SpreadsheetConfig['afterCellChanged'],
      rowConfig: config,
      showPreviousColsDivider: false,
      showTitlesColumn: true,
      showToolbar: true,
      showTotalColumn: false,
      tableData: {},
      tableTerms: FUTURE_EQUITY_SPREADSHEET_TABLE_TERMS as unknown as SpreadsheetConfig['tableTerms'],
      totalParser: undefined,
      unitsFormatter: true,
      getColumns,
    })
    : null;

  return futureEquitySheet;
};

export default createFutureEquityConfiguration;

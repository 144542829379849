import { isNil } from 'lodash';
import { areObjectIdsEqual } from 'pages/ValuationsAllocation/util/areObjectIdsEqual';
import { AreApproachesSynchronizedParams } from './types';

const areApproachesSynchronized = (params: AreApproachesSynchronizedParams) => {
  const { valuationApproaches, approaches } = params;

  return (
    approaches?.every(approach => {
      const currentValuationApproach = valuationApproaches?.find(
        valuationApproach =>
          (!isNil(approach?.id) && valuationApproach?.id === approach?.id)
          || (!isNil(approach?.panelId) && valuationApproach?.panelId === approach?.panelId)
          || (!isNil(approach?.panel_id) && valuationApproach?.panel_id === approach?.panel_id)
      );

      return (
        currentValuationApproach?.approach_type === approach?.approach_type
        && currentValuationApproach?.financial_statement === approach?.financial_statement
        && currentValuationApproach?.name === approach?.name
        && currentValuationApproach?.valuation_date === approach?.valuation_date
        && currentValuationApproach?.is_calibration === approach?.is_calibration
        && currentValuationApproach?.valuation === approach?.valuation
        && currentValuationApproach?.valuations_approach_gpc?.gpc_comparison?.length
          === approach?.valuations_approach_gpc?.gpc_comparison?.length
        && currentValuationApproach?.valuations_approach_backsolve?.applied_methodologies?.length
          === approach?.valuations_approach_backsolve?.applied_methodologies?.length
        && areObjectIdsEqual({
          firstObjectList: approach?.valuations_approach_backsolve?.applied_methodologies,
          secondObjectList: currentValuationApproach?.valuations_approach_backsolve?.applied_methodologies,
        })
        && currentValuationApproach?.valuations_approach_backsolve?.public_companies_status?.length
          === approach?.valuations_approach_backsolve?.public_companies_status?.length
        && areObjectIdsEqual({
          firstObjectList: approach?.valuations_approach_backsolve?.public_companies_status,
          secondObjectList: currentValuationApproach?.valuations_approach_backsolve?.public_companies_status,
        })
        && areObjectIdsEqual({
          firstObjectList: approach?.valuations_approach_gpc?.gpc_comparison,
          secondObjectList: currentValuationApproach?.valuations_approach_gpc?.gpc_comparison,
        })
        && currentValuationApproach?.valuations_approach_gpt?.gpt_transactions?.length
          === approach?.valuations_approach_gpt?.gpt_transactions?.length
        && areObjectIdsEqual({
          firstObjectList: approach?.valuations_approach_gpt?.gpt_transactions,
          secondObjectList: currentValuationApproach?.valuations_approach_gpt?.gpt_transactions,
        })
        && currentValuationApproach?.valuations_approach_dcf?.dcf_financial_period?.length
          === approach?.valuations_approach_dcf?.dcf_financial_period?.length
        && areObjectIdsEqual({
          firstObjectList: approach?.valuations_approach_dcf?.dcf_financial_period,
          secondObjectList: currentValuationApproach?.valuations_approach_dcf?.dcf_financial_period,
        })
      );
    }) && valuationApproaches?.length === approaches?.length
  );
};

export default areApproachesSynchronized;
